import React, {useState} from 'react';

import {gql, useMutation} from "@apollo/client";
import {Button, Space,} from "antd";
import {PlusOutlined, UnorderedListOutlined} from "@ant-design/icons";
import ModalClientsSelect from "../../../components/ModalClientsSelect";
import ModalClientAdd from "../../../components/ModalClientAdd";

const UPDATE = gql`
    mutation training($clients: [ID], $id: ID!) {
        updateTrainingVisits(id: $id, clients: $clients) {
            done
        }
    }
`;

const CREATE = gql`
    mutation visit($data: VisitInput!) {
        createVisit(data: $data) {
            data {
                id
            }
        }
    }
`;

export default function TrainingClientsSelectAndAdd({trainingId, clients, onUpdate}) {

    const [update] = useMutation(UPDATE);
    const [create] = useMutation(CREATE);

    // Select Clients
    const [modalSelectVisible, setModalSelectVisible] = useState(false);
    const onSaveList = async function (value) {
        let clients = value.map(function (item) {
            return (item.id);
        });

        await update({variables: {clients: clients, id: trainingId}});
        onUpdate();
        setModalSelectVisible(false);
    };

    // Add Visit with New Client
    const [modalAddVisible, setModalAddVisible] = useState(false);
    const onAddClient = async function (value) {
        const data = {
            training: trainingId,
            user: value,
            visited: false,
        }
        await create({variables: {data: data}});
        onUpdate();
        setModalAddVisible(false);
    };

    return (
        <>
            <Space>
                <Button size={'large'} icon={<UnorderedListOutlined/>} onClick={() => setModalSelectVisible(true)}/>
                <Button size={'large'} icon={<PlusOutlined/>} onClick={() => setModalAddVisible(true)}/>
            </Space>

            <ModalClientsSelect isOpen={modalSelectVisible} onClose={() => setModalSelectVisible(false)} selected={clients} onFinish={(value) => onSaveList(value)}/>
            <ModalClientAdd isOpen={modalAddVisible} onClose={() => setModalAddVisible(false)} onFinish={(value) => onAddClient(value)}/>
        </>

    );
}



