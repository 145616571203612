import React from 'react';
import {gql, useQuery} from "@apollo/client";
import {Select, Spin} from "antd";
import {EnvironmentOutlined} from "@ant-design/icons";
import _ from "lodash";

const QUERY = gql`
    query gyms ($filters: GymFiltersInput, $pagination: PaginationArg, $sort: [String] = []) {
        gyms (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    name
                    enabled
                    address
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

const { Option } = Select;

export default function SelectGym({ initialValue = 'Зал не выбран', emptyValue = '',  ...field }) {
    // Query
    const query = useQuery(QUERY, {
        variables: {
            filters: {
                or: [
                    {
                        enabled: { eq: true },
                    },
                    {
                        enabled: { null: true },
                    },
                ],
            },
            pagination: {
                limit: 200,
            },
            sort: ['id:ASC'],
        },
    });

    // Render
    return (
        <Select
            {...field}
            filterOption={false}
            notFoundContent={query.loading ? <Spin size="small" /> : null}
            placeholder={initialValue}
            dropdownMatchSelectWidth={false}
        >
            <Option key={0} value={null}>
                {initialValue}
            </Option>
            {emptyValue && (
                <Option key={'empty'} value={'empty'}>
                    {emptyValue}
                </Option>
            )}
            {query.data &&
                _.map(query.data.gyms.data, (value) => {
                    return (
                        <Option key={value.id} value={value.id}>
                            <EnvironmentOutlined /> <span className={'bold'}>{value.attributes.name}</span>{' '}
                            {value.attributes.address}
                        </Option>
                    );
                })}
        </Select>
    );
}