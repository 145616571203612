import SingleWhiteChart from "./includes/SingleCircleWhiteChart";
import MutiplyCircleWhiteChart from "./includes/MutiplyCircleWhiteChart";

const CircleChart = ({firstName, lastName, params, id}) => {
  if (params.length === 0) {
    return null;
  }

  if (params.length === 1) {
    return (
      <SingleWhiteChart
        id={id}
        firstName={firstName}
        lastName={lastName}
        percent={params[0].percent}
        title={params[0].title}
        targetValue={params[0].targetValue}
        value={params[0].value}
        date={params[0].date}
      />
    );
  }

  return (
    <MutiplyCircleWhiteChart
      id={id}
      firstName={firstName}
      lastName={lastName}
      params={params}
    />
  );
}

export default CircleChart;
