import React from 'react';
import cls from './bonus.module.scss';

export default function BonusLayout({content, fixed}) {
    return (
        <div className={cls.bonusLayout}>
            <div className={cls.bonusLayoutContent}>
                {content}
            </div>
            <div className={cls.bonusLayoutFixed}>
                {fixed}
            </div>
        </div>
    );
}
