import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {gql, useQuery} from "@apollo/client";

import {Empty, message, Pagination, Skeleton} from 'antd';

import {postingDefault} from '../../routes';

import CrmLayout from "../../components/CrmLayout";
import CrmCenteredTitle from "../../components/CrmCenteredTitle";
import {PostRow} from "./PostRow";

import cls from '../../styles/list.module.scss';

const QUERY_POSTINGS = gql`
    query postings ($filters: PostingFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        postings (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    createdAt
                    message
                    uid
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

export default function Postings() {
 // todo empty тренера и залы - дописать бек
    const navigate = useNavigate();

    const [page, setPage] = useState(1);

    const query = useQuery(QUERY_POSTINGS, {
        variables: {
            pagination: {
                pageSize: 10,
                page: page
            },
        },
        fetchPolicy: "no-cache",
        onError: (error) => {
            message.error(error.message)
            console.error(error)
        },

    });

    let items = null;

    if (query.data !== undefined) {

        if (query.data.postings.data.length > 0) {

            items = query.data.postings.data.map(function (value) {
                return (
                    <PostRow key={value.id} data={value}/>
                );
            });
        } else {
            items = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>;
        }
    }

    function goAdd() {
        navigate(postingDefault());
    }

    return (
        <CrmLayout>
            <CrmCenteredTitle
                title={'Рассылки'}
                addAction={() => {
                    goAdd()
                }}
            />
            {query.loading
                ?
                <Skeleton active/>
                :
                <>

                    <div className={cls.list}>
                        {items}
                    </div>

                    {query.data && query.data.postings.data.length > 0 ? <Pagination
                        className={'crm-task-pagination'}
                        defaultCurrent={query.data.postings.meta.pagination.page}
                        current={query.data.postings.meta.pagination.page}
                        total={query.data.postings.meta.pagination.total}
                        pageSize={query.data.postings.meta.pagination.pageSize}
                        onChange={(page) => {setPage(page)}}
                    /> : null}

                </>
            }
        </CrmLayout>
    );
}



