import React, {useState} from 'react';
import {Button, DatePicker, Divider, Form, Input, Select, Spin} from 'antd';
import {
    EnvironmentOutlined,
    MailOutlined,
    MinusCircleOutlined,
    PhoneOutlined,
    PlusOutlined,
    SendOutlined,
    WhatsAppOutlined
} from '@ant-design/icons';
import MaskedInput from "antd-mask-input";
import {gql, useQuery} from "@apollo/client";
import debounce from 'debounce-promise';
import translit from "../../../helpers/translit";
import dayjs from 'dayjs';
import _ from 'lodash';
import axios from "axios";
import {getRestApiBase} from "../../../env";

const QUERY_GYMS = gql`
    query gyms ($filters: GymFiltersInput, $pagination: PaginationArg, $sort: [String] = []) {
        gyms (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    name
                    enabled
                    address
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

const {Option} = Select;

export default function ParentForm({data, onSubmit, loading, withEmail, withGym, actionName, actionIcon}) {

    const [formData, setFormData] = useState(data);
    const [customEmail, setCustomEmail] = useState(false);

    const [form] = Form.useForm();

    const query = useQuery(QUERY_GYMS, {
        variables: {
            filters: {or: [{enabled: {eq: true}}, {enabled: {null: true}}]},
            pagination: {
                limit: 200
            },
            sort: ['id:ASC'],
        }
    });

    const onFinish = (values) => {
        let newData = {...values};
        newData.birthDate = values.birthDate && dayjs(values.birthDate).format('YYYY-MM-DD');
        onSubmit(newData);
        form.resetFields();
    };

    const onFormChange = () => {
        setFormData(form.getFieldValue('contacts'))
    };

    const onNameChange = () => {
        const firstName = form.getFieldValue('firstName');
        const lastName = form.getFieldValue('lastName');

        if (firstName && lastName) {
            const username = `${translit(firstName)}-${translit(lastName)}`;
            if (!customEmail) {
                form.setFieldsValue({email: `${username}@trainer-crm.ru`});
                form.validateFields(['email']);
            }
        }

    };

    const emailExist = debounce(async (value) => {
        // console.log('emailExist request', value);

        const {data} = await axios.get(
            `${getRestApiBase()}/checkemail?email=${value}`
        );
        // console.log('emailExist response', data);
        return data;

    }, 1000);

    const emailValidate = async (rule, value) => {

        const exist = await emailExist(value);
        if (exist) {
            return Promise.reject('Этот email занят, введите другой');
        } else {
            return Promise.resolve();
        }

    };

    let newData = {...data};
    newData.birthDate = data && data.birthDate && dayjs(data.birthDate);

    // console.log('queryEmailData', queryEmailData.data);

    return (

        <Form name="parent"
              form={form}
              onFinish={onFinish}
              initialValues={newData}
              layout={'vertical'}
              size={'large'}
              disabled={loading}
              scrollToFirstError
        >

            <Form.Item name={'firstName'} label={'Имя'} required
                       rules={[{required: true, message: 'Заполните поле',},]}>
                <Input placeholder='Константин' onChange={onNameChange}/>
            </Form.Item>

            <Form.Item name={'lastName'} label={'Фамилия'} required
                       rules={[{required: true, message: 'Заполните поле',},]}>
                <Input placeholder='Иванов' onChange={onNameChange}/>
            </Form.Item>

            {withEmail && (
                <>
                    <Divider>Аккаунт для входа</Divider>

                    <Form.Item name={'email'}
                               label={'Email для входа'}
                               required
                               hasFeedback
                               validateFirst={true}
                               rules={[
                                   {required: true, message: 'Заполните поле',},
                                   {type: 'email', message: 'Введите корректный email',},
                                   {validator: emailValidate},
                               ]}>
                        <Input placeholder='name@example.com' onChange={() => {
                            setCustomEmail(true)
                        }}/>
                    </Form.Item>
                </>
            )}

            <Divider>Личные контакты</Divider>

            <Form.List
                name="contacts"
            >
                {(fields, {add, remove}, {errors}) => (
                    <>
                        {fields.map(({key, name, ...restField}) => (

                            <div key={key} className={'form-contact'}>

                                <Form.Item {...restField} name={[name, 'type']}
                                           rules={[{required: true, message: 'Выберите тип контакта',},]}
                                           className={'form-contact-select'}>
                                    <Select onSelect={onFormChange} dropdownMatchSelectWidth={false}>
                                        <Select.Option value='phoneWhatsapp'><PhoneOutlined/> <WhatsAppOutlined/> Телефон + WhatsApp</Select.Option>
                                        <Select.Option value='phone'><PhoneOutlined/> Телефон</Select.Option>
                                        <Select.Option value='whatsapp'><WhatsAppOutlined/> WhatsApp</Select.Option>
                                        <Select.Option value='email'><MailOutlined/> Почта</Select.Option>
                                        <Select.Option value='telegram'><SendOutlined/> Telegram</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item name={[name, 'data']} hidden={true}>
                                    <Input/>
                                </Form.Item>

                                {(form.getFieldValue('contacts')[key] && form.getFieldValue('contacts')[key].type === 'email') &&
                                    <Form.Item {...restField} name={[name, 'value']}
                                               rules={[
                                                   {required: true, message: 'Заполните поле',},
                                                   {type: 'email', message: 'Введите корректный email',}
                                               ]}
                                               className={'form-contact-input'} required>
                                        <Input onChange={onFormChange}/>
                                    </Form.Item>
                                }

                                {(form.getFieldValue('contacts')[key] && form.getFieldValue('contacts')[key].type === 'telegram') &&
                                    <Form.Item {...restField} name={[name, 'value']}
                                               rules={[
                                                   {required: true, message: 'Заполните поле',},
                                               ]}
                                               className={'form-contact-input'} required>
                                        <Input onChange={onFormChange} placeholder={'Chat ID'}/>
                                    </Form.Item>
                                }

                                {(form.getFieldValue('contacts')[key] && (
                                    form.getFieldValue('contacts')[key].type === 'phone' ||
                                    form.getFieldValue('contacts')[key].type === 'whatsapp' ||
                                    form.getFieldValue('contacts')[key].type === 'phoneWhatsapp'
                                    )) &&
                                    <Form.Item {...restField} name={[name, 'value']}
                                           rules={[{required: true, message: 'Заполните поле',},]}
                                           className={'form-contact-input'} required>
                                    <MaskedInput onChange={onFormChange} mask={'+7 (000) 000-00-00'} size={'large'}/>
                                </Form.Item>
                                }

                                <Form.Item>
                                    <Button icon={<MinusCircleOutlined/>} onClick={() => remove(name)}
                                            type={'dashed'}/>
                                </Form.Item>

                            </div>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} icon={<PlusOutlined/>}/>
                            <Form.ErrorList errors={errors}/>
                        </Form.Item>
                    </>
                )}
            </Form.List>

            <Form.Item name={'birthDate'} label={'Дата рождения'}>
                <DatePicker
                    format={'DD.MM.YYYY'}
                />
            </Form.Item>

            {withGym &&
            <Form.Item name={'gym'} label={'Занимается в зале'}>
                <Select
                    filterOption={false}
                    notFoundContent={query.loading ? <Spin size="small"/> : null}
                    placeholder="Выберите зал"
                    dropdownMatchSelectWidth={false}
                >
                    <Option key={0} value={null}>Без зала</Option>
                    {query.data && _.map(query.data.gyms.data, (value) => {
                        return (
                            <Option key={value.id} value={value.id}><EnvironmentOutlined/> <span
                                className={'bold'}>{value.attributes.name}</span> {value.attributes.address}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>
            }

            <Form.Item>
                <Button type="primary" htmlType="submit" icon={actionIcon} loading={loading}>
                    {actionName}
                </Button>
            </Form.Item>


        </Form>
    );

}