import React from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';

import {Divider, message, Modal, Skeleton, Space, Switch} from 'antd';

import {client_info, INDEX, NOT_FOUND, task_edit} from "../../routes";
import {gql, useMutation, useQuery} from "@apollo/client";

import moment from 'moment';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import CrmAvatar from "../../components/CrmAvatar";
import CrmCenteredTitle from "../../components/CrmCenteredTitle";
import CrmLayout from "../../components/CrmLayout";
import CrmDisplayData from "../../components/CrmDisplayData";
import ClientMessage from "../Client/ClientMessage";
import TaskAddComment from "./TaskAddComment";

const QUERY_TASK = gql`
    query task ($id: ID, $mesfilter: MessageFiltersInput) {
        task (id: $id) {
            data {
                id
                attributes {
                    name
                    comment
                    date
                    done
                    user {
                        data {
                            id
                            attributes {
                               role {
                                   data {
                                       attributes {
                                           name
                                       }
                                   }
                               }
                                parent {
                                    data {
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                        }
                                    }
                                }
                                client {
                                    data {
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                        }
                                    }
                                }
                            }
                        }
                    }
                    executor {
                        data {
                            id
                            attributes {
                                trainer {
                                    data {
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                        }
                                    }
                                }
                            }
                        }
                    }
                    messages (filters: $mesfilter) {
                        data {
                            id
                            attributes {
                                author {
                                    data {
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                        }
                                    }
                                }
                                message
                                fileUrl
                                date
                            }
                        }
                    }
                }
            }
        }
    }
`;

const DELETE_TASK = gql`
    mutation deleteTask($id: ID!) {
        deleteTask(id: $id) {
            data {
                id
            }
        }
    }
`;

const UPDATE_TASK = gql`
    mutation updateTask($data: TaskInput!, $id: ID!) {
        updateTask(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

export default function TaskInfo() {

    const {id} = useParams();

    const navigate = useNavigate();

    const query = useQuery(QUERY_TASK, {
        variables: {
            id: id,
            mesfilter: {
                or: [{
                    enabled: {eq: true}
                }, {
                    enabled: {null: true}
                }]
            }
        },
        fetchPolicy: "network-only"
    });

    if (query.data !== undefined) {

        if (query.data.task.data === null) {
            navigate(NOT_FOUND, {replace: true})
        }
    }

    const [deleteTaskMutation] = useMutation(DELETE_TASK);

    const [updateTaskMutation, {loading}] = useMutation(UPDATE_TASK);
    const onChengeDone = async () => {
        const newDone = !query.data.task.data.attributes.done;
        try {
            await updateTaskMutation({variables: {id: id, data: {done: newDone}}});
            query.refetch();
        } catch (error) {
            console.error(error);
            message.error('Что-то пошло не так, попробуйте снова');
        }
    };

    function onDelete() {
        Modal.confirm({
            title: 'Удалить',
            icon: <ExclamationCircleOutlined/>,
            content: 'Действительно удалить задачу?',
            okText: 'Да',
            cancelText: 'Нет',
            onOk() {
                onConfirmedDelete()
            }
        });
    }

    async function onConfirmedDelete() {
        await deleteTaskMutation({
            variables: {
                id: id
            }
        });
        navigate(INDEX, {replace: true});
    }

    let client = null;

    if (query.data) {
        if (query.data.task.data.attributes.user.data) {
            let person = null;
            let child = false;
            const role = query.data.task.data.attributes.user.data.attributes.role.data;
            if (role.attributes.name === 'Child') {
                child = true;
                person = query.data.task.data.attributes.user.data.attributes.client.data;
            } else if (role.attributes.name === 'Parent') {
                person = query.data.task.data.attributes.user.data.attributes.parent.data;
            }
            if (person) {
                const firstName = person.attributes.firstName;
                const lastName = person.attributes.lastName;
                const color = person.attributes.color;

                client =
                    <CrmDisplayData label={'Клиент'} value={
                        <Link to={client_info(query.data.task.data.attributes.user.data.id)}>
                            <CrmAvatar
                                firstName={firstName}
                                lastName={lastName}
                                color={color}
                                child={child}
                            />
                        </Link>
                    }/>
            }
        }
    }

    return (

        <CrmLayout>

            <CrmCenteredTitle
                title={'Задача'}
                editAction={() => {
                    navigate(task_edit(id))
                }}
                deleteAction={() => {
                    onDelete()
                }}
            />

            {query.loading && <Skeleton active/>}

            {query.data &&
            <Space direction={'vertical'} size={20} className={'block'}>

                <CrmDisplayData label={'Название'} value={query.data.task.data.attributes.name}/>

                {query.data.task.data.attributes.comment &&
                <div className='crm-message'>
                    {query.data.task.data.attributes.comment}
                </div>
                }

                <CrmDisplayData label={'Исполнитель'} value={
                    <CrmAvatar type={'user'}
                               firstName={query.data.task.data.attributes.executor.data.attributes.trainer.data.attributes.firstName}
                               lastName={query.data.task.data.attributes.executor.data.attributes.trainer.data.attributes.lastName}
                               color={query.data.task.data.attributes.executor.data.attributes.trainer.data.attributes.color}
                    />
                }/>

                {client}

                <CrmDisplayData label={'Дата исполнения'}
                                value={moment(query.data.task.data.attributes.date).format('DD MMMM YYYY')}
                />

                <span>
                    <Switch
                        checked={query.data.task.data.attributes.done}
                        onChange={onChengeDone}
                        loading={loading}
                    /> {query.data.task.data.attributes.done ? ' Выполнена' : ' Не выполнена'}
                </span>

                <Divider>Комментарии</Divider>

                <Space direction='vertical' size={20} className='block'>

                    {query.data.task.data.attributes.messages.data.map(function (value, index) {
                        return <ClientMessage
                            key={index}
                            message={value.attributes}
                            id={value.id}
                            onUpdate={() => query.refetch()}
                        />
                    })}

                    <TaskAddComment taskId={id} onUpdate={() => query.refetch()}/>

                </Space>



            </Space>
            }

        </CrmLayout>

    );
}



