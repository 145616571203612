import React from 'react';

const BgSvg = () => {
  return (
    <>
      <g clipPath="url(#clip0_816_11411)">
          <rect width="1080" height="1920" fill="#2D68C4"/>
          <g clipPath="url(#clip0_2541_3232)">
              <path d="M256.809 189.074C262.686 191.447 270.67 192.634 280.759 192.634C286.989 192.634 293.915 192.482 301.535 192.179C309.156 191.877 316.116 191.457 322.415 190.92L323.12 170.66C311.855 172.066 300.012 173.238 287.592 174.176C281.626 174.645 274.216 175.045 265.361 175.377V138.257C273.677 138.589 281.087 138.989 287.592 139.458C297.652 140.19 309.328 141.362 322.62 142.973L321.915 122.773C315.332 122.245 308.324 121.825 300.889 121.513C293.454 121.2 286.744 121.054 280.759 121.073C270.699 121.073 262.716 122.26 256.809 124.633C251.266 126.685 246.687 130.722 243.967 135.957C241.322 141.142 240 148.115 240 156.875C240 165.635 241.322 172.608 243.967 177.794C246.696 183.012 251.273 187.033 256.809 189.074Z" fill="white"/>
              <path d="M361.058 174.879H360.367V122.699H337.71V190.934H373.048L400.921 138.754H401.626V190.934H424.268V122.699H388.946L361.058 174.879Z" fill="white"/>
              <path d="M445.912 192.326C448.733 192.531 451.113 192.633 453.053 192.633C457.704 192.753 462.327 191.864 466.6 190.026C470.441 188.23 473.625 185.286 475.71 181.603C478.248 176.821 479.722 171.55 480.029 166.148L482.233 141.816H509.005V190.978H532.147V122.772H462.206L457.461 172.008C454.933 172.008 452.053 171.729 448.762 171.378C444.579 170.958 440.774 170.47 437.345 169.913L438.051 191.593C440.46 191.857 443.076 192.135 445.912 192.326Z" fill="white"/>
              <path d="M572.553 177.779H608.081L613.959 190.963H639.231V190.465L607.126 122.772H574.008L542.211 190.495V190.993H566.661L572.553 177.779ZM590.611 138.754L601.117 162.193H579.503L590.009 138.754H590.611Z" fill="white"/>
              <path d="M256.809 276.44C262.686 278.813 270.67 280 280.759 280C286.989 280 293.915 279.849 301.535 279.546C309.156 279.243 316.116 278.823 322.415 278.286L323.12 258.027C311.855 259.433 300.012 260.605 287.592 261.542C281.626 262.011 274.216 262.411 265.361 262.743V225.623C273.677 225.945 281.087 226.346 287.592 226.824C297.652 227.557 309.328 228.729 322.62 230.34L321.915 210.066C315.332 209.538 308.324 209.118 300.889 208.806C293.454 208.493 286.744 208.347 280.759 208.366C270.699 208.366 262.716 209.553 256.809 211.926C251.263 213.982 246.685 218.024 243.967 223.265C241.322 228.45 240 235.423 240 244.183C240 252.943 241.322 259.916 243.967 265.102C246.685 270.342 251.263 274.385 256.809 276.44Z" fill="white"/>
              <path d="M418.024 210.578V210.066H390.048L366.494 234.954H360.867V210.066H337.71V278.301H360.867V253.016H365.995L389.548 278.301H419.537V277.803L385.317 242.982L418.024 210.578Z" fill="white"/>
              <path d="M517.159 278.301V210.066H481.837L453.949 262.245H453.244V210.066H430.601V278.301H465.924L493.812 226.121H494.517V278.301H517.159Z" fill="white"/>
              <path d="M530.942 278.901C533.351 279.224 535.967 279.502 538.788 279.692C541.609 279.883 543.989 280 545.929 280C550.576 280.118 555.193 279.228 559.461 277.392C563.305 275.601 566.49 272.656 568.571 268.969C571.115 264.189 572.593 258.917 572.906 253.515L575.124 229.124H601.881V278.286H625.037V210.066H555.097L550.351 259.301C547.824 259.301 544.944 259.023 541.653 258.671C537.461 258.241 533.655 257.753 530.236 257.206L530.942 278.901Z" fill="white"/>
              <path d="M676.464 268.969C679.003 264.188 680.476 258.916 680.784 253.515L683.002 229.124H709.773V278.286H732.915V210.066H662.975L658.229 259.301C655.702 259.301 652.822 259.023 649.531 258.671C645.348 258.241 641.543 257.753 638.114 257.206L638.82 278.887C641.229 279.209 643.859 279.487 646.666 279.678C649.472 279.868 651.867 279.985 653.821 279.985C658.469 280.104 663.086 279.214 667.354 277.378C671.193 275.586 674.377 272.647 676.464 268.969Z" fill="white"/>
              <path d="M773.322 265.146H808.835L814.713 278.33H839.985V277.832L807.88 210.11H774.776L742.98 277.832V278.33H767.43L773.322 265.146ZM791.38 226.121L801.886 249.559H780.272L790.777 226.121H791.38Z" fill="white"/>
          </g>
      </g>
    </>
  );
}

export default BgSvg;
