import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Styles and locales
import './styles/App.scss';
import {ConfigProvider} from 'antd';
import ru_RU from 'antd/lib/locale/ru_RU';
import moment from 'moment';
import 'moment/locale/ru';
import 'dayjs/locale/ru'
import dayjs from "dayjs";

moment.updateLocale("ru", {week: {dow: 1}});
dayjs.locale('ru')

ReactDOM.render(
    <ConfigProvider locale={ru_RU}>
        <App/>
    </ConfigProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
