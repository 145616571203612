import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {useInstance} from "react-ioc";
import {observer} from "mobx-react-lite";
import {Store} from "../../model/store/Store";

import moment from 'moment';

import {calendar} from "../../routes";

function Index() {

    const {auth} = useInstance(Store);
    const navigate = useNavigate();

    useEffect(() => {
        const date = moment().startOf('isoWeek').format('YYYY-MM-DD');
        const user = auth.role === 'Admin' || auth.role === 'Authenticated' ? 'all' : auth.id;
        navigate(calendar(date, user), {replace: true});
    }, [auth, navigate]);


    return null;
}

export default observer(Index);