import {toFactory} from 'react-ioc';
import {Store} from './Store';

const StoreDI = () => [
  Store,
  toFactory(
    () => Store.create(),
  ),
];


export default StoreDI;
