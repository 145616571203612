import React from 'react';
// import {useNavigate} from 'react-router-dom';
// import {USER} from "../../routes";
import {Avatar, Space} from 'antd';
// import {EnvironmentOutlined} from '@ant-design/icons';

export default function UserListRow(props) {

    // const navigate = useNavigate();

    // function go() {
    //     navigate(USER+'/'+props.id)
    // }

    return (
        <div className='client-list-row'>
            <Space size={10}>
                <Avatar size='large' style={{backgroundColor: props.color, color: '#333333'}}>{props.firstName.charAt(0)}{props.lastName.charAt(0)}</Avatar>
                <div>
                    {props.firstName} {props.lastName}
                    {/*<div className='caption'><EnvironmentOutlined/> {props.gym}</div>*/}
                </div>
            </Space>
        </div>
    );
}
