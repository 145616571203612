import {ResponsiveContainer,} from 'recharts';

import hash from 'object-hash';
import map from 'lodash/map';
import last from 'lodash/last';
import {Carousel} from 'antd';

import CircleChart from "../../../components/Charts/CircleChart";
import moment from "moment";

import SkillsListChart from "../../../components/Charts/SkillsListChart";
import MutiplyCircleWhiteChart from "../../../components/Charts/includes/MutiplyCircleWhiteChart";
import PolygonChart from "../../../components/Charts/PolygonChart";
import FiveCirclesChart from "../../../components/Charts/includes/FiveCirclesChart";
import EightCirclesChart from "../../../components/Charts/includes/EightCirclesChart";
import GraphSend from "./GraphSend";

export default function Graph({firstName, lastName, indicators, skills, contacts, clientID}) {

    // данные графиков
    const targetCount = indicators.length;
    // данные скилов
    const skillParams = map(skills, skill => {
        return {name: skill.name, own: skill.own}
    });

    // данные мультиграфиков
    const paramsToMulti = map(indicators, (item) => {
        const {goal, startValue} = item;
        const lastResult = last(item.result);
        const lastRecordedValue = lastResult.value;

        let percent = ((lastRecordedValue - startValue) * 100) / (goal - startValue);

        if (percent < 0) {
            percent = 0;
        }

        const date = lastResult.date;

        return {
            percent: percent,
            title: item.name,
            targetValue: goal,
            value: lastRecordedValue,
            date: moment(date).format('DD MMM YYYY'),
        };
    });

    // данные для полигонов
    const paramsToPolygon = map(paramsToMulti, (item) => {
        return {
            ...item,
            percent: item.percent > 100 ? 100 : item.percent,
        }
    });

    return (
        <>
            <Carousel className={'crm-client-graph'}>

                {targetCount >= 2 && targetCount <= 3 && (
                    <ResponsiveContainer width='100%' height={400}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            maxHeight: 400
                        }}>
                            <div className="chart-root">
                                <MutiplyCircleWhiteChart
                                    key={new Date().getTime()}
                                    id={`chart-main1-${hash(paramsToMulti)}`}
                                    firstName={firstName}
                                    lastName={lastName}
                                    params={paramsToMulti}
                                />
                            </div>
                            <div className="hidden-chart-root">
                                <MutiplyCircleWhiteChart
                                    key={new Date().getTime()}
                                    id={`hidden-chart-main1-${hash(paramsToMulti)}`}
                                    firstName={firstName}
                                    lastName={lastName}
                                    params={paramsToMulti}
                                />
                            </div>
                        </div>
                    </ResponsiveContainer>
                )}

                {targetCount > 3 && targetCount <= 5 && (
                    <ResponsiveContainer width='100%' height={400}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            maxHeight: 400
                        }}>
                            <div className="chart-root">
                                <FiveCirclesChart
                                    key={new Date().getTime()}
                                    id={`chart-main1-${hash(paramsToMulti)}`}
                                    firstName={firstName}
                                    lastName={lastName}
                                    params={paramsToMulti}
                                />
                            </div>
                            <div className="hidden-chart-root">
                                <FiveCirclesChart
                                    key={new Date().getTime()}
                                    id={`hidden-chart-main1-${hash(paramsToMulti)}`}
                                    firstName={firstName}
                                    lastName={lastName}
                                    params={paramsToMulti}
                                />
                            </div>
                        </div>
                    </ResponsiveContainer>
                )}

                {targetCount > 5 && targetCount <= 8 && (
                    <ResponsiveContainer width='100%' height={400}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            maxHeight: 400
                        }}>
                            <div className="chart-root">
                                <EightCirclesChart
                                    key={new Date().getTime()}
                                    id={`chart-main1-${hash(paramsToMulti)}`}
                                    firstName={firstName}
                                    lastName={lastName}
                                    params={paramsToMulti}
                                />
                            </div>
                            <div className="hidden-chart-root">
                                <EightCirclesChart
                                    key={new Date().getTime()}
                                    id={`hidden-chart-main1-${hash(paramsToMulti)}`}
                                    firstName={firstName}
                                    lastName={lastName}
                                    params={paramsToMulti}
                                />
                            </div>
                        </div>
                    </ResponsiveContainer>
                )}


                {targetCount >= 2 && targetCount >= 3 && (
                    <ResponsiveContainer width='100%' height={400}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            maxHeight: 400
                        }}>
                            <div className="chart-root">
                                <PolygonChart
                                    key={new Date().getTime()}
                                    id={`chart-main2-${hash(paramsToPolygon)}`}
                                    firstName={firstName}
                                    lastName={lastName}
                                    params1={paramsToPolygon}
                                />
                            </div>
                            <div className="hidden-chart-root">
                                <PolygonChart
                                    key={new Date().getTime()}
                                    id={`hidden-chart-main2-${hash(paramsToPolygon)}`}
                                    firstName={firstName}
                                    lastName={lastName}
                                    params1={paramsToPolygon}
                                />
                            </div>
                        </div>
                    </ResponsiveContainer>
                )}

                {/*Навыки*/}
                {skillParams.length > 0 && (
                    <ResponsiveContainer width='100%' height={400}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            maxHeight: 400
                        }}>
                            <div className="chart-root">
                                <SkillsListChart
                                    key={new Date().getTime()}
                                    id={`chart-skills-${hash(skillParams)}`}
                                    firstName={firstName}
                                    lastName={lastName}
                                    skills={skillParams}
                                />
                            </div>
                            <div className="hidden-chart-root">
                                <SkillsListChart
                                    key={new Date().getTime()}
                                    id={`hidden-chart-skills-${hash(skillParams)}`}
                                    firstName={firstName}
                                    lastName={lastName}
                                    skills={skillParams}
                                />
                            </div>
                        </div>
                    </ResponsiveContainer>
                )}

                {map(indicators, (item) => {
                    const {goal, startValue} = item;
                    const lastResult = last(item.result);
                    const lastRecordedValue = lastResult.value;

                    let percent = ((lastRecordedValue - startValue) * 100) / (goal - startValue);

                    if (percent < 0) {
                        percent = 0;
                    }

                    const date = lastResult.date;

                    const params = [
                        {
                            percent: percent,
                            title: item.name,
                            targetValue: goal,
                            value: lastRecordedValue,
                            date: moment(date).format('DD MMM YYYY'),
                        }
                    ];

                    return (
                        <ResponsiveContainer width='100%' height={400} key={hash(params)}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                maxHeight: 400
                            }}>
                                <div className="chart-root">
                                    <CircleChart
                                        key={new Date().getTime()}
                                        id={`chart-si-${hash(params)}`}
                                        firstName={firstName}
                                        lastName={lastName}
                                        params={params}
                                    />
                                </div>
                                <div className="hidden-chart-root">
                                    <CircleChart
                                        key={new Date().getTime()}
                                        id={`hidden-chart-si-${hash(params)}`}
                                        firstName={firstName}
                                        lastName={lastName}
                                        params={params}
                                    />
                                </div>
                            </div>
                        </ResponsiveContainer>
                    )
                })}
            </Carousel>
            <GraphSend clientID={clientID} contacts={contacts}/>
        </>
    );
}
