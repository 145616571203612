import React from 'react';
import BgSvg from "./includes/BgSvg";
import {map} from "lodash";

const SkillsListChart = ({firstName, lastName, skills, ...rest}) => {
    return (
        <svg width="1080" height="1920" viewBox="0 0 1080 1920" fill="none"
             xmlns="http://www.w3.org/2000/svg" {...rest}>
            <BgSvg/>
            <text fontSize="60" fontWeight="bold" fill="#fff" fontFamily="'Open Sans', sans-serif">
                <tspan x="50%" textAnchor="middle" y="420">{firstName}</tspan>
                {lastName && (
                    <tspan x="50%" textAnchor="middle" y="500">{lastName}</tspan>
                )}
            </text>

            <text fontSize="50" fontWeight="normal" fill="#fff" fontFamily="'Open Sans', sans-serif">
                <tspan x="80" y="692">Владеет навыками</tspan>
            </text>

            {map(skills, (skill, index) => {
                const originalTitle = skill.name;
                let title = originalTitle;
                if (originalTitle.length > 27) {
                    title = `${originalTitle.substr(0, 27)}...`;
                }

                let checkbox = '';

                if (skill.own) {
                    checkbox =
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="graph-6" fillRule="nonzero" transform={`translate(113, ${790 + (index) * 100})`}>
                            <path
                                d="M47.3241 7.03125H43.4234C42.8765 7.03125 42.3576 7.28237 42.0227 7.71205L19.0149 36.8583L7.98256 22.8795C7.81566 22.6676 7.60294 22.4962 7.36035 22.3783C7.11776 22.2604 6.85161 22.199 6.58189 22.1987H2.68122C2.30733 22.1987 2.10086 22.6284 2.32966 22.9185L17.6143 42.2824C18.3285 43.1864 19.7013 43.1864 20.4212 42.2824L47.6756 7.74554C47.9044 7.46094 47.698 7.03125 47.3241 7.03125Z"
                                fill="white"
                            />
                        </g>
                    </g>
                }


                return (
                    <React.Fragment key={index}>
                        <rect width={920} height={1} fill="#C4C4C4" y={660 + (index + 1) * 100} x="80"/>

                        {checkbox}

                        <text
                            fontSize="50" fontWeight="normal" fill="#fff" fontFamily="'Open Sans', sans-serif"
                            height={120}
                            width={920}
                        >
                            <tspan x="200" y={726 + (index + 1) * 100}>{title}</tspan>
                        </text>
                    </React.Fragment>
                );
            })}
        </svg>
    )
}

export default SkillsListChart;
