import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {child_info, NOT_FOUND, parent_info} from "../../routes";

import {gql, useQuery} from "@apollo/client";

const GET_CLIENT_INFO = gql`
    query client (
        $id: ID,
    ) {
        usersPermissionsUser(id: $id) {
            data {
                id
                attributes {
                    role {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                    parent {
                        data {
                            id
                        }
                    }
                    client {
                        data {
                            id
                        }
                    }
                }
            }
        }
    }
`;

export default function ClientInfo() {

    const {id} = useParams();

    const navigate = useNavigate();

    const [to, setTo] = useState();

    useQuery(GET_CLIENT_INFO, {
        variables: {
            id: id,
        },
        onCompleted: (data) => {
            // если такого клиента нет, то отправляем в 404

            if (!data.usersPermissionsUser.data) {
                setTo(NOT_FOUND);
            }

            const role = data.usersPermissionsUser.data.attributes.role.data;

            if (role.attributes.name === 'Child') {
                const child = data.usersPermissionsUser.data.attributes.client.data;
                setTo(child_info(child.id));
            } else if (role.attributes.name === 'Parent') {
                const parent = data.usersPermissionsUser.data.attributes.parent.data;
                setTo(parent_info(parent.id));
            } else {
                setTo(NOT_FOUND);
            }
        }
    });

    useEffect(() => {
        if (to) {
            navigate(to, {replace: true});
        }
    // eslint-disable-next-line
    }, [to]);

    return null

}


