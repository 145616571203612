import React from 'react';
import {useNavigate} from 'react-router-dom';

import {gql, useQuery} from "@apollo/client";
import {Button, Skeleton} from "antd";

import {ArrowRightOutlined} from '@ant-design/icons';
import {get_report_gym} from "../../routes";

const REPORT_GYM = gql`
    query reportGym ($endDate: String!, $id: String!, $startDate: String!) {
        reportGym (startDate: $startDate, endDate: $endDate, id: $id) {
            clientsTotal {
                count
            }
            clientsWithSubscription {
                count
            }
            clientsWithoutSubscription {
                count
            }
            clientsFirstSubscription {
                count
            }
            clientsNotExtend {
                count
            }
            totalMoney {
                sumPaid
            }
            
#            clientsNew {
#                count
#            }
#            clientsRecorded {
#                count
#            }
#            clientsNotRecorded {
#                count
#            }
#            clientsVisited {
#                count
#            }
#            clientsNotVisited {
#                count
#            }
#            visits {
#                count
#            }
#            trainingsTotal {
#                count
#            }
#            subscriptionsTotal {
#                count
#                sum
#            }
#            subscriptionsFirst {
#                count
#                sum
#            }
#            subscriptionsPaid {
#                count
#                sum
#            }
#            subscriptionsNotPaid {
#                count
#                sum
#            }

        }
    }
`;

export default function ReportGymsGroup(props) {

    const {id, range} = props;

    const reportGym = useQuery(REPORT_GYM, {
        variables: {
            startDate: range[0].format('YYYY-MM-DD'),
            endDate: range[1].format('YYYY-MM-DD'),
            id: id
        }
    });

    const navigate = useNavigate();

    return (
        <>
            {reportGym.loading
                ?
                <Skeleton active />
                :
                <>
                    <div className={'crm-report-line'}>
                        Всего клиентов
                        <div className={'crm-report-count'}>{reportGym.data.reportGym.clientsTotal.count}</div>
                        <Button type={'link'} icon={<ArrowRightOutlined/>} onClick={() => {navigate(get_report_gym('clientsTotal', id, range[0].format('YYYY-MM-DD'),  range[1].format('YYYY-MM-DD')))}}/>
                    </div>
                    <div className={'crm-report-line'}>
                        С абонементами
                        <div className={'crm-report-count'}>{reportGym.data.reportGym.clientsWithSubscription.count}</div>
                        <Button type={'link'} icon={<ArrowRightOutlined/>} onClick={() => {navigate(get_report_gym('clientsWithSubscription', id, range[0].format('YYYY-MM-DD'),  range[1].format('YYYY-MM-DD')))}}/>
                    </div>
                    <div className={'crm-report-line'}>
                        Без абонемента
                        <div className={'crm-report-count'}>{reportGym.data.reportGym.clientsWithoutSubscription.count}</div>
                        <Button type={'link'} icon={<ArrowRightOutlined/>} onClick={() => {navigate(get_report_gym('clientsWithoutSubscription', id, range[0].format('YYYY-MM-DD'),  range[1].format('YYYY-MM-DD')))}}/>
                    </div>
                    <div className={'crm-report-line'}>
                        Впервые оформили абонемент
                        <div className={'crm-report-count'}>{reportGym.data.reportGym.clientsFirstSubscription.count}</div>
                        <Button type={'link'} icon={<ArrowRightOutlined/>} onClick={() => {navigate(get_report_gym('clientsFirstSubscription', id, range[0].format('YYYY-MM-DD'),  range[1].format('YYYY-MM-DD')))}}/>
                    </div>
                    <div className={'crm-report-line'}>
                        Не продлили абонемент
                        <div className={'crm-report-count'}>{reportGym.data.reportGym.clientsNotExtend.count}</div>
                        <Button type={'link'} icon={<ArrowRightOutlined/>} onClick={() => {navigate(get_report_gym('clientsNotExtend', id, range[0].format('YYYY-MM-DD'),  range[1].format('YYYY-MM-DD')))}}/>
                    </div>
                    <div className={'crm-report-line'}>
                        Поступления
                        <div className={'crm-report-count'}>{reportGym.data.reportGym.totalMoney.sumPaid} ₽</div>
                        <Button type={'link'} icon={<ArrowRightOutlined/>} onClick={() => {navigate(get_report_gym('totalMoney', id, range[0].format('YYYY-MM-DD'),  range[1].format('YYYY-MM-DD')))}}/>
                    </div>
                </>
            }

        </>
    );
}



