import React from 'react';
import {useNavigate} from 'react-router-dom';
import CrmLayout from "../../components/CrmLayout";
import moment from 'moment';

import {Empty, Skeleton} from 'antd';

import {task_new} from "../../routes";
import {gql, useQuery} from "@apollo/client";

import CrmCenteredTitle from "../../components/CrmCenteredTitle";
import TaskGroup from "./TaskGroup";

const QUERY_USERS = gql`
    query users ($filters: UsersPermissionsUserFiltersInput, $pagination: PaginationArg, $sort: [String] = []) {
        usersPermissionsUsers (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    trainer {
                        data {
                            attributes {
                                firstName
                                lastName
                                color
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

export default function Task() {

    const navigate = useNavigate();

    const query = useQuery(QUERY_USERS, {
        variables: {
            filters: {
                role: {id: {in: [3, 4, 5]}}
                //     name: {
                //         ne: 'Authenticated'
                //     }
                // }
            },
            sort: ['trainer.lastName:ASC'],
            pagination: {
                limit: 200
            }
        }
    });

    let items = null;

    if (query.data !== undefined) {

        if (query.data.usersPermissionsUsers.data.length > 0) {

            items = query.data.usersPermissionsUsers.data.map(function (value) {

                const trainer = value.attributes.trainer.data;

                const user = {
                    firstName: trainer.attributes.firstName,
                    lastName: trainer.attributes.lastName,
                    color: trainer.attributes.color,
                }

                return (
                    <TaskGroup id={value.id} user={user} key={value.id}/>
                );
            });
        } else {
            items = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>;
        }
    }

    function goAdd() {
        navigate(task_new(moment().format('YYYY-MM-DD'), 0));
    }

    return (

        <CrmLayout>
            <CrmCenteredTitle
                title={'Задачи'}
                addAction={() => {
                    goAdd()
                }}
            />
            {query.loading
                ?
                <Skeleton active/>
                :
                items
            }
        </CrmLayout>
    );
}



