// clientNoContacts
// clientBirthday
// clientNoSubscriptions
// clientNoVisits
// clientVisitFalse
// subscriptionNotPaid
// paymentFailed

// День рождения
// Не заполнены контакты
// Нет абонемента
// Нет тренировки
// Не посетил тренировку
// Не оплачен абонемент
// Ошибка оплаты

export const OPTIONS = {
    'clientNoContacts': {
        value: 'clientNoContacts',
        name: 'Не заполнены контакты',
        author: 'Тот, кто создал клиента',
        trainer: null
    },
    'clientBirthday': {
        value: 'clientBirthday',
        name: 'День рождения',
        author: 'Тот, кто создал клиента',
        trainer: null
    },
    'clientNoSubscriptions': {
        value: 'clientNoSubscriptions',
        name: 'Не создан абонемент',
        author: 'Тот, кто создал клиента',
        trainer: null
    },
    'clientNoVisits': {
        value: 'clientNoVisits',
        name: 'Нет тренировки',
        author: 'Тот, кто создал клиента',
        trainer: null
    },
    'clientVisitFalse': {
        value: 'clientVisitFalse',
        name: 'Не посетил тренировку',
        author: 'Тот, кто создал клиента',
        trainer: 'Тренер'
    },
    'subscriptionNotPaid': {
        value: 'subscriptionNotPaid',
        name: 'Не оплачен абонемент',
        author: 'Тот, кто создал абонемент',
        trainer: null
    },
    'subscriptionEnd': {
        value: 'subscriptionEnd',
        name: 'Закончился абонемент',
        author: 'Тот, кто создал абонемент',
        trainer: null
    },
    'paymentFailed': {
        value: 'paymentFailed',
        name: 'Ошибка оплаты',
        author: 'Тот, кто создал абонемент',
        trainer: null
    },
};

export const OFFSETS = {
    '-7': {
        value: -7,
        name: 'За 7 дней'
    },
    '-6': {
        value: -6,
        name: 'За 6 дней'
    },
    '-5': {
        value: -5,
        name: 'За 5 дней'
    },
    '-4': {
        value: -4,
        name: 'За 4 дня'
    },
    '-3': {
        value: -3,
        name: 'За 3 дня'
    },
    '-2': {
        value: -2,
        name: 'За 2 дня'
    },
    '-1': {
        value: -1,
        name: 'За 1 день'
    },
    '0': {
        value: 0,
        name: 'В день события'
    },
    '1': {
        value: 1,
        name: 'Через 1 день'
    },
    '2': {
        value: 2,
        name: 'Через 2 дня'
    },
    '3': {
        value: 3,
        name: 'Через 3 дня'
    },
    '4': {
        value: 4,
        name: 'Через 4 дня'
    },
    '5': {
        value: 5,
        name: 'Через 5 дней'
    },
    '6': {
        value: 6,
        name: 'Через 6 дней'
    },
    '7': {
        value: 7,
        name: 'Через 7 дней'
    },
}