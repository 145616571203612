import useFileUpload from "../../../hooks/useFileUpload";
import {getApiBase} from "../../../env";
import dataURLtoFile from "../../../helpers/dataURLtoFile";
import toImg from 'react-svg-to-image';
import _ from 'lodash';
import reduce from "lodash/reduce";
import {UPLOAD_FILES} from "../../../graphql/uploadFiles";
import {Alert, Button, Checkbox, Input, Radio, Space, Spin} from "antd";
import CrmModal from "../../../components/CrmModal";
import cn from "classnames";
import {WhatsAppOutlined} from "@ant-design/icons";
import {useEffect, useMemo, useState} from "react";
import {ApolloClient, useMutation} from "@apollo/client";
import {useInstance} from "react-ioc";
import {nanoid} from "nanoid";
import {CREATE_POST} from "../../../graphql/createPost";
import TelegramOutlined from "../../../vidgets/icon/TelegramOutlined";

const MAX_MESSAGE_LENGTH = 1000;

export default function GraphSend({clientID, contacts}) {

    const [showModal, setShowModal] = useState(false);
    const [provider, setProvider] = useState('telegram')
    const [message, setMessage] = useState('');
    const [images, setImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);

    // const [uploadFiles] = useLazyQuery(UPLOAD_FILES, {fetchPolicy: "network-only"})
    const uploadFile = useFileUpload();
    const apolloClient = useInstance(ApolloClient);
    const [createPost] = useMutation(CREATE_POST)

    const uploadImage = (id) => {
        return toImg(
            `#${id}`,
            'file', {
                format: 'png',
                download: false, // false чтобы не скивалась
            }).then(async fileData => {
            try {
                const fileName = `${clientID}-${id.replace('hidden-chart-', '')}.png`;

                const findFile = await apolloClient.query({
                    query: UPLOAD_FILES,
                    variables: {
                        fileName: fileName,
                    },
                    fetchPolicy: "network-only",
                });

                const data = _.get(findFile, 'data.uploadFiles.data');
                if (Array.isArray(data) && data.length > 0) {
                    // Такой файл уже есть
                    const existedFile = data[0];
                    // возращаем найденный файл
                    return `${getApiBase()}${existedFile.attributes.url}`;
                }

                // загружаем новый файл
                const file = dataURLtoFile(fileData, fileName);
                const res = await uploadFile(file);
                // возвращаем загруженный файл
                return `${getApiBase()}${res.data.upload.data.attributes.url}`;

            } catch (error) {
                console.error(error);
            }
        });
    }

    const maxLength = useMemo(() => {
        if (provider === 'whatsapp') {
            const linksLength = reduce(selectedImages, (sum, n) => {
                return sum + n.length;
            }, 0);

            return MAX_MESSAGE_LENGTH - linksLength - selectedImages.length;
        }
        return MAX_MESSAGE_LENGTH
    }, [selectedImages, provider]);

    useEffect(() => {
        if (!showModal) {
            setImages([]);
            setSelectedImages([]);
            return;
        }

        setTimeout(() => {
            setImages([]);

            let elements = document.querySelectorAll('svg[id^=hidden-chart]');
            const ids = _.uniq(_.map(elements, (el) => el.id));
            elements = null;

            const promises = _.map(ids, (id) => uploadImage(id))

            Promise.all(promises).then((res) => {
                setImages(res);
            });
        }, 500);
    }, [showModal])

    const genLink = (phoneNumber) => {
        let pn = '';
        if (phoneNumber !== null) {
            pn = phoneNumber.replace(/[^0-9]/g, '');
        }

        const imagesUrls = selectedImages.join("\n\n");
        let trimmedMessage = message.trim();
        if (trimmedMessage.length > 0) {
            trimmedMessage = `${trimmedMessage}\n\n`;
        }
        const text = `${trimmedMessage}${imagesUrls}`

        return `https://wa.me/${pn}?text=${encodeURIComponent(text)}`;
    }

    let availableContacts = [];

    for (const contact of contacts) {
        if (provider === 'whatsapp') {
            if (contact.type === 'whatsapp' || contact.type === 'phoneWhatsapp') {
                availableContacts.push(contact)
            }
        }
        if (provider === 'telegram') {
            if (contact.type === 'telegram') {
                availableContacts.push(contact)
            }
        }
    }

    const onChangeProvider = (value) => {
        setProvider(value)
        if (value === 'telegram' && selectedImages.length > 1) {
            setSelectedImages(selectedImages[0])
        }
    }

    const onToggleImage = (image) => {
        if (provider === 'whatsapp') {
            if (selectedImages.includes(image)) {
                setSelectedImages(_.filter(selectedImages, item => item !== image))
            } else {
                setSelectedImages([...selectedImages, image])
            }
        }
        if (provider === 'telegram') {
            if (selectedImages.includes(image)) {
                setSelectedImages([])
            } else {
                setSelectedImages([image])
            }
        }
    }

    const onWhatsAppClick = (phone) => {
        const link = genLink(phone);
        window.open(link, '_blank').focus();
    }

    const onTelegramClick = async (contact) => {
        await createPost({
            variables: {
                data: {
                    message: message,
                    mediaUrl: selectedImages[0],
                    provider: 'telegram',
                    uid: nanoid(),
                    receivers: [{address: contact.value}],
                }
            },
            onCompleted: () => {
                setShowModal(false)
            }
        })
    }

    return (
        <>
            <Button
                size='large'
                type='link'
                onClick={() => {
                    setShowModal(true);
                }}
            >
                Отправить графики
            </Button>
            <CrmModal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                title={'Отправить графики'}
            >
                <div className={'modal-basic-form'}>

                    <Radio.Group value={provider} onChange={(e) => onChangeProvider(e.target.value)}>
                        <Radio.Button value={'telegram'}>Telegram</Radio.Button>
                        <Radio.Button value={'whatsapp'}>WhatsApp</Radio.Button>
                    </Radio.Group>

                    {/*<span className="caption">Рекомендуем выбрать только один график</span>*/}

                    <div className={'graph-preview-list'}>
                        {images.length === 0 && (
                            <Spin/>
                        )}
                        {_.map(images, image => {

                            const isChecked = selectedImages.includes(image);

                            return (
                                <div
                                    key={image}
                                    className={cn([
                                        'graph-preview-item',
                                        isChecked && 'graph-preview-item__selected',
                                    ])}
                                    style={{backgroundImage: `url("${image}")`}}
                                    onClick={() => onToggleImage(image)}
                                >
                                    <div className={'graph-preview-item__checkbox'}>
                                        <Checkbox onChange={() => onToggleImage(image)} checked={isChecked}/>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <Space direction={'vertical'} size={8}>
                        <span>Сообщение</span>
                        <Input.TextArea
                            size="large"
                            autoSize={{minRows: 2, maxRows: 4}}
                            showCount
                            maxLength={maxLength}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </Space>

                    {provider === 'telegram' &&
                        (availableContacts.length > 0
                                ?
                                _.map(availableContacts, (contact, index) => {
                                    return (
                                        <div className={'crm-line'} key={index}>
                                            <div>
                                                <div className={'caption'}>{contact.data.firstName} {contact.data.lastName}</div>
                                                {contact.data.username}
                                            </div>
                                            <Button icon={<TelegramOutlined/>} size="large" onClick={() => onTelegramClick(contact)} disabled={selectedImages.length === 0}>Отправить</Button>
                                        </div>
                                    )
                                })                                :
                                <Alert type={'error'} message={'Нет связанных telegram-аккаунтов'}/>
                        )
                    }

                    {provider === 'whatsapp' &&
                        (availableContacts.length > 0
                            ?
                                _.map(availableContacts, (contact, index) => {
                                    return (
                                        <div className={'crm-line'} key={index}>
                                            <div>
                                                <div className={'caption'}>{contact.data.firstName} {contact.data.lastName}</div>
                                                {contact.value}
                                            </div>
                                            <Button icon={<WhatsAppOutlined/>} size="large" onClick={() => onWhatsAppClick(contact.value)} disabled={selectedImages.length === 0}>Перейти</Button>
                                        </div>
                                    )
                                })
                                :
                                <Button icon={<WhatsAppOutlined/>} size="large" onClick={() => {onWhatsAppClick(null)}} disabled={selectedImages.length === 0}>Перейти в WhatsApp</Button>
                        )
                    }

                </div>
            </CrmModal>
        </>
    )


}