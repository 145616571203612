import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {gql, useQuery} from "@apollo/client";

import {Empty, Pagination, Skeleton} from 'antd';

import {AUTOMATION_NEW} from "../../routes";

import CrmLayout from "../../components/CrmLayout";
import CrmCenteredTitle from "../../components/CrmCenteredTitle";
import AutomationRow from "./AutomationRow";

const QUERY_AUTOMATION = gql`
    query automations ($filters: AutomationFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        automations (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    action
                    cronOffset
                    taskOffset
                    author
                    name
                    comment
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

export default function Automation() {

    const navigate = useNavigate();

    const [page, setPage] = useState(1);

    const query = useQuery(QUERY_AUTOMATION, {
        variables: {
            pagination: {
                pageSize: 20,
                page: page
            },
        }
    });

    let items = null;

    if (query.data !== undefined) {



        if (query.data.automations.data.length > 0) {

            items = query.data.automations.data.map(function (value) {
                return (
                    <AutomationRow key={value.id} id={value.id} data={value}/>
                );
            });
        } else {
            items = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>;
        }
    }

    function goAdd() {
        navigate(AUTOMATION_NEW);
    }

    return (
        <CrmLayout>
            <CrmCenteredTitle
                title={'Настройки задач'}
                addAction={() => {
                    goAdd()
                }}
            />
            {query.loading
                ?
                <Skeleton active/>
                :
                <>
                    {items}

                    {query.data.automations.data.length > 0 ? <Pagination
                        className={'crm-task-pagination'}
                        defaultCurrent={query.data.automations.meta.pagination.page}
                        current={query.data.automations.meta.pagination.page}
                        total={query.data.automations.meta.pagination.total}
                        pageSize={query.data.automations.meta.pagination.pageSize}
                        onChange={() => {setPage(page)}}
                    /> : null}

                </>
            }
        </CrmLayout>
    );
}



