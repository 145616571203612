import React from 'react';
import {Avatar, Space} from 'antd';
import {EnvironmentOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {gym_info} from "../../routes";
import cn from 'classnames';

export default function GymListRow(props) {

    const {name, addressLine, color, enabled} = props;

    // console.log('color', color);

    const navigate = useNavigate();

    function go() {
        navigate(gym_info(props.id));
    }

    return (
        <div className={cn(['client-list-row', !enabled && 'disabled'])} onClick={go}>
            <Space size={10}>
                <Avatar size='large' style={{backgroundColor: color, color: 'black', fontSize: '20px'}}><EnvironmentOutlined /></Avatar>
                <div>
                    {name}
                    <div className='caption'>{addressLine}</div>
                </div>
            </Space>
        </div>
    );
}
