import React, {useState} from 'react';

import {gql, useMutation} from "@apollo/client";
import {Button, Space,} from "antd";
import {PlusOutlined, UnorderedListOutlined} from "@ant-design/icons";
import ModalParentAdd from "./ModalParentAdd";
import ModalParentSelect from "./ModalParentSelect";

const UPDATE_PARENT = gql`
    mutation parent($data: ParentInput!, $id: ID!) {
        updateParent(id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;

const UPDATE_CLIENT_PARENTS = gql`
    mutation parent($id: ID!, $parents: [ID]) {
        updateClientParents(id: $id, parents: $parents) {
            done
        }
    }
`;



export default function ChildParentsSelectAndAdd({childId, onUpdate, parents}) {

    const [updateClientParents] = useMutation(UPDATE_CLIENT_PARENTS);

    // Select Parents
    const [modalSelectVisible, setModalSelectVisible] = useState(false);
    const onSaveList = async function (value) {
        const parents = value.map(function (item) {
            return item.id;
        })
        await updateClientParents ({variables: {id: childId, parents: parents}});
        onUpdate();
        setModalSelectVisible(false);
    };

    // Add Parent
    const [modalAddVisible, setModalAddVisible] = useState(false);
    const onAddParent = async function () {
        onUpdate();
        setModalAddVisible(false);
    };

    return (
        <>
            <Space>
                <Button size={'large'} icon={<UnorderedListOutlined/>} onClick={() => setModalSelectVisible(true)}/>
                <Button size={'large'} icon={<PlusOutlined/>} onClick={() => setModalAddVisible(true)}/>
            </Space>

            <ModalParentSelect isOpen={modalSelectVisible} onClose={() => setModalSelectVisible(false)} selected={parents} onFinish={(value) => onSaveList(value)}/>
            <ModalParentAdd isOpen={modalAddVisible} onClose={() => setModalAddVisible(false)} childId={childId} onFinish={(value) => onAddParent(value)}/>

        </>

    );
}



