import {applySnapshot, types as t,} from 'mobx-state-tree';

export const initialAuthStore = {
    token: null,
    authError: false,
    refresh: null,
    id: null,
    role: null,
    data: null,
};

export const AuthStore = t
    .model({
        token: t.maybeNull(t.string),
        authError: t.boolean,
        refresh: t.maybeNull(t.string),
        id: t.maybeNull(t.string),
        role:  t.maybeNull(t.string),
        data: t.maybeNull(t.model({
            firstName: t.string,
            lastName: t.string,
            color: t.string
        }))
    })
    .views((self => ({
        get isAuthenticated() {
            return !!self.token;
        },
    })))
    .actions((self) => ({
        clearState: () => {
            applySnapshot(self, initialAuthStore);
        },
        setToken: (token) => {
            self.token = token;
        },
        setRefresh: (refresh) => {
            self.refresh = refresh;
        },
        setId: (id) => {
            self.id = id;
        },
        setTokenAndId(token, id) {
            self.token = token;
            self.id = id;
            self.authError = false;
        },
        setTokenAndRefresh(token, refresh, callback,) {
            self.token = token;
            self.refresh = refresh;

            if (callback) {
                callback();
            }
        },
        setRoleAndData (role, data) {
            self.role = role;
            self.data = data;
        },
        setAuthError (boolean) {
            self.authError = boolean;
            self.token = null;
        }

    }));
