import React from 'react';
import map from "lodash/map";

const Polygon = ({params}) => {
  const n = params.length;
  const a = 360 / n;
  const radius = 320;

  const points = [];
  let polygonPointsString = '';

  {map(params, (param, index) => {
    const ai = -90 + a * index;
    const rad_i = (ai * Math.PI) / 180;
    const xi = radius * Math.cos(rad_i);
    const yi = radius * Math.sin(rad_i);

    const percent = 1 - ((100 - param.percent) / 100);
    points.push({
      x: xi * percent,
      y: yi * percent,
    })
  })}

  {map(points, point => {
    polygonPointsString = `${polygonPointsString} ${point.x},${point.y}`;
  })}

  return (
    <polygon
      points={polygonPointsString}
      fill={'#FFFFFF15'}
      strokeWidth={2}
      stroke="#fff"
    />
  );
}

export default Polygon;
