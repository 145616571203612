import React, {useState} from 'react';
import {gql, useMutation} from "@apollo/client";
import CrmModal from "./CrmModal";
import ParentForm from "../pages/Client/Parent/ParentForm";
import {PlusOutlined} from "@ant-design/icons";
import generatePassword from "../helpers/generatePassword";
import {Radio} from "antd";

const CREATE_USER = gql`
    mutation user ($data: UsersPermissionsUserInput!) {
        createUsersPermissionsUser (data: $data) {
            data {
                id
            }
        }
    }
`;

const CREATE_CHILD = gql`
    mutation client ($data: ClientInput!) {
        createClient (data: $data) {
            data {
                id
            }
        }
    }
`;

const CREATE_PARENT = gql`
    mutation parent ($data: ParentInput!) {
        createParent (data: $data) {
            data {
                id
            }
        }
    }
`;


export default function ModalClientAdd({isOpen, onClose, onFinish}) {

    const [formType, setFormType] = useState('child');

    const [createUser] = useMutation(CREATE_USER);
    const [createChild] = useMutation(CREATE_CHILD);
    const [createParent] = useMutation(CREATE_PARENT);

    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState(generatePassword());

    const onSubmit = async (data) => {
        setLoading(true);

        // 6 - ребенок
        // 7 - взрослый

        let child = true;

        if (formType === 'adult') {child = false;}

        const userData = {
            firstName: data.firstName,
            lastName: data.lastName,
            username: data.email,
            email: data.email,
            password: password,
            contacts: data.contacts,
            blocked: false,
            role: child ? 6 : 7,
        }

        const res = await createUser({variables: {data: userData}});
        const userId = res.data.createUsersPermissionsUser.data.id;


        const clientData = {
            firstName: data.firstName,
            lastName: data.lastName,
            birthDate: data.birthDate,
            account: userId,
            enabled: true,
        }

        if (child) {
            const res2 = await createChild({variables: {data: clientData}});
            const clientId = res2.data.createClient.data.id;
        } else {
            const res2 = await createParent({variables: {data: clientData}});
            const clientId = res2.data.createParent.data.id;
        }

        onFinish(userId);
        setLoading(false);

    };

    return (
        <CrmModal isOpen={isOpen} onClose={onClose} title={'Добавить клиента'}>
            <div className={'modal-basic-form'}>

                <Radio.Group defaultValue={formType} buttonStyle="solid" onChange={(e) => setFormType(e.target.value)}>
                    <Radio.Button value="child">Ребенок</Radio.Button>
                    <Radio.Button value="adult">Взрослый</Radio.Button>
                </Radio.Group>

                <ParentForm onSubmit={onSubmit} loading={loading} withEmail actionName={'Добавить'}
                            actionIcon={<PlusOutlined/>}/>

            </div>
        </CrmModal>
    )

}