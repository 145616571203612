import {React} from 'react';
import _ from 'lodash';
import './visits.scss';
import moment from 'moment';
import {CheckOutlined, MinusOutlined} from "@ant-design/icons";

export default function ClientVisits({visits}) {

    return (
        <>

            <div className={'visits-line'}>

                <div className={'line-3-8col ellipsis'}>
                    <span className={'caption color-gray-3'}>Дата</span>
                </div>

                <div className={'line-3-8col'}>
                    <span className={'caption color-gray-3'}>Тренировка</span>
                </div>

                <div className={'line-2-8col'}>
                    <span className={'caption color-gray-3'}>Посещения</span>
                </div>

            </div>

            {_.map(visits, (visit) => {

                const training = visit.attributes.training.data;

                return (
                    <div className={'visits-line'} key={visit.id}>
                        <div className={'line-3-8col ellipsis'}>
                            <span className={'body'}>{moment(training.attributes.startDate).format('DD MMM YYYY')}</span>
                        </div>

                        <div className={'line-3-8col'}>
                            <span className={'body'}>{training.attributes.name || `№ ${training.id}`}</span>
                        </div>

                        <div className={'line-2-8col'}>
                            <span className={'body'}>{visit.attributes.visited ? <CheckOutlined/> : <MinusOutlined className={'deleted'}/>}</span>
                        </div>
                    </div>

                )
            })}
            </>
    );
}



