import React from 'react';
import WC from "./circle_20х20_6.png";

const EightCircle234D = ({percent}) => {
  const r = 177;
  let strokeDashoffset = ((100 - percent) / 100) * Math.PI * r * 2;
  const rotate = (percent / 100) * 360;
  const strokeDasharray = Math.PI * r * 2;
  if (percent >= 100) {
    strokeDashoffset = 0;
  }

  return (
    <>
      <circle
        cx="560"
        cy="1130"
        r={r}
        stroke="#00FF7B"
        strokeOpacity="0.3"
        strokeWidth="20"
      />
      <circle
        cx="560"
        cy="1130"
        r={r}
        stroke="#00FF7B"
        strokeOpacity="1"
        strokeWidth="20"
        strokeDashoffset={strokeDashoffset}
        strokeDasharray={strokeDasharray}
        strokeLinecap="round"
        transform="rotate(-90 560 1130)"
      />

      <image
        width="50"
        height="50"
        href={WC}
        x="535"
        y="928"
        transform={`rotate(${rotate} 560 1130)`}
      />
    </>
  );
}

export default EightCircle234D;
