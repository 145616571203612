import {useState} from 'react';
import {Button, message} from 'antd';
import {CheckCircleOutlined, IdcardOutlined} from '@ant-design/icons';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import {useQuery} from "react-query";
import CrmWindowSpin from "../../components/CrmWindowSpin";
import {getRestApiBase} from "../../env";
import PublicLayout from "../Public/PublicLayout";

import cls from '../Public/public.module.scss';

function usePost(publicKey) {
    return useQuery("getSubscriptionData", async () => {
        const {data} = await axios.get(
            `${getRestApiBase()}/payment-order?publicKey=${encodeURIComponent(publicKey)}`
        );
        return data;
    });
}

export default function PaymentOffer() {

    const {public_key} = useParams();

    const {data, error, isFetching} = usePost(public_key);

    let DisplayNumber = '';
    let DisplayClient = '';
    let DisplayGym = '';
    let DisplayRange = '';
    let DisplayPrice = '';

    if (data !== undefined) {
        DisplayNumber = data.id;
        DisplayClient = `${data.client.firstName} ${data.client.lastName}`;
        DisplayGym = `${data.gym.name} ${data.gym.address}`;
        DisplayRange = `${moment(data.startDate).format('DD MMM YYYY')} — ${moment(data.endDate).format('DD MMM YYYY')}`;
        DisplayPrice = data.price;
    }

    const [loadingLink, setLoadingLink] = useState(false);

    const onSubmit = async () => {
        setLoadingLink(true);
        const link = await axios.post(`${getRestApiBase()}/payment-link?subscriptionId=${encodeURIComponent(data.id)}`);
        const url = String(link.data);
        window.location.assign(url);
        setLoadingLink(false);
    };

    if (error) {
        message.error(error.message);
    }

    return (

        <PublicLayout>

            {isFetching && <CrmWindowSpin spinning/>}

            {!isFetching && !error &&
                <>
                    <div className={cls.title}>
                        <div className={cls.icon}>
                            <IdcardOutlined/>
                        </div>
                        <div className={cls.text}>
                            <div>
                                Абонемент
                            </div>
                            <div>
                                №{DisplayNumber}
                            </div>
                        </div>
                    </div>

                    <div className={cls.content}>

                        <div>{DisplayClient}</div>
                        <div>{DisplayGym}</div>
                        <div>{DisplayRange}</div>
                        <div className={'bold'}>{DisplayPrice} ₽</div>

                        <hr/>

                        {data.paid ?
                            <span className={'color-success'}><CheckCircleOutlined/> Оплачено</span>
                            :
                            <Button
                                type={'primary'}
                                size={'large'}
                                loading={loadingLink}
                                onClick={onSubmit}
                            >Оплатить</Button>
                        }
                    </div>
                </>
            }


        </PublicLayout>
    );
}



