import React from 'react';
import BgSvg from "./includes/BgSvg";
import map from 'lodash/map';
import Polygon from "./includes/Polygon";
import textEllipsis from './includes/TextEllipsis';


const colors = [
    '#FFFFFF',
    '#B2F900',
    '#E100FF',
    '#00EAFF',
    '#FFAE00',
    '#00FF7B',
    '#6D33FF',
    '#FF2D1A',
    '#33FF33',
    '#0051FF',
    '#FF1A90',
    '#3e77fd',
    '#baf341',
    '#6ff5ab',
    '#5fa4ee',
];

const PolygonChart = (
    {
        firstName,
        lastName,
        params1,
        params2,
        params3,
        ...rest
    }
) => {
    const center = {
        x: 0,
        y: 0
    }
    const n = params1.length;
    const a = 360 / n;
    const radius = 320;

    return (
        <svg width="1080" height="1920" viewBox="0 0 1080 1920" fill="none"
             xmlns="http://www.w3.org/2000/svg" {...rest}>
            <BgSvg/>
            <text fontSize="60" fontWeight="bold" fill="#fff" fontFamily="'Open Sans', sans-serif">
                <tspan x="50%" textAnchor="middle" y="420">{firstName}</tspan>
                {lastName && (
                    <tspan x="50%" textAnchor="middle" y="500">{lastName}</tspan>
                )}
            </text>

            <g transform="translate(530 1000)">
                {map(params1, (param, index) => {
                    const ai = -90 + a * index;

                    return (
                        <React.Fragment key={index}>
                            <rect
                                width={radius}
                                height={1}
                                fill="#828282"
                                x={center.x}
                                y={center.y}
                                transform={`rotate(${ai}) translate(-0.5 -0.5)`}
                            />
                        </React.Fragment>
                    )
                })}
                <Polygon
                    params={params1}
                />
                {!!params2 && params2.length > 0 && (
                    <Polygon
                        params={params2}
                    />
                )}
                {!!params3 && params3.length > 0 && (
                    <Polygon
                        params={params3}
                    />
                )}
                {map(params1, (param, index) => {
                    const ai = -90 + a * index;
                    const rad_i = (ai * Math.PI) / 180;
                    const xi = radius * Math.cos(rad_i);
                    const yi = radius * Math.sin(rad_i);

                    return (
                        <React.Fragment key={index}>
                            <circle
                                data-a={ai}
                                data-index={index}
                                fill={colors[index]}
                                cx={center.x + xi}
                                cy={center.y + yi}
                                r={20}
                            />
                        </React.Fragment>
                    )
                })}
            </g>
            <g>
                {map(params1, (param, index) => {

                    const delta = 60;

                    let color = colors[index];

                    const title = textEllipsis(param.title, 'normal 35px Open Sans', 600);

                    const y = 1880 - (params1.length - index - 1) * delta;

                    return (
                        <React.Fragment key={index}>
                            <text fontSize="35" fontWeight="normal" fill={color} fontFamily="'Open Sans', sans-serif">
                                <tspan x="80" y={y}>{title}</tspan>
                            </text>

                            <text fontSize="35" fontWeight="normal" fill={color} textAnchor="end"
                                  fontFamily="'Open Sans', sans-serif">
                                <tspan x="780" y={y}>{param.value}</tspan>
                            </text>

                            <text fontSize="35" fontWeight="normal" fill={color} textAnchor="end"
                                  fontFamily="'Open Sans', sans-serif">
                                <tspan x="1000" y={y}>Цель: {param.targetValue}</tspan>
                            </text>
                        </React.Fragment>
                    )
                })}
            </g>

        </svg>
    )
}

export default PolygonChart;
