import {toFactory} from 'react-ioc';
import {Store} from '../store/Store';
import {ApolloClient} from "@apollo/client";
import ApolloClientFactory from "./ApolloClientFactory";

const ApolloClientDI = () => [
  ApolloClient,
  toFactory(
    [Store],
    (store) => ApolloClientFactory(store),
  ),
];

export default ApolloClientDI;
