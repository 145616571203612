import {Checkbox, message} from 'antd';
import {Link} from "react-router-dom";
import {client_info} from "../../../routes";
import {gql, useMutation, useQuery} from "@apollo/client";
import moment from "moment";
import SubscriptionStatus from "../../../components/SubscriptionStatus";
import cn from 'classnames';
import CrmAvatar from "../../../components/CrmAvatar";

const QUERY_SUBSCRIPTION = gql`
    query subscriptions ($filters: SubscriptionFiltersInput, $pagination: PaginationArg, $sort: [String] = []) {
        subscriptions (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    startDate
                    endDate
                    unlimited
                    limit
                    visitCount
                    paid
                    gym {
                        data {
                            id
                        }
                    }
                }
            }
        }
    }
`;

const UPDATE_SUBSCRIPTION = gql`
    mutation updateSubscription($data: SubscriptionInput!, $id: ID!) {
        updateSubscription(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

const UPDATE_VISIT = gql`
    mutation updateVisit($data: VisitInput!, $id: ID!) {
        updateVisit(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;


export default function VisitListRow({id, userId, firstName, lastName, color, child, enabled, visited, startDate, gymId, onUpdate}) {

    let filter = {
            user: {id: {eq: userId}},
            startDate: {lte: moment(startDate).format('YYYY-MM-DD')},
            endDate: {gte: moment(startDate).format('YYYY-MM-DD')},
        }

    if (gymId) {
        filter = {
            ...filter,
            gym: {id: {eq: gymId}},
        }
    }

    const query = useQuery(QUERY_SUBSCRIPTION, {
        variables: {
            filters: filter,
            sort: 'endDate:DESC',
            pagination: {
                limit: 1
            }

        },
        onCompleted: (data) => {
            console.log('data', data);
        },
        onError: (error) => {
            message.error(error.message)
            console.error(error)
        },
    });

    const [updateSubscriptionMutation] = useMutation(UPDATE_SUBSCRIPTION);
    const [updateVisitMutation] = useMutation(UPDATE_VISIT);

    let displayStatus = null;

    let isSubscription = false;
    let subscriptionId = null;

    if (query.data !== undefined) {
        if (query.data.subscriptions.data.length > 0) {

            isSubscription = true;
            subscriptionId = query.data.subscriptions.data[0].id;

            const unlimited = query.data.subscriptions.data[0].attributes.unlimited;
            const limit = query.data.subscriptions.data[0].attributes.limit;
            const visitCount = query.data.subscriptions.data[0].attributes.visitCount !== undefined ? query.data.subscriptions.data[0].attributes.visitCount : 0;

            displayStatus =
                <div className={'crm-subscription-status'}>

                    <SubscriptionStatus
                        type={'date'}
                        size={'small'}
                        startDate={query.data.subscriptions.data[0].attributes.startDate}
                        endDate={query.data.subscriptions.data[0].attributes.endDate}
                    />

                    <SubscriptionStatus
                        type={'limit'}
                        size={'small'}
                        unlimited={unlimited}
                        limit={limit}
                        visitCount={visitCount}
                    />

                    <SubscriptionStatus
                        type={'payment'}
                        size={'small'}
                        paid={query.data.subscriptions.data[0].attributes.paid}
                    />

                </div>;

        } else {
            displayStatus =
                <>
                    <span className={'color-danger'}>Нет абонемента</span>
                    {/*<Button>Создать</Button>*/}
                </>
        }

        if (!enabled) {
            displayStatus = <span>Пользователь удален</span>
        }

    }

    const changeStatus = async (e) => {

        // обновляем абонемент
        if (isSubscription) {

            let willVisitCount = query.data.subscriptions.data[0].attributes.visitCount;
            // const limit = query.data.subscriptions.data[0].attributes.limit;

            if (visited) {
                willVisitCount -= 1;
            } else {
                willVisitCount += 1;

            }

            await updateSubscriptionMutation({
                variables: {
                    id: subscriptionId,
                    data: {
                        visitCount: willVisitCount
                    }
                }
            });

            message.success('Добавилось посещения в абонементе № ' + subscriptionId);
            //
            query.refetch();
            // }


        }

        // обновляем визит
        await updateVisitMutation({
            variables: {
                id: id,
                data: {visited: e.target.checked},
            },
            onCompleted: (data) => {
                if (data) {
                    query.refetch()
                }
            },
            onError: (error) => {
                message.error(error.message)
                console.error(error)
            },
        });

        // посылаем хук на апдейт
        onUpdate();

    };


    return (
        <div className={cn(['visit-list-row', !enabled && 'deleted'])}>

            <div className={'visit-list-row-info'}>
                <Link to={client_info(userId)}>
                    <CrmAvatar
                        firstName={firstName}
                        lastName={lastName}
                        color={color}
                        child={child}
                        caption={displayStatus}
                    />
                </Link>
            </div>

            <div className={'visit-list-row-check'}>
                 <Checkbox size={'large'} className='visit-list-row-checkbox'
                                  checked={visited}
                                  disabled={!enabled || visited}
                                  onChange={changeStatus}
                        />
            </div>
        </div>
    );
}
