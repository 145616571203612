import React from 'react';
import BgSvg from "./BgSvg";
import map from 'lodash/map';
import EightCircle234D from "./EightCircle234D";
import EightCircle294D from "./EightCircle294D";
import EightCircle354D from "./EightCircle354D";
import EightCircle414D from "./EightCircle414D";
import EightCircle474D from "./EightCircle474D";
import EightCircle534D from "./EightCircle534D";
import EightCircle594D from "./EightCircle594D";
import EightCircle654D from "./EightCircle654D";
import textEllipsis from './TextEllipsis';

const colors = [
    '#FFFFFF',
    '#B2F900',
    '#E100FF',
    '#00EAFF',
    '#FFAE00',
    '#00FF7B',
    '#6D33FF',
    '#FF2D1A',
    '#33FF33',
    '#0051FF',
    '#FF1A90',
    '#3e77fd',
    '#baf341',
    '#6ff5ab',
    '#5fa4ee',
];

const EightCirclesChart = (
    {
        params,
        firstName,
        lastName,
        ...rest
    }
) => {
    return (
        <svg width="1080" height="1920" viewBox="0 0 1080 1920" fill="none"
             xmlns="http://www.w3.org/2000/svg" {...rest}>
            <BgSvg/>
            <text fontSize="60" fontWeight="bold" fill="#fff" fontFamily="'Open Sans', sans-serif">
                <tspan x="50%" textAnchor="middle" y="420">{firstName}</tspan>
                {lastName && (
                    <tspan x="50%" textAnchor="middle" y="500">{lastName}</tspan>
                )}
            </text>

            <g transform="translate(-21 -134)">
                {map(params, (param, index) => {
                    if (index === 0) {
                        return <EightCircle654D percent={param.percent} key={index}/>
                    }

                    if (index === 1) {
                        return <EightCircle594D percent={param.percent} key={index}/>
                    }

                    if (index === 2) {
                        return <EightCircle534D percent={param.percent} key={index}/>
                    }

                    if (index === 3) {
                        return <EightCircle474D percent={param.percent} key={index}/>
                    }

                    if (index === 4) {
                        return <EightCircle414D percent={param.percent} key={index}/>
                    }

                    if (index === 5) {
                        return <EightCircle354D percent={param.percent} key={index}/>
                    }

                    if (index === 6) {
                        return <EightCircle294D percent={param.percent} key={index}/>
                    }

                    if (index === 7) {
                        return <EightCircle234D percent={param.percent} key={index}/>
                    }

                    return null;
                })}
            </g>

            <g>
                {map(params, (param, index) => {
                    const delta = 50;

                    let color = colors[index];

                    const title = textEllipsis(param.title, 'normal 35px Open Sans', 600);

                    const y = 1880 - (params.length - index - 1) * delta;

                    return (
                        <React.Fragment key={index}>
                            <text fontSize="35" fontWeight="normal" fill={color} fontFamily="'Open Sans', sans-serif">
                                <tspan x="80" y={y}>{title}</tspan>
                            </text>

                            <text fontSize="35" fontWeight="normal" fill={color} textAnchor="end"
                                  fontFamily="'Open Sans', sans-serif">
                                <tspan x="780" y={y}>{param.value}</tspan>
                            </text>

                            <text fontSize="35" fontWeight="normal" fill={color} textAnchor="end"
                                  fontFamily="'Open Sans', sans-serif">
                                <tspan x="1000" y={y}>Цель: {param.targetValue}</tspan>
                            </text>
                        </React.Fragment>
                    )
                })}
            </g>

        </svg>
    );
}

export default EightCirclesChart;
