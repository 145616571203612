import React, {useState} from 'react';
import {Skeleton,} from 'antd';

import {useNavigate, useParams} from 'react-router-dom';
import CrmLayout from "../../components/CrmLayout";
import {gql, useMutation, useQuery} from "@apollo/client";
import CrmCenteredTitle from "../../components/CrmCenteredTitle";
import {subscription_info} from "../../routes";
import SubscriptionForm from "./SubscriptionForm";

import dayjs from 'dayjs';

const QUERY = gql`
    query subscription ($id: ID) {
        subscription(id: $id) {
            data {
                id
                attributes {
                    startDate
                    endDate
                    unlimited
                    limit
                    visitCount
                    price
                    paid
                    user {
                        data {
                            id
                        }
                    }
                    gym {
                        data {
                            id
                        }
                    }
                }
            }
        }
    }
`;

const UPDATE = gql`
    mutation updateSubscription($data: SubscriptionInput!, $id: ID!) {
        updateSubscription(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;


export default function SubscriptionEdit() {

    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const query = useQuery(QUERY, {
        variables: {id: id,},
        onCompleted: ((data) => {
            setData({
                range: [dayjs(data.subscription.data.attributes.startDate), dayjs(data.subscription.data.attributes.endDate)],
                unlimited: data.subscription.data.attributes.unlimited,
                limit: data.subscription.data.attributes.limit,
                visitCount: data.subscription.data.attributes.visitCount,
                price: data.subscription.data.attributes.price,
                paid: data.subscription.data.attributes.paid,
                gym: data.subscription.data.attributes.gym.data && data.subscription.data.attributes.gym.data.id,
                user: data.subscription.data.attributes.user.data && data.subscription.data.attributes.user.data.id,
            });
        })
    });

    const [update] = useMutation(UPDATE);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);
        const newData = {
            startDate: dayjs(data.range[0]).format('YYYY-MM-DD'),
            endDate: dayjs(data.range[1]).format('YYYY-MM-DD'),
            unlimited: data.unlimited,
            limit: data.limit,
            visitCount: data.visitCount,
            price: data.price,
            paid: data.paid,

        };
        await update({variables: {id: id, data: newData}});
        navigate(subscription_info(id));
    };

    return (
        <CrmLayout>

            <CrmCenteredTitle
                title={`Изменить абонемент №${id}`}
            />

            {query.loading && <Skeleton active/>}

            {data &&
            <>
                <SubscriptionForm onSubmit={onSubmit} loading={loading} data={data} actionName={'Сохранить'}/>
            </>
            }

        </CrmLayout>

    );
}