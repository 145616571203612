import React, {useState} from 'react';
import {gql, useMutation} from "@apollo/client";
import CrmModal from "../../../components/CrmModal";
import ParentForm from "../Parent/ParentForm";
import {PlusOutlined} from "@ant-design/icons";
import generatePassword from "../../../helpers/generatePassword";

const CREATE_USER = gql`
    mutation users ($data: UsersPermissionsUserInput!) {
        createUsersPermissionsUser (data: $data) {
            data {
                id
            }
        }
    }
`;

const CREATE_PARENT = gql`
    mutation users ($data: ParentInput!) {
        createParent (data: $data) {
            data {
                id
            }
        }
    }
`;

export default function ModalParentAdd({isOpen, onClose, onFinish, childId}) {

    const [createUser] = useMutation(CREATE_USER);
    const [createParent] = useMutation(CREATE_PARENT);

    const [loading, setLoading] = useState(false);
    const password = generatePassword();

    const onSubmit = async (data) => {
        setLoading(true);

        const userData = {
            firstName: data.firstName,
            lastName: data.lastName,
            username: data.email,
            email: data.email,
            password: password,
            contacts: data.contacts,
            blocked: false,
            role: 7,
        }

        const res = await createUser({variables: {data: userData}});
        const userId = res.data.createUsersPermissionsUser.data.id;

        const parentData = {
            firstName: data.firstName,
            lastName: data.lastName,
            birthDate: data.birthDate,
            account: userId,
            clients: [childId],
        }
        const res2 = await createParent({variables: {data: parentData}});
        const parentId = res2.data.createParent.data.id;

        onFinish(parentId);

    }

    return (
        <CrmModal isOpen={isOpen} onClose={onClose} title={'Добавить родителя'}>
            <div className={'modal-basic-form'}>

                <ParentForm onSubmit={onSubmit} loading={loading} withEmail actionName={'Добавить'} actionIcon={<PlusOutlined/>}/>

            </div>
        </CrmModal>
    )

}