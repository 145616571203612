import React from 'react';
import {Button, DatePicker, Form, Input,} from 'antd';
import SelectClient from "../../components/Form/SelectClient";
import SelectTrainer from "../../components/Form/SelectTrainer";

export default function TaskForm ({data, onSubmit, loading, actionName, actionIcon}) {

    const [form] = Form.useForm();

    const onFinish = (values) => {
        onSubmit(values);
    };

    return (

        <Form name="task"
              form={form}
              onFinish={onFinish}
              initialValues={data}
              layout={'vertical'}
              size={'large'}
              disabled={loading}
              scrollToFirstError
        >

            <Form.Item name={'executor'} label={'Исполнитель'} required
                       rules={[{required: true, message: 'Заполните поле',},]}>
                <SelectTrainer/>
            </Form.Item>

            <Form.Item name={'user'} label={'Клиент'}>
                <SelectClient/>
            </Form.Item>

            <Form.Item name={'name'} label={'Название задачи'} required
                       rules={[{required: true, message: 'Заполните поле',},]}>
                <Input/>
            </Form.Item>

            <Form.Item name={'comment'} label={'Описание'}>
                <Input.TextArea autoSize={{minRows: 1, maxRows: 6,}}/>
            </Form.Item>

            <Form.Item name={'date'} label={'Дата исполнения'} required
                       rules={[{required: true, message: 'Заполните поле',},]}>
                <DatePicker format={'DD MMM YYYY'}/>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" icon={actionIcon} loading={loading}>
                    {actionName}
                </Button>
            </Form.Item>

        </Form>
    );

}