import React, {useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import dayjs from 'dayjs';

import CrmLayout from "../../components/CrmLayout";
import {DatePicker, Select, Tabs} from "antd";

import CrmCenteredTitle from "../../components/CrmCenteredTitle";
import ReportGyms from "./ReportGyms";
import ReportUsers from "./ReportUsers";
import {get_report} from "../../routes";

const {TabPane} = Tabs;

export default function Report() {

    const {type, id, startDate, endDate} = useParams();

    const {RangePicker} = DatePicker;
    const {Option} = Select;
    const navigate = useNavigate();

    const dateFilter = {
        0: {
            name: 'Этот месяц',
            range: [dayjs().startOf('month'), dayjs().endOf('month')],
        },
        1: {
            name: 'Прошлый месяц',
            range: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
        },
        2: {
            name: 'Этот год',
            range: [dayjs().startOf('year'), dayjs().endOf('year')],
        },
        3: {
            name: 'Прошлый год',
            range: [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')],
        },
        4: {
            name: 'Дата',
            range: [],
        },
    };

    const [selectedDate, setSelectedDate] = useState(4);
    const [range, setRange] = useState([dayjs(startDate), dayjs(endDate)]);

    async function onDateChange(value) {
        await setRange(dateFilter[value].range);
        setSelectedDate(value);
        navigate(get_report(type, id, dateFilter[value].range[0].format('YYYY-MM-DD'), dateFilter[value].range[1].format('YYYY-MM-DD')), {replace: true});
    }

    function onRangeChange(value) {
        setSelectedDate(4);
        setRange(value);
        navigate(get_report(type, id, value[0].format('YYYY-MM-DD'), value[1].format('YYYY-MM-DD')), {replace: true});
    }

    return (

        <CrmLayout>

            <CrmCenteredTitle
                title={'Отчеты'}
            />

            <Tabs defaultActiveKey="1" activeKey={type}>
                <TabPane key="gyms" tab={<Link to={get_report('gyms', 0, startDate, endDate)} replace>Залы</Link>}/>
                <TabPane key="users" tab={<Link to={get_report('users', 0, startDate, endDate)} replace>Тренеры</Link>}/>
            </Tabs>

            <div className={'crm-report-filter'}>
                <Select defaultValue={0} value={selectedDate} bordered={false} onChange={onDateChange} dropdownMatchSelectWidth={false}>
                    <Option value={0}>{dateFilter[0].name}</Option>
                    <Option value={1}>{dateFilter[1].name}</Option>
                    <Option value={2}>{dateFilter[2].name}</Option>
                    <Option value={3}>{dateFilter[3].name}</Option>
                    <Option value={4} disabled>{dateFilter[4].name}</Option>
                </Select>
                <RangePicker
                    onChange={onRangeChange}
                    className={'crm-report-date-range'}
                    format={'DD MMM YYYY'}
                    value={range}
                    allowClear={false}
                />
            </div>

            {type === 'gyms' && <ReportGyms range={range} id={id}/>}
            {type === 'users' && <ReportUsers range={range} id={id}/>}

        </CrmLayout>
    );
}



