import React, {useState} from 'react';
import {Empty, Pagination, Space} from 'antd';
import {gql, useQuery} from "@apollo/client";
import _ from 'lodash';
import ClientTask from "./ClientTask";

const QUERY = gql`
    query query (
        $filters: TaskFiltersInput,
        $pagination: PaginationArg = {},
        $sort: [String] = []
    ) {
        tasks (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    name
                    comment
                    done
                    date
                    executor {
                        data {
                            id
                            attributes {
                                trainer {
                                    data {
                                        id
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                            avatar {
                                                data {
                                                    attributes {
                                                        url
                                                        previewUrl
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

export default function ClientTasks({id}) {

    const [page, setPage] = useState(1);

    const query = useQuery(QUERY, {
        variables: {
            id: id,
            filters: {
                user: {
                    id: {
                        eq: id
                    }
                }
            },
            pagination: {
                pageSize: 20,
                page: page
            },
            sort: ['createdAt:DESC'],
        },
        fetchPolicy: 'cache-and-network',
    });

    return (
        <>
            {query.data &&
            <Space direction={'vertical'} size={20} className={'block'}>

                {query.data.tasks.data.length === 0 &&
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                }

                {_.map(query.data.tasks.data, (item) => {
                    return (<ClientTask
                        key={item.id}
                        id={item.id}
                        task={item.attributes}
                    />)
                })}
                {query.data.tasks.data.length > 0 ? <Pagination
                    size={'small'}
                    className={'crm-task-pagination'}
                    defaultCurrent={query.data.tasks.meta.pagination.page}
                    current={query.data.tasks.meta.pagination.page}
                    total={query.data.tasks.meta.pagination.total}
                    pageSize={query.data.tasks.meta.pagination.pageSize}
                    onChange={(page) => {
                        setPage(page)
                    }}
                /> : null}
            </Space>
            }
        </>
    );
}