import React from 'react';
import logo from '../../images/sila-skilla-white-logo.svg'
import cls from './public.module.scss'

export default function PublicHeader() {

    return (
        <header className={cls.header}>
            <div className={cls.logo}>
                <img src={logo} alt="logo"/>
            </div>
        </header>
    );
}
