import React from 'react';
import {Space} from 'antd';


export default function CrmCard(props) {

    const {onClick} = props;

    return (
        <div className="crm-card" onClick={onClick}>
            <Space direction="vertical" className="crm-card-content">
                {props.children}
            </Space>

            {props.action && (
                <div className="crm-card-action">
                    {props.action}
                </div>)
            }

        </div>
    );
}
