import React, {useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';

import CrmLayout from "../../../components/CrmLayout";

import {Button, Divider, Empty, message, Modal, Skeleton, Space, Tabs} from 'antd';
import {EnvironmentOutlined, ExclamationCircleOutlined, GiftOutlined, PlusOutlined,} from '@ant-design/icons';

import {child_edit, CLIENTS, NOT_FOUND, parent_info, postingClients, subscription_new, task_new} from "../../../routes";
import ClientIndicator from "../ClientIndicator";
import ClientSkill from "../ClientSkill";
import ClientModalAddIndicator from "../ClientModalAddIndicator";

import moment from 'moment';
import {gql, useMutation, useQuery} from "@apollo/client";
import ClientModalAddSkill from "../ClientModalAddSkill";
import ClientMessage from "../ClientMessage";
import ClientAddComment from "../ClientAddComment";
import CrmCenteredTitle from "../../../components/CrmCenteredTitle";
import _ from 'lodash';
import CrmContact from "../../../components/Contact/CrmContact";
import CrmAvatar from "../../../components/CrmAvatar";
import CrmCard from "../../../components/CrmCard";
import roleLocation from "../../../helpers/roleLocation";
import ChildParentsSelectAndAdd from "./ChildParentsSelectAndAdd";
import ChildEditAnket from "./ChildEditAnket";
import ClientSubscriptions from "../ClientSubscriptions";
import ClientTasks from "../ClientTasks";
import IconAndText from "../../../vidgets/IconAndText";
import BonusLayout from "../../../vidgets/bonus/BonusLayout";
import Bonus from "../Bonus/Bonus";
import BonusLogo from "../../../vidgets/bonus/BonusLogo";
import PublicUrl from "../../../vidgets/client/PublicUrl";
import TelegramOutlined from "../../../vidgets/icon/TelegramOutlined";
import Graph from "../Graph/Graph";

const GET_CLIENT_INFO = gql`
    query client (
        $id: ID,
        $parfilters: ParentFiltersInput,
        $subfilter: SubscriptionFiltersInput,
        $mesfilter: MessageFiltersInput,
    ) {
        client(id: $id) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    birthDate
                    avatarUrl
                    enabled
                    type
                    color
                    bonus
                    avatar {
                        data {
                            attributes {
                                previewUrl
                                url
                            }
                        }
                    }
                    account {
                        data {
                            id
                            attributes {
                                publicKey
                                contacts {
                                    type
                                    value
                                    data
                                }
                            }
                        }
                    }
                    anket {
                        contraindications
                        experience
                        fullFamily
                        gadgetTime
                        mainGoal
                    }
                    enabled
                    indicator (pagination: {limit: 200}) {
                        id
                        name
                        startValue
                        goal
                        result (pagination: {limit: 200}) {
                            id
                            date
                            value
                        }
                    }
                    skill {
                        id
                        name
                        own
                    }
                    gym {
                        data {
                            id
                            attributes {
                                name
                                address
                                city {
                                    data {
                                        id
                                        attributes {
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                    messages (filters: $mesfilter) {
                        data {
                            id
                            attributes {
                                author {
                                    data {
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                        }
                                    }
                                }
                                message
                                fileUrl
                                date
                            }
                        }
                    }
                }
            }
        }
        parents(filters: $parfilters) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    enabled
                    role
                    birthDate
                    color
                    avatar {
                        data {
                            id
                            attributes {
                                previewUrl
                                url
                            }
                        }
                    }
                    account {
                        data {
                            attributes {
                                contacts {
                                    type
                                    value
                                    data
                                }
                            }
                        }
                    }
                }
            }
        }
        subscriptions (filters: $subfilter) {
            data {
                id
                attributes {
                    startDate
                    endDate
                    unlimited
                    limit
                    visitCount
                    paid
                    gym {
                        data {
                            id
                            attributes {
                                name
                                address
                            }
                        }
                    }
                }
            }
        }
    }
`;

const DELETE_CLIENT = gql`
    mutation removeClient($id: ID!) {
        removeClient(id: $id) {
            done
        }
    }
`;

const {TabPane} = Tabs;

export default function ChildInfo() {

    const {id} = useParams();

    const navigate = useNavigate();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [addSkillModalOpen, setAddSkillModalOpen] = useState(false);

    const query = useQuery(GET_CLIENT_INFO, {
        variables: {
            id: id,
            parfilters: {
                clients: {
                    id: {
                        eq: id
                    }
                }
            },
            subfilter: {
                and: [{
                    user: {
                        id: {
                            eq: id
                        }
                    }
                }, {
                    endDate: {
                        gte: moment().subtract(1, 'month').format('YYYY-MM-DD')
                    }
                }]

            },
            mesfilter: {
                or: [{
                    enabled: {eq: true}
                }, {
                    enabled: {null: true}
                }]
            },
        },
        fetchPolicy: 'cache-and-network',
    });

    const [deleteMutation] = useMutation(DELETE_CLIENT);
    const onDelete = function () {
        Modal.confirm({
            title: 'Удалить',
            icon: <ExclamationCircleOutlined/>,
            content: `Действительно удалить клиента ${query.data.client.data.attributes.firstName} ${query.data.client.data.attributes.lastName}?`,
            okText: 'Да',
            cancelText: 'Нет',
            onOk() {
                onConfirmedDelete()
            }

        });
    };

    async function onConfirmedDelete() {
        const res = await deleteMutation({
            variables: {
                id: id,
            },
            onCompleted: (data) => {
                if (data) {
                    navigate(CLIENTS);
                }
            },
            onError: (error) => {
                message.error(error.message)
                console.error(error)
            }
        });

    }

    let contacts = null;

    let indicators = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>;
    let skills = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>;
    let graph = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>;
    let messages = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>;
    let anket = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>;

    if (query.data) {

        // если нет данных или enabled=false, то отправляем в 404
        if (!query.data.client.data || query.data.client.data.attributes.enabled === false) {
            navigate(NOT_FOUND, {replace: true});
            return null;
        }

        const client = query.data.client.data;
        const account =  client.attributes.account.data;


        // Контакты
        if (account.attributes.contacts.length > 0) {
            contacts = (
                <>
                    {/*<Divider>Контакты</Divider>*/}
                    <Space direction={'vertical'} size={16}>
                        {_.map(query.data.client.data.attributes.account.data.attributes.contacts, (value, index) => {
                            return (
                                <CrmContact key={index} type={value.type} value={value.value} data={value.data}/>
                            )
                        })}
                    </Space>
                </>
            )
        }

        // Анкета
        if (client.attributes.anket) {
            anket = (
                <div>
                    <Divider>Опыт занятий</Divider>
                    <div className={'crm-message-text'}>{query.data.client.data.attributes.anket.experience}</div>

                    <Divider>Противопоказания</Divider>
                    <div className={'crm-message-text'}>{query.data.client.data.attributes.anket.contraindications}</div>

                    <Divider>Состав семьи</Divider>
                    <div className={'crm-message-text'}>
                        {query.data.client.data.attributes.anket.fullFamily === true ? '👫 Полная' : '🧍 Не полная'}
                    </div>

                    <Divider>Время проведенное за гаджетами</Divider>
                    <div className={'crm-message-text'}>{query.data.client.data.attributes.anket.gadgetTime}</div>

                    <Divider>Цель</Divider>
                    <div className={'crm-message-text'}>{query.data.client.data.attributes.anket.mainGoal}</div>
                </div>
            )
        }

        // Цели
        if (client.attributes.indicator.length > 0) {
            indicators = _.map(query.data.client.data.attributes.indicator, (value) => {
                return (
                    <ClientIndicator
                        key={value.id}
                        indicator={value}
                        onUpdate={query.refetch}
                        id={id}
                        clientInfo={query.data.client.data}
                    />
                )

            });
        }

        // Навыки
        if (client.attributes.skill.length > 0) {
            skills = _.map(query.data.client.data.attributes.skill, value => {
                return (
                    <ClientSkill
                        key={value.id}
                        skill={value}
                        onUpdate={query.refetch}
                        id={id}
                        clientInfo={query.data.client.data}
                    />
                )

            });
        }

        // График
        if (client.attributes.indicator.length > 0 || client.attributes.skill.length > 0) {

            let contacts = []
            // личные контакты дитя
            for (const contact of query.data.client.data.attributes.account.data.attributes.contacts) {
                if (contact.data) {
                    contacts.push({
                        ...contact,
                        data: {
                            firstName: contact.data.chat.first_name,
                            lastName: contact.data.chat.last_name,
                            username: contact.data.chat.username || contact.value,
                        }
                    })
                } else {
                    contacts.push({
                        ...contact,
                        data: {
                            firstName: query.data.client.data.attributes.firstName,
                            lastName: query.data.client.data.attributes.lastName,
                            username: null,
                        }
                    })
                }
            }
            // контакты всех родителей
            for (const parent of query.data.parents.data) {
                for (const contact of parent.attributes.account.data.attributes.contacts) {
                    if (contact.data) {
                        contacts.push({
                            ...contact,
                            data: {
                                firstName: contact.data.chat.first_name,
                                lastName: contact.data.chat.last_name,
                                username: contact.data.chat.username || contact.value,
                            }
                        })
                    } else {
                        contacts.push({
                            ...contact,
                            data: {
                                firstName: parent.attributes.firstName,
                                lastName: parent.attributes.lastName,
                                username: null,
                            }
                        })
                    }
                }
            }
            // подчищаем все неуникальные
            contacts = _.uniqBy(contacts, 'value');

            graph = <Graph
                clientID={query.data.client.data.id}
                firstName={query.data.client.data.attributes.firstName}
                lastName={query.data.client.data.attributes.lastName}
                indicators={query.data.client.data.attributes.indicator}
                skills={query.data.client.data.attributes.skill}
                contacts={contacts}
            />
        }

        // Комментарии
        messages = _.map(client.attributes.messages.data, (value, index) => {
            return <ClientMessage
                key={index}
                message={value.attributes}
                id={value.id}
                onUpdate={query.refetch}
            />
        })

    }


    function showAddSkillModal() {
        setAddSkillModalOpen(true);
    }

    function closeAddSkillModal() {
        setAddSkillModalOpen(false);
    }

    function showModal() {
        setIsModalVisible(true);
    }

    function closeModal() {
        setIsModalVisible(false);
    }

    // console.log(query.data);

    return (

        <CrmLayout>

            <CrmCenteredTitle
                title={query.data ? `👦 ${query.data.client.data.attributes.firstName} ${query.data.client.data.attributes.lastName}` : '👦'}
                editAction={() => {
                    navigate(child_edit(id));
                }}
                deleteAction={() => {
                    onDelete()
                }}
            />

            {query.loading && <Skeleton active/>}

            {query.data &&
            <Space direction='vertical' size={16} className='block'>

                    <CrmAvatar
                        size={'large'}
                        firstName={query.data.client.data.attributes.firstName}
                        lastName={query.data.client.data.attributes.lastName}
                        color={query.data.client.data.attributes.color}
                        avatarUrl={query.data.client.data.attributes.avatar.data && query.data.client.data.attributes.avatar.data.attributes.previewUrl}
                    />


                {query.data.client.data.attributes.gym.data &&
                <IconAndText
                    icon={<EnvironmentOutlined/>}
                    value={query.data.client.data.attributes.gym.data.attributes.name}
                />
                }

                {query.data.client.data.attributes.birthDate &&
                <IconAndText
                    icon={<GiftOutlined/>}
                    value={moment(query.data.client.data.attributes.birthDate).format('DD MMMM YYYY') + ', ' + moment().diff(query.data.client.data.attributes.birthDate, 'years') + ' лет'}
                />
                }

                <BonusLayout
                    content={<BonusLogo/>}
                    fixed={<Bonus
                        id={id}
                        value={query.data.client.data.attributes.bonus}
                    />}
                />

                {contacts}

                <div>
                    <Divider>Родители</Divider>

                    <Space direction={'vertical'} size={10} className={'block'}>

                        {_.map(query.data.parents.data, (value) => {
                            return (
                                <CrmCard key={value.id} className={'crm-contact'}>
                                    <Space direction={'vertical'} size={10}>
                                        <Link to={parent_info(value.id)}>
                                            <CrmAvatar
                                                size={'large'}
                                                type={'parent'}
                                                firstName={value.attributes.firstName}
                                                lastName={value.attributes.lastName}
                                                color={value.attributes.color}
                                                avatarUrl={value.attributes.avatar.data && value.attributes.avatar.data.attributes.previewUrl}
                                                caption={roleLocation(value.attributes.role)}
                                            />
                                        </Link>

                                        {_.map(value.attributes.account.data.attributes.contacts, (value, index) => {
                                            return (
                                                <CrmContact key={index} type={value.type} value={value.value} data={value.data}/>
                                            )
                                        })}

                                    </Space>
                                </CrmCard>
                            )
                        })}

                    </Space>
                </div>

                <div className={'crm-line'}>
                    <ChildParentsSelectAndAdd childId={id} onUpdate={query.refetch} parents={query.data.parents.data}/>
                    <Button size={'large'} icon={<TelegramOutlined/>} onClick={() => navigate(postingClients([query.data.client.data.attributes.account.data.id]))}/>
                </div>

                <PublicUrl publicKey={query.data.client.data.attributes.account.data.attributes.publicKey}/>

                <Tabs defaultActiveKey="1">

                    <TabPane key="subscriptions" tab={'Абонементы'}>
                        <ClientSubscriptions id={query.data.client.data.attributes.account.data.id}/>
                        <div className={'space'}/>
                        <Button type='link' icon={<PlusOutlined/>}
                                onClick={() => navigate(subscription_new(query.data.client.data.attributes.account.data.id))}>
                            Добавить абонемент
                        </Button>
                    </TabPane>

                    <TabPane key="anket" tab={'Анкета'}>
                        {anket}
                        <ChildEditAnket id={id} data={query.data.client.data.attributes.anket}
                                        onUpdate={query.refetch}/>
                    </TabPane>

                    <TabPane key="indicators" tab={'Цели'}>
                        <Space direction='vertical' size={10} className='block'>
                            {indicators}
                            <Button type={'link'} icon={<PlusOutlined/>} onClick={showModal}>Добавить цель</Button>
                            <ClientModalAddIndicator isOpen={isModalVisible} onClose={closeModal}
                                                     onUpdate={query.refetch} id={id}
                                                     clientInfo={query.data.client.data}/>
                        </Space>
                    </TabPane>

                    <TabPane key="skills" tab={'Навыки'}>
                        <Space direction='vertical' size={10} className='block'>
                            {skills}
                            <Button type={'link'} icon={<PlusOutlined/>} onClick={showAddSkillModal}>Добавить
                                навык</Button>
                            <ClientModalAddSkill isOpen={addSkillModalOpen} onClose={closeAddSkillModal}
                                                 onUpdate={query.refetch} id={id} clientInfo={query.data.client.data}/>
                        </Space>
                    </TabPane>

                    <TabPane key="graph" tab={'График'}>
                        {graph}
                    </TabPane>

                    <TabPane key="comments" tab={'Комментарии'}>
                        <Space direction='vertical' size={20} className='block'>
                            {messages}
                            <ClientAddComment clientId={id} onUpdate={query.refetch}/>
                        </Space>
                    </TabPane>

                    <TabPane key="tasks" tab={'Задачи'}>
                        <ClientTasks id={query.data.client.data.attributes.account.data.id}/>
                        <div className={'space'}/>
                        <Button type='link' icon={<PlusOutlined/>}
                                onClick={() => navigate(task_new(moment().format('YYYY-MM-DD'), query.data.client.data.attributes.account.data.id))}>
                            Добавить задачу
                        </Button>
                    </TabPane>

                </Tabs>
            </Space>
            }
        </CrmLayout>
    );
}



