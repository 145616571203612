import {Button, Space} from "antd";
import {CopyOutlined} from "@ant-design/icons";
import copyToClipboard from "../../helpers/copyToClipboard";
import {Link} from "react-router-dom";
import React from "react";

export default function PublicUrl({publicKey}) {

    if (publicKey) {
        const to = `/client-info/${publicKey}`;
        const url = `https://sila-crm.ru/client-info/${publicKey}`;
        return (
            <Space>
                <Button size={'large'} icon={<CopyOutlined/>} onClick={() => copyToClipboard(url)}/>
                <div>
                    <div>Ссылка на страницу</div>
                    <Link to={to}>{url}</Link>
                </div>
            </Space>
        )
    } else {
        return null
    }
}
