import React from 'react';
import {useNavigate} from 'react-router-dom';
import {subscription_info} from "../../routes";
import SubscriptionStatus from "../../components/SubscriptionStatus";

export default function ClientSubscriptionCard(props) {

    const navigate = useNavigate();

    function go() {
        navigate(subscription_info(props.id));
    }

    let displayProgress = null;

    // Calculate Limit

    const unlimited = props.data.attributes.unlimited;
    const limit = props.data.attributes.limit !== undefined ? props.data.attributes.limit : 0;
    const visitCount = props.data.attributes.visitCount !== undefined ? props.data.attributes.visitCount : 0;

    let limitText = '';

    if (unlimited === true) {
        limitText = <span className={'bold'}>Безлимит</span>
    } else {
        limitText = <span><span className={'bold'}>Доступно {limit - visitCount}</span> из {limit} занятий</span>
    }

    // console.log('data', props.data);

    return (
        <div className={'crm-card crm-subscription-card'} key={props.id} onClick={go}>
            <div className={'crm-subscription-card-title'}>{props.id}</div>

            <div className={'crm-subscription-card-info'}>

                {props.data.attributes.gym.data &&
                    <div className={'crm-payment-text'}>
                    {props.data.attributes.gym.data.attributes.name}, {props.data.attributes.gym.data.attributes.address}
                    </div>
                }

                <div className={'crm-subscription-status'}>

                    <SubscriptionStatus
                        type={'date'}
                        size={'small'}
                        startDate={props.data.attributes.startDate}
                        endDate={props.data.attributes.endDate}
                    />

                    <SubscriptionStatus
                        type={'limit'}
                        size={'small'}
                        unlimited={unlimited}
                        limit={limit}
                        visitCount={visitCount}
                    />

                    <SubscriptionStatus
                        type={'payment'}
                        size={'small'}
                        paid={props.data.attributes.paid}
                    />

                </div>
            </div>

        </div>

    );
}
