import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import CrmLayout from "../../components/CrmLayout";

import {Avatar, Empty, Pagination} from 'antd';

import {subscription_info, subscription_new} from "../../routes";
import {gql, useQuery} from "@apollo/client";

import CrmWindowSpin from "../../components/CrmWindowSpin";
import SubscriptionStatus from "../../components/SubscriptionStatus";
import CrmCenteredTitle from "../../components/CrmCenteredTitle";

const QUERY_SUBSCRIPTIONS = gql`
    query subscriptions ($filters: SubscriptionFiltersInput, $pagination: PaginationArg, $sort: [String] = []) {
        subscriptions (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    user {
                        data {
                            id
                            attributes {
                                role {
                                    data {
                                        id
                                        attributes {
                                            name
                                        }
                                    }
                                }
                                client {
                                    data {
                                        id
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                            avatar {
                                                data {
                                                    attributes {
                                                        previewUrl
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                parent {
                                    data {
                                        id
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                            avatar {
                                                data {
                                                    attributes {
                                                        previewUrl
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    gym {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                    unlimited
                    limit
                    status
                    startDate
                    endDate
                    visitCount
                    paid
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

export default function Subscription() {

    const [page, setPage] = useState(1);

    const navigate = useNavigate();

    const changePage = (page, pageSize) => {
        setPage(page);
    };

    const query = useQuery(QUERY_SUBSCRIPTIONS, {
        variables: {
            filters: {},
            pagination: {
                pageSize: 20,
                page: page
            }
        }
    });

    let items = null;

    if (query.data !== undefined) {

        if (query.data.subscriptions.data.length > 0) {

            items = query.data.subscriptions.data.map(function (value) {

                const user = value.attributes.user.data;
                const roleName = user.attributes.role.data.attributes.name;

                let person = null;
                let child = false;

                if (roleName === 'Child') {
                    person = user.attributes.client.data;
                    child = true;
                }

                if (roleName === 'Parent') {
                    person = user.attributes.parent.data;
                }


                const firstName = person.attributes.firstName;
                const lastName = person.attributes.lastName;
                const avatar = person.attributes.avatar.data;

                let avatarUrl = null;

                if (avatar) {
                    avatarUrl = avatar.attributes.previewUrl;
                }

                return (

                    <div className={'crm-card crm-subscription-card'} key={value.id} onClick={() => goInfo(value.id)}>
                        <div className={'crm-subscription-card-title'}>{value.id}</div>
                        <div className={'crm-subscription-card-avatar'}>
                            <Avatar size={'large'} src={avatarUrl}></Avatar>
                        </div>
                        <div className={'crm-subscription-card-info'}>
                            <div className={'crm-subscription-card-name'}>
                                {child && '👦'} {firstName} {lastName}
                            </div>

                            <div className={'crm-subscription-status'}>

                                <SubscriptionStatus
                                    type={'date'}
                                    size={'small'}
                                    startDate={value.attributes.startDate}
                                    endDate={value.attributes.endDate}
                                />

                                <SubscriptionStatus
                                    type={'limit'}
                                    size={'small'}
                                    unlimited={value.attributes.unlimited}
                                    limit={value.attributes.limit}
                                    visitCount={value.attributes.visitCount}
                                />

                                <SubscriptionStatus
                                    type={'payment'}
                                    size={'small'}
                                    paid={value.attributes.paid}
                                />

                            </div>

                            {/*<div className={'crm-subscription-card-status'}>*/}
                            {/*<div className='caption'>*/}
                            {/*<SubscriptionStatus*/}
                            {/*startDate={value.attributes.startDate}*/}
                            {/*endDate={value.attributes.endDate}*/}
                            {/*unlimited={value.attributes.unlimited}*/}
                            {/*limit={value.attributes.limit}*/}
                            {/*visitCount={value.attributes.visitCount}*/}
                            {/*/>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            {/*<div className={'crm-subscription-card-limit'}>*/}
                            {/*<SubscriptionLimit*/}
                            {/*unlimited={value.attributes.unlimited}*/}
                            {/*limit={value.attributes.limit}*/}
                            {/*visitCount={value.attributes.visitCount}*/}
                            {/*/>*/}
                            {/*</div>*/}
                        </div>

                    </div>
                );
            });
        } else {
            items = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>;
        }
    }

    if (query.loading) {
        items = <CrmWindowSpin spinning/>
    }

// const gymMenu = (
//     <Menu
//         selectedKeys={selectedGym}
//         onClick={selectGym}
//         mode="vertical"
//     >
//         <Menu.Item key={0}>
//             <span className={'bold'}>Все залы</span>
//         </Menu.Item>
//         {gymOptions}
//     </Menu>
// );

    function goAdd() {
        navigate(subscription_new('0'));
    }

    function goInfo(id) {
        navigate(subscription_info(id));
    }

// console.log('allClientsFetch.data', allClientsFetch.data);

    return (

        <CrmLayout>
            <CrmCenteredTitle
                title={'Абонементы'}
                addAction={() => {
                    goAdd()
                }}
            />

            <div className={'crm-subscription-list'}>

                {items}

            </div>

            <div className={'space'}/>

            {query.data !== undefined &&

            query.data.subscriptions.data.length > 0 ? <Pagination
                defaultCurrent={query.data.subscriptions.meta.pagination.page}
                current={query.data.subscriptions.meta.pagination.page}
                total={query.data.subscriptions.meta.pagination.total}
                pageSize={query.data.subscriptions.meta.pagination.pageSize}
                onChange={changePage}
            /> : null
            }

        </CrmLayout>
    );
}



