import {gql, useMutation} from "@apollo/client";
import {useCallback} from "react";
import moment from "moment";

const UPDATE_CLIENT_MUTATION = gql`
    mutation updateClient($data: ClientInput!, $id: ID!) {
        updateClient(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

const useUpdateClientMutation = () => {
    const [updateClientMutation] = useMutation(UPDATE_CLIENT_MUTATION);

    return useCallback(async (client, id) => {

        const newData = {...client};


        if (newData.birthDate !== undefined) {
            newData.birthDate = moment(newData.birthDate).format('YYYY-MM-DD');
        } else {
            newData.birthDate = null;
        }
        
        return updateClientMutation({
            variables: {
                id: id,
                data: newData,
            }
        });
    }, [updateClientMutation]);
}

export default useUpdateClientMutation;
