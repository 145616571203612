import {React, useState} from 'react';
import {DatePicker, message, Skeleton, Space, Tabs, Button} from 'antd';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import _ from 'lodash';

import {useQuery,} from "react-query";
import {getRestApiBase} from "../../env";
import PublicLayout from "./PublicLayout";
import CrmAvatar from "../../components/CrmAvatar";
import ClientVisits from "../Client/Visit/ClientVisits";
import Message from "./Message/Message";
import Skills from "./Skill/Skills";
import Graphs from "./Graph/Graphs";
import BonusLayout from "../../vidgets/bonus/BonusLayout";
import BonusLogo from "../../vidgets/bonus/BonusLogo";
import BonusValue from "../../vidgets/bonus/BonusValue";
import BannerTelegram from "./Banner/BannerTelegram";

import cls from './public.module.scss'
import TelegramOutlined from "../../vidgets/icon/TelegramOutlined";

function usePost(publicKey, range) {
    return useQuery("getClientData", async () => {
        const {data} = await axios.get(
            `${getRestApiBase()}/client-info?publicKey=${encodeURIComponent(publicKey)}&startDate=${encodeURIComponent(range[0].format('YYYY-MM-DD'))}&endDate=${encodeURIComponent(range[1].format('YYYY-MM-DD'))}`
        );
        return data;
    });
}

const { RangePicker } = DatePicker;

export default function PublicClientInfo() {

    const [range, setRange] = useState([dayjs().subtract(1, 'month'), dayjs()]);

    const {public_key} = useParams();

    // const queryClient = useQueryClient();
    const {refetch, data, error, isFetching} = usePost(public_key, range);

    if (error) {
        message.error(error.message);
    }

    const TGUrl = `https://t.me/silaskilla_bot/?start=${public_key}`

    return (
        <PublicLayout>

            {!data && isFetching && <Skeleton active/>}

            {data &&
            <Space className={'block'} direction={'vertical'} size={20}>

                {data.hasTG || <BannerTelegram url={TGUrl}/>}
                {/*{data.hasTG || <a href={TGUrl} target={'_blank'} rel={'noreferrer'}>@silaskilla_bot</a>}*/}

                <div className={cls.line}>
                    <div className={cls.left}>
                        <CrmAvatar
                            firstName={data.person.attributes.firstName}
                            lastName={data.person.attributes.lastName}
                            color={data.person.attributes.color}
                        />
                    </div>
                    <div className={cls.right}>
                        {data.hasTG && <a href={TGUrl} target={'_blank'} rel={'noreferrer'}><Button type={'primary'} icon={<TelegramOutlined/>} className={cls.tgButton}>Telegram</Button></a>}
                    </div>
                </div>

                <BonusLayout
                    content={<BonusLogo/>}
                    fixed={<BonusValue value={data.person.attributes.bonus}/>}
                />

                <Tabs defaultActiveKey="1" className={'antd-public-tabs'}>
                    <Tabs.TabPane key="visit" tab={'Посещения'}>

                        <span className={'filter'}>
                            <RangePicker
                                allowClear={false}
                                ranges={{
                                    'Сегодня': [dayjs(), dayjs()],
                                    'Этот месяц': [dayjs().startOf('month'), dayjs().endOf('month')],
                                    'Прошлый месяц': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
                                }}
                                allowEmpty={false}
                                value={range}
                                size={'small'}
                                bordered={false}
                                format={'DD.MM.YYYY'}
                                onChange={(range) => setRange(range)}
                                onBlur={refetch}
                            />
                        </span>

                        <ClientVisits visits={data.visits}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane key="goal" tab={'Цели'}>
                        {data.person.attributes.indicator && <Graphs data={data.person.attributes.indicator}/>}
                    </Tabs.TabPane>
                    <Tabs.TabPane key="skill" tab={'Навыки'}>
                        <Skills skills={data.person.attributes.skill}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane key="message" tab={'Комментарии'}>
                        <Space direction={'vertical'} className={'block'} size={10}>
                            {_.map(data.messages, (message) => {
                                return (
                                    <Message key={message.id} message={message.attributes}
                                             className={'public-message'}/>
                                )
                            })}
                        </Space>
                    </Tabs.TabPane>
                </Tabs>

            </Space>
            }

        </PublicLayout>
    );
}



