import React from 'react';

import {Link, useParams} from 'react-router-dom';
import {gql, useQuery} from "@apollo/client";
import {Skeleton} from "antd";
import map from 'lodash/map';

import CrmLayout from "../../../components/CrmLayout";
import CrmCenteredTitle from "../../../components/CrmCenteredTitle";
import CrmAvatar from "../../../components/CrmAvatar";
import {client_info, subscription_info} from "../../../routes";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";

const REPORT_GYM = gql`
    query reportGym ($endDate: String!, $id: String!, $startDate: String!) {
        reportGym (startDate: $startDate, endDate: $endDate, id: $id) {
            clientsFirstSubscription {
                count
                data {
                    id
                    attributes {
                        user {
                            data {
                                id
                                attributes {
                                    role {
                                        data {
                                            id
                                            attributes {
                                                name
                                            }
                                        }
                                    }
                                    client {
                                        data {
                                            id
                                            attributes {
                                                firstName
                                                lastName
                                                color
                                                avatar {
                                                    data {
                                                        attributes {
                                                            url
                                                            previewUrl
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    parent {
                                        data {
                                            id
                                            attributes {
                                                firstName
                                                lastName
                                                color
                                                avatar {
                                                    data {
                                                        attributes {
                                                            url
                                                            previewUrl
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        price
                        paid
                    }
                }
            }
        }
    }
`;

export default function ReportGymClientsFirstSubscription() {

    const {id, startDate, endDate} = useParams();

    // console.log('startDate', startDate);
    // console.log('endDate', endDate);

    const reportGym = useQuery(REPORT_GYM, {
        variables: {
            startDate: startDate,
            endDate: endDate,
            id: id
        }
    });

    return (
        <CrmLayout>
            <CrmCenteredTitle title={'Впервые оформили абонементы'}/>
            {reportGym.loading
                ?
                <Skeleton active/>
                :
                <>
                    {/*<p className={'caption color-gray-3'}>Клиенты, у которых есть минимум 1 абонемент в этом зале и выбранном периоде</p>*/}
                    <div className={'crm-report-line caption'}>
                        Клиент
                        <div className={'crm-report-count'}>
                                Абонемент
                        </div>
                    </div>

                    {map(reportGym.data.reportGym.clientsFirstSubscription.data, (value, index) => {

                        const subscription = value;

                        const user = value.attributes.user.data;
                        const roleName = user.attributes.role.data.attributes.name;

                        let person = null;
                        let child = false;

                        if (roleName === 'Child') {
                            person = user.attributes.client.data;
                            child = true;
                        }

                        if (roleName === 'Parent') {
                            person = user.attributes.parent.data;
                        }


                        const firstName = person.attributes.firstName;
                        const lastName = person.attributes.lastName;
                        const avatar = person.attributes.avatar.data;

                        let avatarUrl = null;

                        if (avatar) {
                            avatarUrl = avatar.attributes.previewUrl;
                        }

                        const color = person.attributes.color;

                        return (
                            <div className={'crm-report-line'} key={index}>
                                <Link to={client_info(value.attributes.user.data.id)}>
                                    <CrmAvatar
                                        avatarUrl={avatarUrl}
                                        firstName={firstName}
                                        lastName={lastName}
                                        child={child}
                                        color={color}
                                    />
                                </Link>


                                <div className={'crm-report-count'}>
                                    <Link to={subscription_info(subscription.id)}>
                                        № {subscription.id}
                                    </Link>
                                </div>

                                <div className={'crm-report-price'}>{subscription.attributes.price} {subscription.attributes.price && '₽'}</div>

                                {subscription.attributes.paid === true
                                    ?
                                    <CheckCircleOutlined className={'color-success'}/>
                                    :
                                    <CloseCircleOutlined className={'color-danger'}/>
                                }

                            </div>
                        )
                    })}
                </>
            }
        </CrmLayout>
    );
}



