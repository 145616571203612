import React, {useState} from 'react';
import {Pagination, Space} from 'antd';
import {gql, useQuery} from "@apollo/client";
import _ from 'lodash';
import ClientSubscriptionCard from "./ClientSubscriptionCard";

const QUERY = gql`
    query query (
        $filters: SubscriptionFiltersInput,
        $pagination: PaginationArg = {},
        $sort: [String] = []
    ) {
        subscriptions (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    startDate
                    endDate
                    unlimited
                    limit
                    visitCount
                    paid
                    gym {
                        data {
                            id
                            attributes {
                                name
                                address
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

export default function ClientSubscriptions({id}) {

    const [page, setPage] = useState(1);

    const query = useQuery(QUERY, {
        variables: {
            id: id,
            filters: {
                user: {
                    id: {
                        eq: id
                    }
                }
            },
            pagination: {
                pageSize: 20,
                page: page
            },
            sort: ['createdAt:DESC'],
        },
        fetchPolicy: 'cache-and-network',
    });

    return (
        <>
            {query.data &&
            <Space direction={'vertical'} size={10} className={'block'}>
                {_.map(query.data.subscriptions.data, (item) => {
                    return (<ClientSubscriptionCard key={item.id} id={item.id} data={item}/>)
                })}
                {query.data.subscriptions.data.length > 0 ? <Pagination
                    size={'small'}
                    className={'crm-task-pagination'}
                    defaultCurrent={query.data.subscriptions.meta.pagination.page}
                    current={query.data.subscriptions.meta.pagination.page}
                    total={query.data.subscriptions.meta.pagination.total}
                    pageSize={query.data.subscriptions.meta.pagination.pageSize}
                    onChange={(page) => {
                        setPage(page)
                    }}
                /> : null}
            </Space>
            }
        </>
    );
}