import {getApiBase} from "../../env";
import {ApolloClient, ApolloLink, InMemoryCache} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {createUploadLink} from "apollo-upload-client";


const API_BASE = getApiBase();

const ApiClientFactory = (store) => {
    const authLink = setContext((_, {headers}) => {
        if (!store.auth.token) {
            return headers;
        }
        const token = store.auth.token;

        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    });

    const uploadLink = createUploadLink({
        uri: `${API_BASE}/graphql`,
    });

    return new ApolloClient({
        link: ApolloLink.from([authLink, uploadLink]),
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'cache-and-network',
            }
        },
    });
};

export default ApiClientFactory;
