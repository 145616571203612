import React from 'react';
import {Space} from 'antd';


export default function CrmDisplayData({label, value, children}) {
    return (
        <div className="crm-display-data">
            <Space direction="vertical" size={2} className={'block'}>
                <div className='crm-display-data-key'>{label}</div>
                <div className='crm-display-data-value'>{value}</div>
                {children}
            </Space>
        </div>
    );
}
