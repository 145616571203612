import React, {useState} from 'react';
import {Button, Form, Input} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {gql, useMutation} from "@apollo/client";
import CrmModal from "../../../components/CrmModal";

const UPDATE_PARENT = gql`
    mutation email($data: ParentInput!, $id: ID!) {
        updateParent(id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;

export default function ParentEditAnket({id, data, onUpdate}) {

    const [showModal, setShowModal] = useState(false);

    const [updateParent] = useMutation(UPDATE_PARENT);

    const [form] = Form.useForm();

    const onFinish = async (values) => {
        await updateParent({variables: {data: {anket: values}, id: id}});
        onUpdate();
        setShowModal(false);
    };

    return (
        <>

            <Button type={'link'} onClick={() => {setShowModal(true);form.resetFields()}} icon={<EditOutlined/>}>Редактировать</Button>

            <CrmModal isOpen={showModal} title={'Редактировать анкету'} onClose={() => setShowModal(false)}>
                <div className={'modal-basic-form'}>
                    <Form name={'anket'}
                          form={form}
                          onFinish={onFinish}
                          initialValues={data}
                          layout={'vertical'}
                          size={'large'}
                          scrollToFirstError
                    >

                        <Form.Item name={'experience'} label={'Опыт занятий'}>
                <Input.TextArea
                    autoSize={{minRows: 1, maxRows: 6,}}
                    placeholder="Опыт занятий"
                />
                        </Form.Item>

                        <Form.Item name={'contraindications'} label={'Противопоказания'}>
                <Input.TextArea
                    autoSize={{minRows: 1, maxRows: 6,}}
                />
                        </Form.Item>

                        <Form.Item name={'mainGoal'} label={'Цели'}>
                <Input.TextArea
                    autoSize={{minRows: 1, maxRows: 6,}}
                    placeholder="Цели и пожелания"
                />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={updateParent.loading}>
                                Сохранить
                            </Button>
                        </Form.Item>


                    </Form>
                </div>

            </CrmModal>

        </>
    );

}