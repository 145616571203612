import {Button, Input, message, Select, Table} from 'antd';
import React, {useState} from 'react';
import SelectGym from '../../components/Form/SelectGym';
import SelectTrainer from '../../components/Form/SelectTrainer';
import {gql, useQuery} from '@apollo/client';
import {RightOutlined} from '@ant-design/icons';

const GET_POSTING_RECIVERS = gql`
    query getPostingRecivers($ids: [ID], $gym: ID, $provider: String!, $searchText: String, $trainer: ID, $type: String!) {
        getPostingRecivers(ids: $ids, trainer: $trainer, type: $type, gym: $gym, provider: $provider, searchText: $searchText) {
            items {
                key
                id
                gym
                type
                trainer
                name
                phone
                owner
            }
        }
    }
`;

const PARENT_AND_CHILD = 'all';
const PARENT = 'parent';
const CHILD = 'child';

const columns = [
    {
        title: 'Имя',
        dataIndex: 'name',
    },
    {
        title: 'Возраст',
        dataIndex: 'type',
        render: (_, { type }) => {
            if (type === 'child') {
                return 'Ребенок';
            }
            return 'Взрослый';
        },
    },
    {
        title: 'Зал',
        dataIndex: 'gym',
    },
    {
        title: 'Тренер',
        dataIndex: 'trainer',
    },
    {
        title: 'Владелец',
        dataIndex: 'owner',
    },
    {
        title: 'Адрес',
        dataIndex: 'phone',
    },
];

export const PostingClients = ({setClients, setStage, userIds }) => {
    const { Option } = Select;

    const [type, setType] = useState(PARENT_AND_CHILD);
    const [gym, setGym] = useState(0);
    const [trainer, setTrainer] = useState(0);
    const [name, setName] = useState('');
    const [clientsData, setClientsData] = useState([]);
    const [selectedClients, setSelectedClients] = useState(0);

    const query = useQuery(GET_POSTING_RECIVERS, {
        variables: {
            ids: userIds,
            gym,
            type,
            trainer,
            searchText: name,
            provider: 'telegram',
        },
        onCompleted: (data) => {
            setClientsData(data.getPostingRecivers.items);
        },
        onError: (error) => {
            message.error(error.message)
            console.error(error)
        },
        fetchPolicy: "no-cache",
    });

    const goToMessage = () => {
        setStage(1);
    };

    const onChangeClients = (selectedClients) => {
        setClients(selectedClients);
        setSelectedClients(selectedClients.length)
    };

    return (
        <>
            <div className={'postingWrap'}>
                <div className={'crm-filters topFilters'}>
                    <Select
                        className={'filterTopItem'}
                        bordered={false}
                        onSelect={(value) => {
                            setType(value);
                        }}
                        value={type}
                        dropdownMatchSelectWidth={false}
                    >
                        <Option value={PARENT_AND_CHILD}>Взрослые и дети</Option>
                        <Option value={PARENT}>Взрослые</Option>
                        <Option value={CHILD}>Дети</Option>
                    </Select>
                    <SelectGym
                        className={'filterTopItem'}
                        bordered={false}
                        onSelect={(value) => {
                            setGym(value);
                        }}
                        emptyValue={'Зал не указан'}
                        initialValue={'Все залы'}
                    />
                    <SelectTrainer
                        className={'filterTopItem'}
                        bordered={false}
                        onSelect={(value) => {
                            setTrainer(value);
                        }}
                        emptyValue={'Тренер не указан'}
                        initialValue={'Все тренеры'}
                    />
                    <Input.Search
                        className={'crm-filters-1-item'}
                        onSearch={(value) => {
                            setName(value);
                        }}
                    />
                </div>

                <p>Найдено записей: {clientsData.length}</p>
            </div>


            <Table
                className={'clientsTabel'}
                rowSelection={{
                    type: 'checkbox',
                    onChange: (selectedRowKeys, selectedRows) => {
                        onChangeClients(selectedRows);
                    },
                    getCheckboxProps: (record) => ({
                        disabled: record.name === 'Disabled User',
                        // Column configuration not to be checked
                        name: record.name,
                    }),
                }}
                columns={columns}
                dataSource={clientsData}
                loading={query.loading}
                pagination={{
                    pageSize: 100
                }}
                rowClassName={'tableRow'}
            />

            <div className={'postingWrap'}>
                <div className={'bottomButtons'}>
                    <Button type={'primary'} size={'large'} onClick={goToMessage} disabled={!selectedClients}>
                        <RightOutlined /> Далее
                    </Button>

                    <span>Выбрано: {selectedClients}</span>
                </div>
            </div>

        </>
    );
};