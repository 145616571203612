import React from 'react';

import {Link, useParams} from 'react-router-dom';
import {gql, useQuery} from "@apollo/client";
import {Skeleton} from "antd";
import map from 'lodash/map';

import CrmLayout from "../../../components/CrmLayout";
import CrmCenteredTitle from "../../../components/CrmCenteredTitle";
import {training_info} from "../../../routes";
import moment from "moment";

const REPORT_USER = gql`
    query reportUser ($endDate: String!, $id: String!, $startDate: String!) {
        reportUser (startDate: $startDate, endDate: $endDate, id: $id) {
            trainingsTotal {
                count
                data {
                    id
                    attributes {
                        name
                        startDate
                    }
                }
            }
        }
    }
`;

export default function ReportUserTrainingsTotal() {

    const {id, startDate, endDate} = useParams();

    const reportUser = useQuery(REPORT_USER, {
        variables: {
            startDate: startDate,
            endDate: endDate,
            id: id
        }
    });

    return (
        <CrmLayout>
            <CrmCenteredTitle title={'Тренировки'}/>
            {reportUser.loading
                ?
                <Skeleton active/>
                :
                <>
                    <div className={'crm-report-line caption'}>
                        Название
                        <div className={'crm-report-count caption'}>Дата</div>
                    </div>

                    {map(reportUser.data.reportUser.trainingsTotal.data, (value, index) => {
                        return (
                            <div className={'crm-report-line'} key={index}>
                                <Link to={training_info(value.id)}>
                                    №{value.id}, {value.attributes.name}
                                </Link>
                                <div className={'crm-report-count'}><Link
                                    to={training_info(value.id)}>{moment(value.attributes.startDate).format('DD.MM.YYYY')}</Link>
                                </div>
                            </div>
                        )
                    })}
                </>
            }
        </CrmLayout>
    );
}



