import React from 'react';
import {Button, Form, Input,} from 'antd';
import SelectGym from "../../components/Form/SelectGym";
import SelectTrainers from "../../components/Form/SelectTrainers";
import DateAndTimePicker from "../../components/Form/DateAndTimePicker";

export default function TrainingForm ({data, onSubmit, loading, actionName, actionIcon}) {

    const [form] = Form.useForm();

    const onFinish = (values) => {
        onSubmit(values);
    };

    return (

        <Form name="task"
              form={form}
              onFinish={onFinish}
              initialValues={data}
              layout={'vertical'}
              size={'large'}
              disabled={loading}
              scrollToFirstError
        >

            <Form.Item name={'name'} label={'Название тренироввки'}>
                <Input/>
            </Form.Item>

            <Form.Item name={'startDate'} label={'Начало'} required
                       rules={[{required: true, message: 'Заполните поле',},]}>
                <DateAndTimePicker/>
            </Form.Item>

            <Form.Item name={'gym'} label={'Зал'} required
                       rules={[{required: true, message: 'Заполните поле',},]}>
                <SelectGym/>
            </Form.Item>

            <Form.Item name={'trainers'} label={'Тренеры'}>
                <SelectTrainers/>
            </Form.Item>

            <Form.Item name={'contentUrl'} label={'Ссылка на состав тренировки'}>
                <Input/>
            </Form.Item>

            <Form.Item name={'comment'} label={'Комментарий'}>
                <Input.TextArea autoSize={{minRows: 1, maxRows: 6,}}/>
            </Form.Item>


            <Form.Item>
                <Button type="primary" htmlType="submit" icon={actionIcon} loading={loading}>
                    {actionName}
                </Button>
            </Form.Item>

        </Form>
    );

}