import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {task_new, training_new} from "../../routes";
import moment from "moment";
import {Skeleton} from 'antd';
import CalendarTraining from "./CalendarTraining";
import cn from 'classnames';

import {PlusOutlined} from '@ant-design/icons';
import CalendarTask from "./CalendarTask";

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

export default function CalendarGrid(props) {

    // назначили пропсы
    const {loading, trainings, tasks, startWeek} = props;

    // замерили ширину окна и отловили ее изменение
    // данные записали в calWidth
    const [calWidth, setCalWidth] = useState(getWindowDimensions().width);
    useEffect(() => {
        function handleResize() {
            setCalWidth(getWindowDimensions().width);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // открываем создание тренировки из календарной сетки
    const navigate = useNavigate();

    function goAdd(date, time) {
        if (time === 'task') {
            navigate(task_new(date.format('YYYY-MM-DD'), 0));
        } else {
            navigate(training_new(date.format('YYYY-MM-DD'), time));
        }
    }

    //  расчитали размер ячеек исходя из ширины экрана
    function calculateGrid(width) {
        let columns = 3;
        if (width >= 700) {
            columns = 7;
        } else if (width >= 570) {
            columns = 6;
        } else if (width >= 480) {
            columns = 5;
        } else if (width > 425) {
            columns = 4;
        } else {
            columns = 3;
        }
        const columnWidth = Math.floor((width - 40 - (10 * columns) - 20) / columns);
        const firstColumn = (width - (columnWidth + 10) * columns) - 20;
        const tableWidth = firstColumn + (columnWidth + 10) * 7 + 20;
        return {
            columns,
            columnWidth,
            firstColumn,
            tableWidth
        };
    }

    const calGrid = calculateGrid(calWidth);

    // заполнили данные для легеды: названий дней недели, числа и "если сегодня"
    let legend = [];
    for (let i = 0; i <= 6; i++) {
        legend.push({
            name: moment(startWeek).add(i, 'days').format("dd"),
            number: moment(startWeek).add(i, 'days').format("DD"),
            isToday: moment(startWeek).add(i, 'days').isSame(moment(), 'days'),
        });
    }

    // заполняем матрицу данных по числам и часам
    // index 0 - для задач
    // index 1 - 0 часов
    // index 24 - 23 часа
    let matrix = [];
    matrix[0] = [];
    for (let curDay = 0; curDay <= 6; curDay++) {
        matrix[0][curDay] = {
            date: moment(startWeek).add(curDay, 'days'),
            time: 'task',
            tasks: []
        };
    }
    for (let curHour = 0; curHour < 24; curHour++) {
        matrix[curHour + 1] = [];
        for (let curDay = 0; curDay <= 6; curDay++) {
            matrix[curHour + 1][curDay] = {
                date: moment(startWeek).add(curDay, 'days'),
                time: curHour,
                trainings: []
            };
        }
    }

    // проходимя по массиву тренировок
    // добавляем в матрицу информацию о тренировке,
    // заполнив объект "trainings"
    trainings.forEach(function callback(value) {
        const trainingDay = moment(value.attributes.startDate).isoWeekday();
        const trainingTime = moment(value.attributes.startDate).format('H');
        matrix[parseInt(trainingTime) + 1][trainingDay - 1].trainings.push(value);
    });

    // проходимя по массиву задач
    // добавляем в матрицу информацию о задачах,
    // заполнив объект "tasks"
    tasks.forEach(function callback(value) {
        const trainingDay = moment(value.attributes.date).isoWeekday();
        matrix[0][trainingDay - 1].tasks.push(value);
    });
    // console.log('matrix', matrix);

    // проходимся по всей матрице
    const calendarTable = matrix.map(function (valueA, keyA) {
        const calendarRow = valueA.map(function (valueB, keyB) {

            // для всех задач в одной ячейки создаем объекты
            // только для индекса 0 - он для задач
            let calendarTask = null;
            if (keyA === 0) {
                calendarTask = valueB.tasks.map(function (valueC, keyC) {
                    return (
                        <CalendarTask
                            key={keyA + keyB + keyC}
                            id={valueC.id}
                            name={valueC.attributes.name}
                            executor={valueC.attributes.executor.data}
                            done={valueC.attributes.done}
                            date={valueC.attributes.date}
                        />
                    )
                });
            }

            // для всех тренировок в одной ячейки создаем объекты
            // пропускаем индекс 0 - он для задач
            let calendarTraining = null;
            if (keyA !== 0) {
                calendarTraining = valueB.trainings.map(function (valueC, keyC) {
                    return (
                        <CalendarTraining
                            key={keyA + keyB + keyC}
                            id={valueC.id}
                            name={valueC.attributes.name}
                            trainers={valueC.attributes.trainers.data}
                            color={valueC.attributes.gym.data && valueC.attributes.gym.data.attributes.color}
                            gymName={valueC.attributes.gym.data && valueC.attributes.gym.data.attributes.name}
                        />
                    )
                });
            }

            // рисуем ячейку
            return (
                <td key={keyA + keyB} style={{width: calGrid.columnWidth}}>
                    {calendarTask}
                    {calendarTraining}

                    {loading && <Skeleton.Button block active/>}

                    {!loading && <div className={'calendar-add-button'} onClick={() => goAdd(valueB.date, valueB.time)}>
                        <PlusOutlined/></div>}

                </td>
            );
        });

        // рисуем строчку одного временного промежутка
        return (
            <React.Fragment key={keyA}>
                <tr>
                    <td className='calendar-head-time'
                        style={{width: calGrid.firstColumn}}>{keyA !== 0 ? `${keyA - 1}:00` : `Задачи`}</td>
                    <td colSpan={7}>
                        <hr/>
                    </td>
                </tr>
                <tr className='calendar-row'>
                    <td style={{width: calGrid.firstColumn}}></td>
                    {calendarRow}
                </tr>
            </React.Fragment>

        );
    });

    return (
        <div className='calendar'>
            <table className='calendar-table' style={{width: calGrid.tableWidth}}>
                <thead></thead>
                <tbody>
                <tr>
                    <td className='calendar-head-time' style={{width: calGrid.firstColumn}}/>
                    {
                        legend.map(function (value, index) {
                            // {console.log(value)}
                            return (
                                <td
                                    key={index}
                                    className={cn(['calendar-head-date-number', value.isToday && 'today'])}
                                    style={{width: calGrid.columnWidth}}
                                >
                                    {value.isToday ? 'Сегодня' : <span>{value.name}, {value.number}</span>}
                                </td>
                            )
                        })
                    }
                </tr>
                </tbody>
            </table>

            <table className='calendar-table calendar-body' style={{width: calGrid.tableWidth}}>
                <thead></thead>
                <tbody>
                {calendarTable}
                </tbody>
            </table>
        </div>
    );
}
