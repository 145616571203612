import Icon from '@ant-design/icons';

const CustomIcon = () => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2930_895)">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M512 960C759.424 960 960 759.424 960 512C960 264.576 759.424 64 512 64C264.576 64 64 264.576 64 512C64 759.424 264.576 960 512 960ZM1024 512C1024 794.77 794.77 1024 512 1024C229.23 1024 0 794.77 0 512C0 229.23 229.23 0 512 0C794.77 0 1024 229.23 1024 512Z"
                  />
            <path
                d="M427.119 592.546L415.458 716.476C415.458 716.476 410.577 754.449 448.542 716.476C486.507 678.502 522.846 649.22 522.846 649.22"
                />
            <path
                d="M347.674 555.426L222.72 514.714C222.72 514.714 207.787 508.655 212.595 494.916C213.585 492.083 215.582 489.673 221.555 485.53C249.242 466.232 734.008 291.994 734.008 291.994C734.008 291.994 747.695 287.381 755.768 290.449C757.765 291.067 759.562 292.205 760.975 293.745C762.388 295.286 763.366 297.174 763.81 299.217C764.682 302.825 765.047 306.537 764.894 310.246C764.856 313.455 764.467 316.429 764.173 321.092C761.22 368.73 672.866 724.262 672.866 724.262C672.866 724.262 667.58 745.067 648.64 745.779C643.986 745.93 639.349 745.141 635.005 743.461C630.662 741.781 626.702 739.244 623.36 736C586.193 704.03 457.732 617.698 429.346 598.712C428.706 598.275 428.167 597.707 427.765 597.044C427.363 596.382 427.108 595.641 427.017 594.872C426.62 592.87 428.796 590.392 428.796 590.392C428.796 590.392 652.48 391.565 658.432 370.692C658.893 369.075 657.152 368.277 654.814 368.986C639.957 374.451 382.413 537.092 353.988 555.042C351.942 555.661 349.78 555.793 347.674 555.426Z"
                />
        </g>
        <defs>
            <clipPath id="clip0_2930_895">
                <rect width="1024" height="1024" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)


export default function TelegramOutlined(props) {
    return <Icon component={CustomIcon} {...props}/>
}