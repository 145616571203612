import React, {useState} from 'react';
import {Button, Checkbox, Form, Input} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {gql, useMutation} from "@apollo/client";
import CrmModal from "../../../components/CrmModal";

const UPDATE = gql`
    mutation update($data: ClientInput!, $id: ID!) {
        updateClient(id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;

export default function ChildEditAnket({id, data, onUpdate}) {

    const [showModal, setShowModal] = useState(false);

    const [updateClient] = useMutation(UPDATE);

    const [form] = Form.useForm();

    const onFinish = async (values) => {
        await updateClient({variables: {data: {anket: values}, id: id}});
        onUpdate();
        setShowModal(false);
    };

    return (
        <>

            <Button type={'link'} onClick={() => {setShowModal(true);form.resetFields()}} icon={<EditOutlined/>}>Редактировать</Button>

            <CrmModal isOpen={showModal} title={'Редактировать анкету'} onClose={() => setShowModal(false)}>
                <div className={'modal-basic-form'}>
                    <Form name={'anket'}
                          form={form}
                          onFinish={onFinish}
                          initialValues={data}
                          layout={'vertical'}
                          size={'large'}
                          scrollToFirstError
                    >

                        <Form.Item name={'experience'} label={'Опыт занятий'}>
                <Input.TextArea
                    autoSize={{minRows: 1, maxRows: 6,}}
                    placeholder="Опыт занятий"
                />
                        </Form.Item>

                        <Form.Item name={'contraindications'} label={'Противопоказания'}>
                <Input.TextArea
                    autoSize={{minRows: 1, maxRows: 6,}}
                />
                        </Form.Item>

                        <Form.Item valuePropName="checked" name={'fullFamily'} label={'Состав семьи'}>
                            <Checkbox
                                checkedChildren="👫"
                                unCheckedChildren="🧍"
                            >
                                Ребенок растет в полной семье
                            </Checkbox>
                        </Form.Item>

                        <Form.Item name={'gadgetTime'} label={'Время проведенное за гаджетами'}>
                            <Input.TextArea
                                autoSize={{minRows: 1, maxRows: 6,}}
                                placeholder={'Планшет, 30 минут каждый день'}
                            />
                        </Form.Item>

                        <Form.Item name={'mainGoal'} label={'Цели'}>
                <Input.TextArea
                    autoSize={{minRows: 1, maxRows: 6,}}
                    placeholder="Цели и пожелания"
                />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={updateClient.loading}>
                                Сохранить
                            </Button>
                        </Form.Item>


                    </Form>
                </div>

            </CrmModal>

        </>
    );

}