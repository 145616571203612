import React from 'react';
import map from 'lodash/map';
import WC from "../../../components/Charts/includes/circle_60х60_white.png";
import YC from "../../../components/Charts/includes/circle_60х60_green.png";
import OC from "../../../components/Charts/includes/circle_60х60_purple.png";
import textEllipsis from "../../../components/Charts/includes/TextEllipsis";

const colors = [
    '#FFFFFF',
    '#B2F900',
    '#E100FF',
    '#00EAFF',
    '#FFAE00',
    '#00FF7B',
    '#6D33FF',
    '#FF2D1A',
    '#33FF33',
    '#0051FF',
    '#FF1A90',
    '#3e77fd',
    '#baf341',
    '#6ff5ab',
    '#5fa4ee',
];

const TreeCircleChart = ({
                                     params,
                                     ...rest
                                 }) => {

    return (
        <svg width="1080" height="1080" viewBox="0 0 1080 1080" fill="none" style={{backgroundColor: '#2D68C4', borderRadius: 48}} xmlns="http://www.w3.org/2000/svg" {...rest}>

            <g>
                {map(params, (param, index) => {



                    if (index === 0) {
                        const r = 327;
                        const strokeDasharray = Math.PI * r * 2;
                        let strokeDashoffset = ((100 - param.percent) / 100) * Math.PI * r * 2;
                        const rotate = (param.percent / 100) * 360;
                        if (param.percent >= 100) {
                            strokeDashoffset = 0;
                        }
                        return <>
                            <circle
                                cx="540"
                                cy="540"
                                r={r}
                                stroke="#fff"
                                strokeOpacity="0.3"
                                strokeWidth="60"
                            />
                            <circle
                                cx="540"
                                cy="540"
                                r={r}
                                stroke="#fff"
                                strokeOpacity="1"
                                strokeWidth="60"
                                strokeDashoffset={strokeDashoffset}
                                strokeDasharray={strokeDasharray}
                                strokeLinecap="round"
                                transform="rotate(-90 540 540)"
                            />

                            <image
                                width="120"
                                height="120"
                                href={WC}
                                x="480"
                                y="153"
                                transform={`rotate(${rotate} 540 540)`}
                            />
                        </>
                    }

                    if (index === 1) {

                        const r = 232;
                        const strokeDasharray = Math.PI * r * 2;
                        let strokeDashoffset = ((100 - param.percent) / 100) * Math.PI * r * 2;
                        const rotate = (param.percent / 100) * 360;
                        if (param.percent >= 100) {
                            strokeDashoffset = 0;
                        }
                        return <>
                            <circle
                                cx="540"
                                cy="540"
                                r={r}
                                stroke="#B2F900"
                                strokeOpacity="0.3"
                                strokeWidth="60"
                            />
                            <circle
                                cx="540"
                                cy="540"
                                r={r}
                                stroke="#B2F900"
                                strokeOpacity="1"
                                strokeWidth="60"
                                strokeDashoffset={strokeDashoffset}
                                strokeDasharray={strokeDasharray}
                                strokeLinecap="round"
                                transform="rotate(-90 540 540)"
                            />

                            <image
                                width="120"
                                height="120"
                                href={YC}
                                x="480"
                                y="248"
                                transform={`rotate(${rotate} 540 540)`}
                            />
                        </>
                    }

                    if (index === 2) {

                        const r = 133;
                        const strokeDasharray = Math.PI * r * 2;
                        let strokeDashoffset = ((100 - param.percent) / 100) * Math.PI * r * 2;
                        const rotate = (param.percent / 100) * 360;
                        if (param.percent >= 100) {
                            strokeDashoffset = 0;
                        }

                        return <>
                            <circle
                                cx="540"
                                cy="540"
                                r={r}
                                stroke="#E100FF"
                                strokeOpacity="0.3"
                                strokeWidth="60"
                            />
                            <circle
                                cx="540"
                                cy="540"
                                r={r}
                                stroke="#E100FF"
                                strokeOpacity="1"
                                strokeWidth="60"
                                strokeDashoffset={strokeDashoffset}
                                strokeDasharray={strokeDasharray}
                                strokeLinecap="round"
                                transform="rotate(-90 540 540)"
                            />

                            <image
                                width="120"
                                height="120"
                                href={OC}
                                x="480"
                                y="347"
                                transform={`rotate(${rotate} 540 540)`}
                            />
                        </>
                    }

                    return null;
                })}
            </g>

            <g>
                {map(params, (param, index) => {
                    const delta = 50;

                    let color = colors[index];

                    const title = textEllipsis(param.title, 'normal 35px Open Sans', 600);

                    const y = 1040 - (params.length - index - 1) * delta;

                    return (
                        <React.Fragment key={index}>
                            <text fontSize="35" fontWeight="normal" fill={color} fontFamily="'Open Sans', sans-serif">
                                <tspan x="80" y={y}>{title}</tspan>
                            </text>

                            <text fontSize="35" fontWeight="normal" fill={color} textAnchor="end"
                                  fontFamily="'Open Sans', sans-serif">
                                <tspan x="780" y={y}>{param.value}</tspan>
                            </text>

                            <text fontSize="35" fontWeight="normal" fill={color} textAnchor="end"
                                  fontFamily="'Open Sans', sans-serif">
                                <tspan x="1000" y={y}>Цель: {param.targetValue}</tspan>
                            </text>
                        </React.Fragment>
                    )
                })}
            </g>

        </svg>
    );
}

export default TreeCircleChart;
