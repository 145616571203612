import React, {useState} from 'react';
import {Button, Input, message, Radio, Select, Space, Spin} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';

import map from 'lodash/map';

import CrmLayout from "../../components/CrmLayout";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {automation_info} from "../../routes";
import CrmAvatar from "../../components/CrmAvatar";
import {gql, useMutation, useQuery} from "@apollo/client";
import CrmCenteredTitle from "../../components/CrmCenteredTitle";
import {OFFSETS, OPTIONS} from "./OptionNames";

const schema = yup.object({
    cronOffset: yup.number().required('Нужно обязательно выбрать'),
    taskOffset: yup.number().required('Нужно обязательно выбрать'),
    name: yup.string().required('Нужно обязательно заполнить'),
    comment: yup.string().required('Нужно обязательно заполнить'),
}).required();

const {Option} = Select;

const QUERY_USERS = gql`
    query users ($filters: UsersPermissionsUserFiltersInput, $pagination: PaginationArg, $sort: [String] = []) {
        usersPermissionsUsers (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    color
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

const CREATE = gql`
    mutation create($data: AutomationInput!) {
        createAutomation(data: $data) {
            data {
                id
            }
        }
    }
`;

export default function AutomationNew() {

    const query = useQuery(QUERY_USERS, {
        variables: {
            filters: {
                role: {
                    name: {
                        ne: 'Authenticated'
                    }
                }
            },
            sort: 'lastName:ASC',
            pagination: {
                limit: 200
            }
        }
    });

    const [selectedUser, setSelectedUser] = useState('9');
    const [selectedAction, setSelectedAction] = useState('clientNoContacts');
    const [selectedAuthor, setSelectedAuthor] = useState('author');

    const {handleSubmit, control, formState: {isSubmitting, isValid}} = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            cronOffset: 0,
            taskOffset: 1,
            name: '',
            comment: ''
        },
        reValidateMode: 'onChange',
        mode: 'all',
    });

    const [createMutation] = useMutation(CREATE);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        try {
            let newData = {
                ...data
            };
            newData.action = selectedAction;
            if (selectedAuthor === 'user') {
                newData.author = `user_id:${selectedUser}`;
            } else {
                newData.author = selectedAuthor
            }
            const res = await createMutation({variables: {data: newData}});
            const createdId = res.data.createAutomation.data.id;
            navigate(automation_info(createdId), {replace: true});
        } catch (error) {
            console.error(error);
            message.error('Что-то пошло не так, попробуйте снова');
        }
    };

    let users = [];

    if (query.data !== undefined) {
        users = query.data.usersPermissionsUsers.data;
    }

    const usersOptions = users.map(function (value) {
        return (
            <Option key={value.id} value={value.id}><CrmAvatar type='user' firstName={value.attributes.firstName}
                                                               lastName={value.attributes.lastName}
                                                               color={value.attributes.color}/></Option>
        )
    });

    return (

        <CrmLayout>

            <CrmCenteredTitle
                title={'Новая настройка'}
            />

            <Space direction="vertical" size={20} style={{width: '100%'}}>

                <Space direction="vertical" size={10} style={{width: '100%'}}>
                    Событие

                    <Select
                        filterOption={false}
                        size="large"
                        style={{width: '100%'}}
                        value={selectedAction}
                        onChange={(value) => {setSelectedAction(value)}}
                        dropdownMatchSelectWidth={false}
                    >
                        {map(OPTIONS, function (item, index) {
                            return (
                                <Option key={index} value={item.value}>{item.name}</Option>
                            )
                        })}
                    </Select>

                </Space>

                <Space direction="vertical" size={10} style={{width: '100%'}}>
                    Создать задачу относительно события
                    <Controller
                        name="cronOffset"
                        control={control}
                        render={({field, formState: {submitCount}, fieldState: {error, isTouched}}) => (
                            <React.Fragment>
                                <Select
                                    filterOption={false}
                                    size="large"
                                    {...field}
                                    style={{width: '100%'}}
                                    dropdownMatchSelectWidth={false}
                                >
                                    {map(OFFSETS, function (item, index) {
                                        return (
                                            <Option key={index} value={item.value}>{item.name}</Option>
                                        )
                                    })}
                                </Select>
                                {(error && submitCount > 0) &&
                                <div className={'caption color-danger'}>{error.message}</div>}
                            </React.Fragment>
                        )}/>
                </Space>

                <Space direction="vertical" size={10} style={{width: '100%'}}>
                    Назначить задачу относительно события
                    <Controller
                        name="taskOffset"
                        control={control}
                        render={({field, formState: {submitCount}, fieldState: {error, isTouched}}) => (
                            <React.Fragment>
                                <Select
                                    filterOption={false}
                                    size="large"
                                    {...field}
                                    style={{width: '100%'}}
                                    dropdownMatchSelectWidth={false}
                                >
                                    {map(OFFSETS, function (item, index) {
                                        return (
                                            <Option key={index} value={item.value}>{item.name}</Option>
                                        )
                                    })}
                                </Select>
                                {(error && submitCount > 0) &&
                                <div className={'caption color-danger'}>{error.message}</div>}
                            </React.Fragment>
                        )}/>
                </Space>

                <Space direction="vertical" size={10} style={{width: '100%'}}>
                    Исполнитель

                    <Radio.Group size="large" value={selectedAuthor} onChange={(e) => {
                        setSelectedAuthor(e.target.value)
                    }}>
                        <Space direction="vertical">
                            <Radio value={'author'}>{OPTIONS[selectedAction].author}</Radio>
                            {OPTIONS[selectedAction].trainer &&
                            <Radio value={'trainer'}>{OPTIONS[selectedAction].trainer}</Radio>}
                            <Radio value={'user'}>Из списка</Radio>
                        </Space>
                    </Radio.Group>

                    <Select
                        filterOption={false}
                        notFoundContent={query.loading ? <Spin size="small"/> : null}
                        placeholder="Выберите исполнителя"
                        size="large"
                        style={{width: '100%'}}
                        disabled={selectedAuthor !== 'user'}
                        onChange={(value) => setSelectedUser(value)}
                        value={selectedUser}
                        dropdownMatchSelectWidth={false}
                    >
                        {usersOptions}
                    </Select>

                </Space>

                <Space direction="vertical" size={10} style={{width: '100%'}}>
                    Название задачи
                    <Controller
                        name="name"
                        control={control}
                        render={({field, formState: {submitCount}, fieldState: {error, isTouched}}) => (
                            <React.Fragment>
                                <Input
                                    size={'large'}
                                    {...field}
                                />
                                {(error && submitCount > 0) &&
                                <div className={'caption color-danger'}>{error.message}</div>}
                            </React.Fragment>
                        )}/>
                    <span className={'caption color-gray-3'}>Поддерживаются переменные %firstName% и %lastName%, которые будут заменены на Имя и Фамилию клиента</span>
                </Space>

                <Space direction="vertical" size={10} style={{width: '100%'}}>
                    Комментарий
                    <Controller
                        name="comment"
                        control={control}
                        render={({field, formState: {submitCount}, fieldState: {error, isTouched}}) => (
                            <React.Fragment>
                                <Input.TextArea
                                    size={'large'}
                                    rows={3}
                                    {...field}
                                />
                                {(error && submitCount > 0) &&
                                <div className={'caption color-danger'}>{error.message}</div>}
                            </React.Fragment>
                        )}/>
                    <span className={'caption color-gray-3'}>Поддерживаются переменные %firstName% и %lastName%, которые будут заменены на Имя и Фамилию клиента</span>
                </Space>

                <Button
                    size='large'
                    type='primary'
                    icon={<PlusOutlined/>}
                    loading={isSubmitting}
                    disabled={!isValid}
                    onClick={handleSubmit(onSubmit)}
                >Добавить настройку</Button>

            </Space>

        </CrmLayout>
    );
}
