import React from 'react';
import {Button, Space} from 'antd';
import {MailOutlined, PhoneOutlined, WhatsAppOutlined} from '@ant-design/icons';
import img from './Telegram_logo.svg';

const getPhoneLink = (phoneNumber) => {
    let pn = '';
    if (phoneNumber !== null) {
        pn = phoneNumber.replace(/[^0-9]/g, '');
    }
    return `tel://${pn}`;
};
const getMailink = (mail) => {
    return `mailto:${mail}`;
};
const getWhatsappLink = (phoneNumber) => {
    let pn = '';
    if (phoneNumber !== null) {
        pn = phoneNumber.replace(/[^0-9]/g, '');
    }
    return `https://wa.me/${pn}`;
};

const getTelegramLink = (value) => {
    return `https://t.me/${value}`;
};

export default function CrmContact({type, value, data}) {

    let button = null;

    if (type === 'phoneWhatsapp') {
        button = <Space size={8}>
            <Button className={'buttonContactPhone'} type={'primary'} shape={'circle'} icon={<PhoneOutlined/>}
                    onClick={() => {
                        const link = getPhoneLink(value);
                        window.open(link, '_self').focus();
                        return;
                    }}/>
            <Button className={'buttonContactWhatsapp'} type={'primary'} shape={'circle'} icon={<WhatsAppOutlined/>}
                    onClick={() => {
                        const link = getWhatsappLink(value);
                        window.open(link, '_blank').focus();
                        return;
                    }}/>
            {value}
        </Space>
    }
    if (type === 'phone') {
        button =
            <Space size={8}>
                <Button className={'buttonContactPhone'} type={'primary'} shape={'circle'}
                        icon={<PhoneOutlined/>} onClick={() => {
                    const link = getPhoneLink(value);
                    window.open(link, '_self').focus();
                    return;
                }}/>
                {value}
            </Space>
    }
    if (type === 'email') {
        button =
            <Space size={8}>
                <Button className={'buttonContactMail'} type={'primary'} shape={'circle'} icon={<MailOutlined/>}
                        onClick={() => {
                            const link = getMailink(value);
                            window.open(link, '_self').focus();
                            return;
                        }}/>
                {value}
            </Space>
    }
    if (type === 'whatsapp') {
        button =
            <Space size={8}>
                <Button className={'buttonContactWhatsapp'} type={'primary'} shape={'circle'}
                        icon={<WhatsAppOutlined/>} onClick={() => {
                    const link = getWhatsappLink(value);
                    window.open(link, '_blank').focus();
                    return;
                }}/>
                {value}
            </Space>
    }
    if (type === 'telegram') {
        button =
            <Space size={8}>
                <Button className={'buttonContactTelegram'} type={'primary'} shape={'circle'}
                        icon={<img src={img} alt={'Telegram'} width={16} height={16}
                                   style={{display: "block", marginLeft: 6}}/>} onClick={() => {
                    const link = getTelegramLink(value);
                    window.open(link, '_blank').focus();
                    return;
                }}/>
                {data && data.chat &&
                    (data.chat.username
                    ?
                    <a href={`https://t.me/${data.chat.username}`}>{data.chat.username}</a>
                    :
                    <span>{data.chat.first_name} {data.chat.last_name}</span>)
                }
            </Space>
    }

    return button
}
