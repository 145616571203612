function getTextWidth(text, font) {
    // re-use canvas object for better performance
    const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
}

export default function textEllipsis(text, font, width) {
    let newText = text;
    let len = text.length;
    while (getTextWidth(newText, font) > width) {
        len--;
        newText = text.slice(0, len) + "...";
    }
    return newText;
}