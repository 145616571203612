import React, {useState} from 'react';
import {Button, Checkbox, DatePicker, Divider, Form, InputNumber} from 'antd';
import SelectGym from "../../components/Form/SelectGym";
import SelectClient from "../../components/Form/SelectClient";

const { RangePicker } = DatePicker;

export default function SubscriptionForm({data, onSubmit, loading, selectGymAndUser, actionName, actionIcon}) {

    const [form] = Form.useForm();

    const [unlimited, setUnlimited] = useState(data.unlimited);

    const onUnlimitedChange = (e) => {
        setUnlimited(e.target.checked)
    };

    const onFinish = (values) => {
        onSubmit(values);
    };

    return (

        <Form name="subscription"
              form={form}
              onFinish={onFinish}
              initialValues={data}
              layout={'vertical'}
              size={'large'}
              disabled={loading}
              scrollToFirstError
        >

            <Form.Item name={'user'} label={'Клиент'} required
                       rules={[{required: true, message: 'Заполните поле',},]}>
                <SelectClient disabled={!selectGymAndUser}/>
            </Form.Item>

            <Form.Item name={'gym'} label={'Зал'} required
                       rules={[{required: true, message: 'Заполните поле',},]}>
                <SelectGym disabled={!selectGymAndUser}/>
            </Form.Item>

            <Form.Item name={'range'} label={'Срок действия'}
                       rules={[{required: true, message: 'Заполните поле',},]}>
                <RangePicker
                    format={'DD MMM YYYY'}
                />
            </Form.Item>

            <Divider>Занятия</Divider>

            <Form.Item name={'unlimited'} valuePropName={'checked'}>
                <Checkbox onChange={onUnlimitedChange}>Безлимит</Checkbox>
            </Form.Item>

            <Form.Item name={'limit'} label={'Количество тренировок'} rules={[{required: !unlimited, message: 'Заполните поле',},]}>
                <InputNumber disabled={unlimited}/>
            </Form.Item>

            <Form.Item name={'visitCount'} label={'Использовано тренировок'} rules={[{required: true, message: 'Заполните поле',},]}>
                <InputNumber/>
            </Form.Item>

            <Divider>Оплата</Divider>

            <Form.Item name={'price'} label={'Стоимость, ₽'}>
                <InputNumber/>
            </Form.Item>

            <Form.Item name={'paid'} valuePropName={'checked'}>
                <Checkbox>Оплачено</Checkbox>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" icon={actionIcon} loading={loading}>
                    {actionName}
                </Button>
            </Form.Item>

        </Form>
    );

}