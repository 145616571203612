import React, {useState} from 'react';
import ParentForm from "./ParentForm";
import {gql, useMutation} from "@apollo/client";
import generatePassword from "../../../helpers/generatePassword";
import CrmModal from "../../../components/CrmModal";
import {Button} from 'antd';
import {useNavigate} from 'react-router-dom';
import {parent_info} from "../../../routes";
import copyToClipboard from "../../../helpers/copyToClipboard";
import {PlusOutlined} from "@ant-design/icons";

const CREATE_USER = gql`
    mutation users ($data: UsersPermissionsUserInput!) {
        createUsersPermissionsUser (data: $data) {
            data {
                id
            }
        }
    }
`;

const CREATE_PARENT = gql`
    mutation users ($data: ParentInput!) {
        createParent (data: $data) {
            data {
                id
            }
        }
    }
`;

export default function ParentNew({phone}) {

    const [createUser] = useMutation(CREATE_USER);
    const [createParent] = useMutation(CREATE_PARENT);

    const [onDone, setOnDone] = useState(false);
    const [loading, setLoading] = useState(false);
    const password = generatePassword();

    const [parentId, setParentId] = useState(null);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);

        const userData = {
            firstName: data.firstName,
            lastName: data.lastName,
            username: data.email,
            email: data.email,
            password: password,
            contacts: data.contacts,
            blocked: false,
            role: 7,
        }

        const res = await createUser({variables: {data: userData}});
        const userId = res.data.createUsersPermissionsUser.data.id;

        const parentData = {
            firstName: data.firstName,
            lastName: data.lastName,
            birthDate: data.birthDate,
            account: userId,
            gym: data.gym,
            enabled: true,
        }
        const res2 = await createParent({variables: {data: parentData}});
        const parentId = res2.data.createParent.data.id;

        setParentId(parentId);
        setOnDone(true);

    };

    function onFinish() {
        navigate(parent_info(parentId), {replace: true});
    }

    let data = {};
    if (phone) {
        data.contacts = [
            {
                type: 'phoneWhatsapp',
                value: phone,
            }
        ]
    }

    return (
        <>
            <ParentForm data={data} onSubmit={onSubmit} loading={loading} withEmail withGym actionName={'Добавить'} actionIcon={<PlusOutlined/>}/>
            <CrmModal isOpen={onDone} title={'Успешно'} actionName={'Хорошо'} onAction={() => onFinish()} onClose={() => onFinish()}>
                <div className={'modal-basic-form'}>
                    Создан новый клиент и ему назначен пароль:
                    <div className={'highlight'}>{password}</div>
                    <Button size={'large'} onClick={() => {copyToClipboard(password)}}>Копировавть</Button>
                    <p>Чтобы установвить новый пароль для входа в личный кабинет, обратитесь к Администратору</p>
                </div>
            </CrmModal>
        </>
    );
}
