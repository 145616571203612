function getRole() {
    // todo забирать из Store а не напрямую из локалстораджа
    const { auth } = JSON.parse(window.localStorage.getItem('crm-trainer-store'));
    return auth.role;
}

const RoleFunctions = {
    onTest: () => {
        console.log('role', getRole());
        return true;
    },

    //TRAINING

    notifyCalls: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Boss':
                return true;
            case 'Trainer':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

    posting: () => {
        switch (getRole()) {
            case 'Authenticated':
                return true;
            case 'Admin':
                return true;
            case 'Boss':
                return true;
            case 'Trainer':
                return false;
            case 'Parent':
                return false;
            case 'Child':
                return false;
            default:
                return false;
        }
    },

};

export default RoleFunctions;
