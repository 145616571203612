import React, {useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';

import CrmLayout from "../../../components/CrmLayout";

import {Button, Divider, Empty, message, Modal, Skeleton, Space, Tabs} from 'antd';
import {
    CopyOutlined,
    EnvironmentOutlined,
    ExclamationCircleOutlined,
    GiftOutlined,
    PlusOutlined,
} from '@ant-design/icons';

import {child_info, CLIENTS, NOT_FOUND, parent_edit, postingClients, subscription_new, task_new} from "../../../routes";
import CrmDisplayData from "../../../components/CrmDisplayData";

import moment from 'moment';
import {gql, useMutation, useQuery} from "@apollo/client";
import ClientMessage from "../ClientMessage";
import CrmCenteredTitle from "../../../components/CrmCenteredTitle";
import map from "lodash/map";
import CrmContact from "../../../components/Contact/CrmContact";
import CrmAvatar from "../../../components/CrmAvatar";
import CrmCard from "../../../components/CrmCard";
import ParentAddMessage from "./ParentAddMessage";
import ParentIndicator from "./ParentIndicator";
import ParentModalAddIndicator from "./ParentModalAddIndicator";
import ParentModalAddSkill from "./ParentModalAddSkill";
import ParentSkill from "./ParentSkill";
import ParentEditAnket from "./ParentEditAnket";
import ParentAccount from "./ParentAccount";
import ParentChildrenSelectAndAdd from "./ParentChildrenSelectAndAdd";
import ClientSubscriptions from "../ClientSubscriptions";
import ClientTasks from "../ClientTasks";
import copyToClipboard from "../../../helpers/copyToClipboard";
import BonusLayout from "../../../vidgets/bonus/BonusLayout";
import Bonus from "../Bonus/Bonus";
import IconAndText from "../../../vidgets/IconAndText";
import BonusLogo from "../../../vidgets/bonus/BonusLogo";
import PublicUrl from "../../../vidgets/client/PublicUrl";
import TelegramOutlined from "../../../vidgets/icon/TelegramOutlined";
import Graph from "../Graph/Graph";

const QUERY = gql`
    query query (
        $id: ID,
        $mesfilter: MessageFiltersInput,
    ) {
        parent(id: $id) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    birthDate
                    enabled
                    color
                    bonus
                    avatar {
                        data {
                            attributes {
                                previewUrl
                                url
                            }
                        }
                    }
                    clients (pagination: {limit: 200}) {
                        data {
                            id
                            attributes {
                                firstName
                                lastName
                                avatar {
                                    data {
                                        attributes {
                                            previewUrl
                                        }
                                    }
                                }
                            }
                        }
                    }
                    account {
                        data {
                            id
                            attributes {
                                email
                                blocked
                                publicKey
                                contacts {
                                    type
                                    value
                                    data
                                }
                            }
                        }
                    }
                    anket {
                        contraindications
                        experience
                        fullFamily
                        gadgetTime
                        mainGoal
                    }
                    enabled
                    indicators (pagination: {limit: 200}) {
                        id
                        name
                        startValue
                        goal
                        result (pagination: {limit: 200}) {
                            id
                            date
                            value
                        }
                    }
                    skills {
                        id
                        name
                        own
                    }
                    gym {
                        data {
                            id
                            attributes {
                                name
                                address
                                city {
                                    data {
                                        id
                                        attributes {
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                    messages (filters: $mesfilter) {
                        data {
                            id
                            attributes {
                                author {
                                    data {
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                        }
                                    }
                                }
                                message
                                fileUrl
                                date
                            }
                        }
                    }
                }
            }
        }
    }
`;

const DELETE = gql`
    mutation removeParent($id: ID!) {
        removeParent(id: $id) {
            done
        }
    }
`;

const {TabPane} = Tabs;

export default function ParentInfo() {

    const {id} = useParams();

    const navigate = useNavigate();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [addSkillModalOpen, setAddSkillModalOpen] = useState(false);

    const query = useQuery(QUERY, {
        variables: {
            id: id,
            mesfilter: {
                or: [{
                    enabled: {eq: true}
                }, {
                    enabled: {null: true}
                }]
            },
        },
        fetchPolicy: 'cache-and-network',
    });

    const [deleteMutation] = useMutation(DELETE);
    const onDelete = function () {
        Modal.confirm({
            title: 'Удалить',
            icon: <ExclamationCircleOutlined/>,
            content: `Действительно удалить клиента ${query.data.parent.data.attributes.firstName} ${query.data.parent.data.attributes.lastName}?`,
            okText: 'Да',
            cancelText: 'Нет',
            onOk() {
                onConfirmedDelete()
            }

        });
    };

    async function onConfirmedDelete() {
        await deleteMutation({
            variables: {
                id: id,
            },
            onCompleted: (data) => {
                if (data.removeParent.done) {
                    navigate(CLIENTS);
                } else {
                    message.error('Ошибка удаления карточки взрослого')
                }
            }
        });
    }

    let contacts = null;

    let indicators = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>;
    let skills = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>;
    let graph = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>;
    let messages = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>;
    let anket = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>;
    let children = null;
    let displayPublicUrl = null;

    if (query.data) {

        // если нет данных или enabled=false, то отправляем в 404
        if (!query.data.parent.data || query.data.parent.data.attributes.enabled === false) {
            navigate(NOT_FOUND, {replace: true});
            return null;
        }

        const parent = query.data.parent.data;
        const account =  parent.attributes.account.data;


        // Контакты
        if (query.data.parent.data.attributes.account.data.attributes.contacts.length > 0) {
            contacts = (
                <>
                    {/*<Divider>Контакты</Divider>*/}
                    <Space direction={'vertical'} size={10}>
                        {map(query.data.parent.data.attributes.account.data.attributes.contacts, (value, index) => {
                            return (
                                <CrmContact key={index} type={value.type} value={value.value} data={value.data}/>
                            )
                        })}
                    </Space>
                </>
            )
        }

        // Дети
        if (query.data.parent.data.attributes.clients) {
            children = (
                <Space direction={'vertical'} size={10} className={'block'}>
                    {query.data.parent.data.attributes.clients.data.map(function (value) {
                        return (
                            <CrmCard key={value.id}>
                                <Link to={child_info(value.id)}>
                                    <CrmAvatar
                                        size={'large'}
                                        child
                                        firstName={value.attributes.firstName}
                                        lastName={value.attributes.lastName}
                                        color={value.attributes.color}
                                        avatarUrl={value.attributes.avatar.data && value.attributes.avatar.data.attributes.previewUrl}
                                    />
                                </Link>
                            </CrmCard>
                        )
                    })}
                </Space>
            )
        }

        // Анкета
        if (query.data.parent.data.attributes.anket) {
            anket = (
                <div>
                    <Divider>Опыт занятий</Divider>
                    <div className={'crm-message-text'}>{query.data.parent.data.attributes.anket.experience}</div>

                    <Divider>Противопоказания</Divider>
                    <div
                        className={'crm-message-text'}>{query.data.parent.data.attributes.anket.contraindications}</div>

                    <Divider>Цель</Divider>
                    <div className={'crm-message-text'}>{query.data.parent.data.attributes.anket.mainGoal}</div>
                </div>
            )
        }

        // Цели
        if (query.data.parent.data.attributes.indicators.length > 0) {
            indicators = query.data.parent.data.attributes.indicators.map(function (value) {
                return (
                    <ParentIndicator
                        key={value.id}
                        indicator={value}
                        onUpdate={query.refetch}
                        id={id}
                        clientInfo={query.data.parent.data}
                    />
                )

            });
        }

        // Навыки
        if (query.data.parent.data.attributes.skills.length > 0) {
            skills = query.data.parent.data.attributes.skills.map(function (value) {
                return (
                    <ParentSkill
                        key={value.id}
                        skill={value}
                        onUpdate={query.refetch}
                        id={id}
                        clientInfo={query.data.parent.data}
                    />
                )

            });
        }

        // График
        let graphContacts = []
        // личные контакты дитя
        for (const contact of query.data.parent.data.attributes.account.data.attributes.contacts) {
            if (contact.data) {
                graphContacts.push({
                    ...contact,
                    data: {
                        firstName: contact.data.chat.first_name,
                        lastName: contact.data.chat.last_name,
                        username: contact.data.chat.username || contact.value,
                    }
                })
            } else {
                graphContacts.push({
                    ...contact,
                    data: {
                        firstName: query.data.parent.data.attributes.firstName,
                        lastName: query.data.parent.data.attributes.lastName,
                        username: null,
                    }
                })
            }
        }
        if (query.data.parent.data.attributes.indicators.length > 0 || query.data.parent.data.attributes.skills.length > 0) {
            graph = <Graph
                clientID={query.data.parent.data.id}
                firstName={query.data.parent.data.attributes.firstName}
                lastName={query.data.parent.data.attributes.lastName}
                indicators={query.data.parent.data.attributes.indicators}
                skills={query.data.parent.data.attributes.skills}
                contacts={graphContacts}
            />
        }

        // Комментарии
        messages = query.data.parent.data.attributes.messages.data.map(function (value, index) {
            return <ClientMessage
                key={index}
                message={value.attributes}
                id={value.id}
                onUpdate={query.refetch}
            />

        })


    }


    function showAddSkillModal() {
        setAddSkillModalOpen(true);
    }

    function closeAddSkillModal() {
        setAddSkillModalOpen(false);
    }

    function showModal() {
        setIsModalVisible(true);
    }

    function closeModal() {
        setIsModalVisible(false);
    }

    return (

        <CrmLayout>

            <CrmCenteredTitle
                title={query.data ? `${query.data.parent.data.attributes.firstName} ${query.data.parent.data.attributes.lastName}` : '—'}
                editAction={() => {
                    navigate(parent_edit(id));
                }}
                deleteAction={() => {
                    onDelete()
                }}
            />

            {!query.data && query.loading && <Skeleton active/>}

            {query.data &&
            <Space direction='vertical' size={16} className='block'>

                <CrmAvatar
                    size={'large'}
                    firstName={query.data.parent.data.attributes.firstName}
                    lastName={query.data.parent.data.attributes.lastName}
                    color={query.data.parent.data.attributes.color}
                    avatarUrl={query.data.parent.data.attributes.avatar.data && query.data.parent.data.attributes.avatar.data.attributes.previewUrl}
                />

                {query.data.parent.data.attributes.gym.data &&
                <IconAndText
                    icon={<EnvironmentOutlined/>}
                    value={query.data.parent.data.attributes.gym.data.attributes.name}
                />
                }

                {query.data.parent.data.attributes.birthDate &&
                <IconAndText
                    icon={<GiftOutlined/>}
                    value={moment(query.data.parent.data.attributes.birthDate).format('DD MMMM YYYY') + ', ' + moment().diff(query.data.parent.data.attributes.birthDate, 'years') + ' лет'}
                />
                }

                <BonusLayout
                    content={<BonusLogo/>}
                    fixed={<Bonus
                        id={id}
                        value={query.data.parent.data.attributes.bonus}
                        parent
                    />}
                />

                {contacts}

                <div>
                    <Divider>Дети</Divider>
                    {children}
                    <div className={'space'}/>
                    <div className={'crm-line'}>
                        <ParentChildrenSelectAndAdd parentId={id} children={query.data.parent.data.attributes.clients.data} onUpdate={query.refetch}/>
                        <Button size={'large'} icon={<TelegramOutlined/>} onClick={() => navigate(postingClients([query.data.parent.data.attributes.account.data.id]))}/>
                    </div>
                </div>

                <PublicUrl publicKey={query.data.parent.data.attributes.account.data.attributes.publicKey}/>

                <Tabs defaultActiveKey="1">

                    <TabPane key="subscriptions" tab={'Абонементы'}>
                        <ClientSubscriptions id={query.data.parent.data.attributes.account.data.id}/>
                        <div className={'space'}/>
                        <Button type='link' icon={<PlusOutlined/>}
                                onClick={() => navigate(subscription_new(query.data.parent.data.attributes.account.data.id))}>
                            Добавить абонемент
                        </Button>
                    </TabPane>

                    <TabPane key="anket" tab={'Анкета'}>
                        <Space direction='vertical' size={20} className='block'>
                            {anket}
                            <ParentEditAnket id={id} data={query.data.parent.data.attributes.anket}
                                             onUpdate={query.refetch}/>
                        </Space>
                    </TabPane>

                    <TabPane key="indicators" tab={'Цели'}>
                        <Space direction='vertical' size={20} className='block'>
                            {indicators}
                            <Button type={'link'} icon={<PlusOutlined/>} onClick={showModal}>Добавить цель</Button>
                            <ParentModalAddIndicator isOpen={isModalVisible} onClose={closeModal}
                                                     onUpdate={query.refetch} id={id}
                                                     clientInfo={query.data.parent.data}/>
                        </Space>
                    </TabPane>

                    <TabPane key="skills" tab={'Навыки'}>
                        <Space direction='vertical' size={20} className='block'>
                            {skills}
                            <Button type={'link'} icon={<PlusOutlined/>} onClick={showAddSkillModal}>Добавить
                                навык</Button>
                            <ParentModalAddSkill isOpen={addSkillModalOpen} onClose={closeAddSkillModal}
                                                 onUpdate={query.refetch} id={id} clientInfo={query.data.parent.data}/>
                        </Space>
                    </TabPane>

                    <TabPane key="graph" tab={'График'}>
                        {graph}
                    </TabPane>

                    <TabPane key="comments" tab={'Комментарии'}>
                        <Space direction='vertical' size={20} className='block'>
                            {messages}
                            <ParentAddMessage id={id} onUpdate={query.refetch}
                                              messages={query.data.parent.data.attributes.messages.data}/>
                        </Space>
                    </TabPane>

                    <TabPane key="tasks" tab={'Задачи'}>
                        <ClientTasks id={query.data.parent.data.attributes.account.data.id}/>
                        <div className={'space'}/>
                        <Button type='link' icon={<PlusOutlined/>}
                                onClick={() => navigate(task_new(moment().format('YYYY-MM-DD'), query.data.parent.data.attributes.account.data.id))}>
                            Добавить задачу
                        </Button>
                    </TabPane>

                    <TabPane key="account" tab={'Аккаунт'}>
                        <ParentAccount data={query.data.parent.data.attributes.account.data} onUpdate={query.refetch}/>
                    </TabPane>

                </Tabs>
            </Space>
            }
        </CrmLayout>
    );
}



