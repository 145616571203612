import React, {useState} from 'react';

import {gql, useQuery} from "@apollo/client";
import {Select} from "antd";

import ReportUsersGroup from "./ReportUsersGroup";
import {useNavigate} from "react-router-dom";
import {get_report} from "../../routes";

const GET_USERS = gql`
    query users (
        $filters: UsersPermissionsUserFiltersInput,
        $pagination: PaginationArg = {},
        $sort: [String] = []) {

        usersPermissionsUsers (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    color
                    blocked
                }
            }
        }
    }
`;

const {Option} = Select;

export default function ReportUsers({range, id}) {

    const type = 'users';
    const navigate = useNavigate();

    const {data, loading} = useQuery(GET_USERS, {
        variables: {
            filters: {
                and: [{
                    blocked: {ne: true}
                }, {
                    role: {id: {in: [3, 4, 5]}}
                }]
            },
            pagination: {
                limit: 200
            }
        }
    });

    const [selectedUser, setSelectedUser] = useState(id);

    let userOptions = null;
    let userGroups = null;

    if (data !== undefined) {
        userOptions = data.usersPermissionsUsers.data.map(function (value) {
            return (
                <Option value={value.id} key={value.id}>
                    {value.attributes.firstName} {value.attributes.lastName}
                </Option>
            )
        });
        userGroups = data.usersPermissionsUsers.data.map(function (value) {
            return (
                <div key={value.id} className={'crm-report-group'}>
                    <div className={'bold'}>{value.attributes.firstName} {value.attributes.lastName}</div>
                    <ReportUsersGroup
                        id={value.id}
                        range={range}
                    />
                </div>
            )
        });
    }

    function onUserChange(value) {
        setSelectedUser(value);
        navigate(get_report(type, value, range[0].format('YYYY-MM-DD'), range[1].format('YYYY-MM-DD')), {replace: true});
    }

    const displayUsers = selectedUser === '0' ?
        <>
            {userGroups}
            </>
        :
        <>
            <ReportUsersGroup
                id={selectedUser}
                range={range}
            />
        </>

    ;

    return (
        <>
            <div className={'crm-report-filter'}>
                <Select value={selectedUser} bordered={false} className={'block'} onChange={onUserChange} loading={loading} dropdownMatchSelectWidth={false}>
                    <Option value={'0'}>Все тренеры</Option>
                    {userOptions}
                </Select>
            </div>

            {displayUsers}

        </>
    );
}



