import moment from 'moment';

// INDEX
export const INDEX = '/';
export const CALENDAR = '/calendar/:date/:user';
export function calendar(date, user) {
    return `/calendar/${date}/${user}`;
}

// AUTH
export const LOGIN = '/login';
export const ABOUT = '/about';
export const LOGOUT = '/logout';
export const FORGET_PASSWORD = '/forget_password';

// TRAINING
export const TRAINING = '/training';
export const TRAINING_NEW = '/training/new/:date/:time';
export const TRAINING_INFO = '/training/:id';
export const TRAINING_EDIT = '/training/:id/edit';
export function training_info(id) {
    return TRAINING + '/' + id;
}
export function training_edit(id) {
    return TRAINING + '/' + id + '/edit';
}
export function training_new(date, time) {
    return TRAINING + '/new/' + date + '/' + time;
}

// MY PAGE - SETTINGS
export const SETTINGS = '/settings';

// CLIENT
export const CLIENTS = '/clients';
export const CLIENT_NEW = '/client/new/:phone';
export const CLIENT_INFO = '/client/:id';
export function client_info(id) {
    return `/client/${id}`;
}
export function client_new(phone = 0) {
    return `/client/new/${phone}`;
}

// CHILDREN
export const CHILD_INFO = '/child/:id';
export const CHILD_EDIT = '/child/:id/edit';
export function child_info(id) {
    return `/child/${id}`;
}
export function child_edit(id) {
    return `/child/${id}/edit`;
}

// PARENTS
export const PARENT_INFO = '/parent/:id';
export const PARENT_EDIT = '/parent/:id/edit';
export function parent_info(id) {
    return `/parent/${id}`;
}
export function parent_edit(id) {
    return `/parent/${id}/edit`;
}


// GYM
export const GYM = '/gym';
export const GYM_NEW = '/gym/new';
export const GYM_INFO = '/gym/:id';
export const GYM_EDIT = '/gym/:id/edit';
export function gym_info(id) {
    return GYM + '/' + id;
}
export function gym_edit(id) {
    return GYM + '/' + id + '/edit';
}

// USER
export const USER = '/user';
export const USER_NEW = '/user/new';
export const USER_INFO = '/user/:id';
export const USER_EDIT = '/user/:id/edit';
export function user_info(id) {
    return USER + '/' + id;
}
export function user_edit(id) {
    return USER + '/' + id + '/edit';
}

// SUBSCRIPTION
export const SUBSCRIPTION = '/subscription';
export const SUBSCRIPTION_NEW = '/subscription/new/:id';
export const SUBSCRIPTION_INFO = '/subscription/:id';
export const SUBSCRIPTION_EDIT = '/subscription/:id/edit';
export function subscription_new(id) {
    return SUBSCRIPTION + '/new/' + id;
}
export function subscription_info(id) {
    return SUBSCRIPTION + '/' + id;
}
export function subscription_edit(id) {
    return SUBSCRIPTION + '/' + id + '/edit';
}

// STATISTIC
export const STATISTIC = '/statistic';
export const STATISTIC_SUBSRIPTION = '/statistic/subscription';
export const STATISTIC_VISIT = '/statistic/visit';
export const STATISTIC_USER = '/statistic/user';

// MESSAGE
export const MESSAGE = '/message';
export const MESSAGE_NEW = '/message_new';

// TASK
export const TASK = '/task';
export const TASK_NEW = '/task/new/:date/:client';
export const TASK_INFO = '/task/:id';
export const TASK_EDIT = '/task/:id/edit';
export function task_info(id) {
    return TASK + '/' + id;
}
export function task_edit(id) {
    return TASK + '/' + id + '/edit';
}
export function task_new(date, client) {
    return `/task/new/${date}/${client}`;
}

// AUTOMATION
export const AUTOMATION = '/automation';
export const AUTOMATION_NEW = '/automation_new';
export const AUTOMATION_INFO = '/automation/:id';
export const AUTOMATION_EDIT = '/automation/:id/edit';
export function automation_info(id) {
    return AUTOMATION + '/' + id;
}
export function automation_edit(id) {
    return AUTOMATION + '/' + id + '/edit';
}

// CALL
export const CALL = '/call';

// TEST
export const TEST = '/test';

// 404
export const NOT_FOUND = '/404';

// PAYMENT
export const PAYMENT_OFFER = '/payment/offer/:public_key';
export const PAYMENT_SUCCESS = '/payment/success';
export const PAYMENT_ERROR = '/payment/error';

// PUBLIC
export const PUBLIC_CLIENT_INFO = '/client-info/:public_key';

// REPORT
export const REPORT = '/report/:type/:id/:startDate/:endDate';
export function default_report() {
    const type = 'gyms';
    const id = '0';
    const startDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');
    return `/report/${type}/${id}/${startDate}/${endDate}`;
}
export function get_report(type, id, startDate, endDate) {
    return `/report/${type}/${id}/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`;
}
export const REPORT_GYM_CLIENTS_TOTAL = '/report/gym/clientsTotal/:id/:startDate/:endDate';
export const REPORT_GYM_CLIENTS_WITH_SUBSCRIPTION = '/report/gym/clientsWithSubscription/:id/:startDate/:endDate';
export const REPORT_GYM_CLIENTS_WITHOUT_SUBSCRIPTION = '/report/gym/clientsWithoutSubscription/:id/:startDate/:endDate';
export const REPORT_GYM_CLIENTS_FIRST_SUBSCRIPTION = '/report/gym/clientsFirstSubscription/:id/:startDate/:endDate';
export const REPORT_GYM_CLIENTS_NOT_EXTEND = '/report/gym/clientsNotExtend/:id/:startDate/:endDate';
export const REPORT_GYM_TOTAL_MONEY = '/report/gym/totalMoney/:id/:startDate/:endDate';
// export const REPORT_GYM_CLIENTS_NEW = '/report/gym/clientsNew/:id/:startDate/:endDate';
// export const REPORT_GYM_CLIENTS_RECORDED = '/report/gym/clientsRecorded/:id/:startDate/:endDate';
// export const REPORT_GYM_CLIENTS_NOT_RECORDED = '/report/gym/clientsNotRecorded/:id/:startDate/:endDate';
// export const REPORT_GYM_CLIENTS_VISITED = '/report/gym/clientsVisited/:id/:startDate/:endDate';
// export const REPORT_GYM_CLIENTS_NOT_VISITED = '/report/gym/clientsNotVisited/:id/:startDate/:endDate';
// export const REPORT_GYM_TRAININGS_TOTAL = '/report/gym/trainingsTotal/:id/:startDate/:endDate';
// export const REPORT_GYM_VISITS = '/report/gym/visits/:id/:startDate/:endDate';
// export const REPORT_GYM_CLIENTS_WITHOUT_SUBSCRIPTION = '/report/gym/clientsWithoutSubscription/:id/:startDate/:endDate';
// export const REPORT_GYM_SUBSRIPTIONS_TOTAL = '/report/gym/subscriptionsTotal/:id/:startDate/:endDate';
// export const REPORT_GYM_SUBSRIPTIONS_FIRST = '/report/gym/subscriptionsFirst/:id/:startDate/:endDate';
// export const REPORT_GYM_SUBSRIPTIONS_PAID = '/report/gym/subscriptionsPaid/:id/:startDate/:endDate';
// export const REPORT_GYM_SUBSRIPTIONS_NOT_PAID = '/report/gym/subscriptionsNotPaid/:id/:startDate/:endDate';
export function get_report_gym(type, id, startDate, endDate) {
    return `/report/gym/${type}/${id}/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`;
}
export const REPORT_USER_TRAININGS_TOTAL = '/report/user/trainingsTotal/:id/:startDate/:endDate';
export const REPORT_USER_SUBSRIPTIONS_TOTAL = '/report/user/subscriptionsTotal/:id/:startDate/:endDate';
export const REPORT_USER_SUBSRIPTIONS_PAID = '/report/user/subscriptionsPaid/:id/:startDate/:endDate';
export const REPORT_USER_SUBSRIPTIONS_NOT_PAID = '/report/user/subscriptionsNotPaid/:id/:startDate/:endDate';
export function get_report_user(type, id, startDate, endDate) {
    return `/report/user/${type}/${id}/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`;
}


// POSTING
export const POSTINGS = '/postings';
export const POSTING = '/posting/:ids?';
export function postingDefault () {
    return `/posting`;
}
export function postingClients (array) {
    return `/posting/${JSON.stringify(array)}`;
}

export const POSTING_CLIENTS = '/posting/clients'
export const POSTING_MESSAGE = '/posting/message'
export const POSTING_SEND = '/posting/send'

// Provider
// WhatsApp
export const WHATS_APP = '/whatsapp'