import React, {useEffect, useState} from 'react';
import {Avatar, Button, Dropdown} from 'antd';
import {
    CalendarOutlined,
    FlagOutlined,
    GlobalOutlined,
    HeartOutlined,
    IdcardOutlined,
    LogoutOutlined,
    MenuOutlined,
    TableOutlined,
    ToolOutlined,
    UserOutlined,
} from '@ant-design/icons';
import logo from '../../images/sila-skilla-white-logo.svg';
import {Link} from 'react-router-dom';
import {
    AUTOMATION,
    CLIENTS,
    default_report,
    GYM,
    INDEX,
    LOGOUT,
    POSTINGS,
    SUBSCRIPTION,
    TASK,
    USER
} from "../../routes";
import {useInstance} from "react-ioc";
import {Store} from "../../model/store/Store";
import {observer} from "mobx-react-lite";
import RoleFunctions from "../../config/roles";
import cls from './header.module.scss';
import TelegramOutlined from "../../vidgets/icon/TelegramOutlined";

function CrmHeader(props) {

    const {auth} = useInstance(Store);

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    let firstName = '';
    let lastName = '';
    let color = null;

    if (auth.data !== null) {
        firstName = auth.data.firstName;
        lastName = auth.data.lastName;
        color = auth.data.color;

    }

    // const menu = (
    //     <Menu>
    //         <Menu.Item key={1}>
    //             <Link to={INDEX}><CalendarOutlined/> Тренировки</Link>
    //         </Menu.Item>
    //         <Menu.Item key={3}>
    //             <Link to={CLIENTS}><HeartOutlined/> Клиенты</Link>
    //         </Menu.Item>
    //         <Menu.Item key={4}>
    //             <Link to={SUBSCRIPTION}><IdcardOutlined/> Абонементы</Link>
    //         </Menu.Item>
    //
    //         {auth.role !== "Trainer" && (
    //             <>
    //                 <Menu.Item key={5}>
    //                     <Link to={TASK}><FlagOutlined/> Задачи</Link>
    //                 </Menu.Item>
    //                 <Menu.Item key={6}>
    //                     <Link to={AUTOMATION}><ToolOutlined/> Настройки задач</Link>
    //                 </Menu.Item>
    //             </>
    //         )}
    //
    //         <Menu.Item key={7}>
    //             <Link to={USER}><UserOutlined/> Тренеры</Link>
    //         </Menu.Item>
    //         <Menu.Item key={8}>
    //             <Link to={GYM}><GlobalOutlined/> Залы</Link>
    //         </Menu.Item>
    //         {auth.role !== "Trainer" && (
    //             <Menu.Item key={9}>
    //                 <Link to={default_report()}><TableOutlined/> Отчеты</Link>
    //             </Menu.Item>
    //         )}
    //
    //         {RoleFunctions.posting() &&
    //         <Menu.Item key={'posting'}>
    //             <Link to={POSTINGS}><WhatsAppOutlined/> Рассылки</Link>
    //         </Menu.Item>
    //         }
    //
    //         {/*<Menu.Item key={8} disabled>*/}
    //         {/*<Link to={CALL}><PhoneOutlined/> История звонков</Link>*/}
    //         {/*</Menu.Item>*/}
    //         {/*<Menu.Item key={9} disabled>*/}
    //         {/*<Link to={SETTINGS}><SettingOutlined/> Мои настройки</Link>*/}
    //         {/*</Menu.Item>*/}
    //     </Menu>
    // );

    const items = [
        {
            key: INDEX,
            label: <Link to={INDEX}><CalendarOutlined/> Тренировки</Link>
        },
        {
            key: CLIENTS,
            label: <Link to={CLIENTS}><HeartOutlined/> Клиенты</Link>
        },
        {
            key: INDEX,
            label: <Link to={SUBSCRIPTION}><IdcardOutlined/> Абонементы</Link>
        },
        auth.role !== 'Trainer' && {
            key: 'TASK',
            label: <Link to={TASK}><FlagOutlined/> Задачи</Link>
        },
        auth.role !== 'Trainer' && {
            key: 'AUTOMATION',
            label: <Link to={AUTOMATION}><ToolOutlined/> Настройки задач</Link>
        },
        {
            key: USER,
            label: <Link to={USER}><UserOutlined/> Тренеры</Link>
        },
        {
            key: GYM,
            label: <Link to={GYM}><GlobalOutlined/> Залы</Link>
        },
        auth.role !== 'Trainer' && {
            key: 'report',
            label: <Link to={default_report()}><TableOutlined/> Отчеты</Link>
        },
        RoleFunctions.posting() && {
            key: POSTINGS,
            label: <Link to={POSTINGS}><TelegramOutlined/> Рассылки</Link>
        },
    ]

    const userItems = [
        // {
        //     key: SETTINGS,
        //     label: <Link to={SETTINGS}><SettingOutlined/> Настройки</Link>
        // },
        {
            key: LOGOUT,
            label: <Link to={LOGOUT}><LogoutOutlined/> Выйти</Link>
        },
    ]

    // const userMenu = (
    //     <Menu>
    //         <Menu.Item key={10}>
    //             <Link to={SETTINGS}><SettingOutlined/> Настройки</Link>
    //         </Menu.Item>
    //         <Menu.Item key={11}>
    //             <Link to={LOGOUT}><LogoutOutlined/> Выйти</Link>
    //         </Menu.Item>
    //     </Menu>
    // );

    return (

        <header className={cls.header}>
            <div className={cls.content}>

                <div className={cls.left}>
                    <Dropdown menu={{items}} trigger="click">
                        <Button type={'text'} icon={<MenuOutlined/>} className={cls.menuButton}/>
                    </Dropdown>
                    <Link to={INDEX}>
                        <img src={logo} className={cls.logo} alt="logo"/>
                    </Link>
                </div>

                {props.children}

                <div className={cls.right}>
                    {windowSize[0] > 478 && <span className={cls.name}>{firstName} {lastName}</span>}
                    <Dropdown menu={{items: userItems}} trigger="click">
                        <Avatar size='small'
                                className={cls.avatar}
                                style={{backgroundColor: color}}>{firstName.charAt(0)}{lastName.charAt(0)}</Avatar>
                    </Dropdown>
                </div>
            </div>
        </header>
    );
}

export default observer(CrmHeader);
