import React from 'react';

import CrmLayout from "../../components/CrmLayout";

import GymListRow from './GymListRow';

import {message, Skeleton} from 'antd';

import {gql, useQuery} from '@apollo/client';
import CrmCenteredTitle from "../../components/CrmCenteredTitle";

import cls from '../../styles/list.module.scss';

const GET_GYM = gql`
    query gyms ($filters: GymFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        gyms (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    name
                    address
                    enabled
                    color
                    city {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default function Gym() {

    const { loading, error, data } = useQuery(GET_GYM, {
        variables: {
            pagination: {
                limit: 200
            }
        }
    });

    let items = '';

    if (data && !error) {
        items = data.gyms.data.map(function (gym) {

            let addressLine = '';

            if (gym.attributes.city.data) {
                addressLine = gym.attributes.city.data.attributes.name + ', ' + gym.attributes.address
            } else {
                addressLine = gym.attributes.address;
            }

            return (
                <GymListRow
                    key={gym.id}
                    id={gym.id}
                    name={gym.attributes.name}
                    addressLine={addressLine}
                    enabled={gym.attributes.enabled}
                    color={gym.attributes.color}
                />
            );
        });
    }

    return (

        <CrmLayout>

            <CrmCenteredTitle title={'Залы'}/>

            {error ? message.error(error.message) : null}

            {loading && <Skeleton active/>}

            <div className={cls.list}>
                {items}
            </div>

        </CrmLayout>
    );
}



