import React from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';

import CrmLayout from "../../components/CrmLayout";

import {gql, useMutation, useQuery} from "@apollo/client";
import {Button, Divider, Input, message, Modal, Space, Tooltip} from "antd";
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    CopyOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import {client_info, INDEX, NOT_FOUND, subscription_edit} from "../../routes";
import moment from "moment";
import CrmDisplayData from "../../components/CrmDisplayData";
import SubscriptionStatus from "../../components/SubscriptionStatus";
import CrmCenteredTitle from "../../components/CrmCenteredTitle";
import map from 'lodash/map';
import CrmWindowSpin from "../../components/CrmWindowSpin";
import copyToClipboard from "../../helpers/copyToClipboard";
import CrmAvatar from "../../components/CrmAvatar";

const QUERY_SUBSCRIPTION = gql`
    query subscription ($id: ID, $filters: SubscriptionPaymentFiltersInput) {
        subscription(id: $id) {
            data {
                id
                attributes {
                    status
                    startDate
                    endDate
                    unlimited
                    limit
                    visitCount
                    price
                    paid
                    publicKey
                    user {
                        data {
                            id
                            attributes {
                                role {
                                    data {
                                        id
                                        attributes {
                                            name
                                        }
                                    }
                                }
                                client {
                                    data {
                                        id
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                            avatar {
                                                data {
                                                    attributes {
                                                        previewUrl
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                parent {
                                    data {
                                        id
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                            avatar {
                                                data {
                                                    attributes {
                                                        previewUrl
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    gym {
                        data {
                            id
                            attributes {
                                name
                                city {
                                    data {
                                        attributes {
                                            name
                                        }
                                    }
                                }
                                address
                            }
                        }
                    }
                }
            }
        }
        subscriptionPayments(filters: $filters) {
            data {
                id
                attributes {
                    amount
                    paymentLink
                    status
                    paid
                    createdAt
                }
            }
        }
    }
`;

const DELETE_SUBSCRIPTION = gql`
    mutation deleteSubscription($id: ID!) {
        deleteSubscription(id: $id) {
            data {
                id
            }
        }
    }
`;

export default function SubscriptionInfo() {

    const {id} = useParams();

    const navigate = useNavigate();

    const query = useQuery(QUERY_SUBSCRIPTION, {
        variables: {
            id: id,
            filters: {
                subscription: {
                    id: {
                        eq: id
                    }
                }
            }
        },
        fetchPolicy: "network-only"
    });

    const [deleteSubscriptionMutation] = useMutation(DELETE_SUBSCRIPTION);


    let displayName = null;
    let displayClient = null;
    let displayGym = null;
    let displayPrice = null;
    let displayPayment = null;

    if (query.data !== undefined) {
        if (query.data.subscription.data !== null) {

            displayName = `Абонемент №${query.data.subscription.data.id}`;

            const user = query.data.subscription.data.attributes.user.data;

            if (user) {

                const roleName = user.attributes.role.data.attributes.name;

                let person = null;
                let child = false;

                if (roleName === 'Child') {
                    person = user.attributes.client.data;
                    child = true;
                }

                if (roleName === 'Parent') {
                    person = user.attributes.parent.data;
                }


                const firstName = person.attributes.firstName;
                const lastName = person.attributes.lastName;
                const avatar = person.attributes.avatar.data;

                let avatarUrl = null;

                if (avatar) {
                    avatarUrl = avatar.attributes.previewUrl;
                }

                const color = person.attributes.color;

                displayClient =
                    <CrmDisplayData
                        label='Клиент'
                        value={
                            <Link to={client_info(user.id)}>
                                <CrmAvatar
                                    size={'large'}
                                    firstName={firstName}
                                    lastName={lastName}
                                    color={color}
                                    avatarUrl={avatarUrl}
                                    child={child}
                                />
                            </Link>

                        }
                    />
            }

            if (query.data.subscription.data.attributes.gym.data !== null) {
                let cityName = '';
                if (query.data.subscription.data.attributes.gym.data.attributes.city.data !== null) {
                    cityName = query.data.subscription.data.attributes.gym.data.attributes.city.data.attributes.name;
                }
                displayGym =
                    <CrmDisplayData label='Зал'
                                    value={query.data.subscription.data.attributes.gym.data.attributes.name}>
                        <span>{cityName}, {query.data.subscription.data.attributes.gym.data.attributes.address}</span>
                    </CrmDisplayData>
            }


            displayPayment = map(query.data.subscriptionPayments.data, (value, index) => {

                let color = null;
                let icon = null;

                const date = moment(value.attributes.createdAt).format('DD.MM.YYYY HH:MM');

                if (value.attributes.status === 0) {
                    color = 'color-gray-3';
                    icon = <ClockCircleOutlined/>;
                }

                if (value.attributes.status === 1) {
                    color = 'color-warning';
                    icon = <ClockCircleOutlined/>;
                }

                if (value.attributes.status === 6 || value.attributes.status === 3 || value.attributes.status === 4) {
                    color = 'color-danger';
                    icon = <CloseCircleOutlined/>;
                }

                if (value.attributes.status === 2 || value.attributes.paid) {
                    color = 'color-success';
                    icon = <CheckCircleOutlined/>;
                }

                return (
                    <div key={index} className={'crm-payment-line'}>
                        <span className={color}>{icon}</span>
                        <span className={'crm-payment-line-date'}>{date}</span>
                        <span className={'crm-payment-line-link'}>{value.attributes.paymentLink}</span>
                    </div>
                )
            });

            if (query.data.subscription.data.attributes.price !== null) {

                const url = `https://sila-crm.ru/payment/offer/${query.data.subscription.data.attributes.publicKey}`;

                displayPrice =
                    <>
                        <Divider>Оплата</Divider>

                        <CrmDisplayData
                            label={'Стоимость'}
                            value={`${query.data.subscription.data.attributes.price} ₽`}
                        />

                        <Input.Group compact>
                            <Input
                                size={'large'}
                                value={url}
                                style={{width: 'calc(100% - 40px)'}}
                            />
                            <Tooltip title="Скопировать ссылку">
                                <Button size={'large'} icon={<CopyOutlined/>} onClick={() => {
                                    copyToClipboard(url);
                                    message.success('Ссылка скопирована');
                                }}/>
                            </Tooltip>
                        </Input.Group>

                        <span className={'caption'}>Попытки оплаты</span>

                        {displayPayment}

                    </>
            }

        } else {
            navigate(NOT_FOUND, {replace: true});
            return null;
        }

    }

    function onDelete() {
        Modal.confirm({
            title: 'Удалить',
            icon: <ExclamationCircleOutlined/>,
            content: 'Действительно удалить абонемент?',
            okText: 'Да',
            cancelText: 'Нет',
            onOk() {onConfirmedDelete()}
        });
    }

    async function onConfirmedDelete () {
        await deleteSubscriptionMutation({
            variables: {
                id: id
            },
            onCompleted: (res) => {
                if (res) {
                    navigate(INDEX, {replace: true});
                }
            },
            onError: (error) => {
                message.error(error.message)
                console.error(error)
            }
        })
    }

    return (

        <CrmLayout>

            <CrmCenteredTitle
                title={displayName}
                editAction={() => {
                    navigate(subscription_edit(id))
                }}
                deleteAction={() => {
                    onDelete()
                }}
            />

            {query.loading && <CrmWindowSpin spinning/>}

            {query.data &&

            <Space direction='vertical' size={20} className='block'>

                {displayClient}

                {displayGym}

                <SubscriptionStatus
                    type={'date'}
                    startDate={query.data.subscription.data.attributes.startDate}
                    endDate={query.data.subscription.data.attributes.endDate}
                />

                <SubscriptionStatus
                    type={'limit'}
                    unlimited={query.data.subscription.data.attributes.unlimited}
                    limit={query.data.subscription.data.attributes.limit}
                    visitCount={query.data.subscription.data.attributes.visitCount}
                />

                <SubscriptionStatus
                    type={'payment'}
                    paid={query.data.subscription.data.attributes.paid}
                />

                {displayPrice}

                {/*<AddSubscriptionPayment id={id} onUpdate={() => query.refetch()}/>*/}

            </Space>
            }

        </CrmLayout>
    );
}



