import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';

import {gql, useMutation, useQuery} from "@apollo/client";

import {useInstance} from "react-ioc";
import {observer} from "mobx-react-lite";
import {Store} from "../../model/store/Store";

import {Button, DatePicker, Select} from 'antd';
import {CheckCircleOutlined, CopyOutlined, LeftOutlined, RightOutlined} from '@ant-design/icons';

import moment from 'moment';
import dayjs from 'dayjs';

import {calendar, INDEX} from "../../routes";

import CalendarGrid from './CalendarGrid';
import CrmModal from "../../components/CrmModal";
import CrmHeader from "../../components/Header/CrmHeader";
import CrmAvatar from "../../components/CrmAvatar";

const GET_TRAINIG = gql`
    query trainings (

        $trainingsFilter: TrainingFiltersInput,
        $trainingsPagination: PaginationArg,

        $tasksFilter: TaskFiltersInput,
        $tasksPagination: PaginationArg,

        $usersFilter: UsersPermissionsUserFiltersInput,
        $usersPagination: PaginationArg,
        $usersSort: [String] = [],

    ) {
        trainings (filters: $trainingsFilter, pagination: $trainingsPagination) {
            data {
                id
                attributes {
                    startDate
                    endDate
                    name
                    comment
                    contentUrl
                    trainers {
                        data {
                            id
                            attributes {
                                firstName
                                lastName
                                color
                            }
                        }
                    }
                    gym {
                        data {
                            id
                            attributes {
                                name
                                color
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
        tasks (filters: $tasksFilter, pagination: $tasksPagination) {
            data {
                id
                attributes {
                    name
                    comment
                    date
                    done
                    executor {
                        data {
                            id
                            attributes {
                                trainer {
                                    data {
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
        usersPermissionsUsers (filters: $usersFilter, pagination: $usersPagination, sort: $usersSort) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    color
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

const COPY_TRAINIG = gql`
    mutation copyTraining($startDate: String!, $endDate: String!, $newStartDate: String!) {
        copyTraining(startDate: $startDate, endDate: $endDate, newStartDate: $newStartDate) {
            done
        }
    }
`;

const {Option} = Select;

function title(str) {
    return str.replace(/(^|\s)\S/g, function (t) {
        return t.toUpperCase()
    });
}

function getTrainingFilter(filter, date) {

    const startDate = moment(date);
    const endDate = moment(date).add(7, 'days');

    if (filter === 'all') {
        return {
            and: [{
                or: [{
                    enabled: {eq: true}
                }, {
                    enabled: {null: true}
                }]
            }, {
                startDate: {
                    between: [startDate.format(), endDate.format()]
                }
            }]
        }
    } else {
        return {
            and: [{
                trainers: {id: {eq: filter}}
            }, {
                and: [{
                    or: [{
                        enabled: {eq: true}
                    }, {
                        enabled: {null: true}
                    }]
                }, {
                    startDate: {
                        between: [startDate.format(), endDate.format()]
                    }
                }]
            }]
        }
    }
}

function getTaskFilter(filter, date) {

    const startDate = moment(date);
    const endDate = moment(date).add(7, 'days');

    if (filter === 'all') {
        return {
            date: {
                between: [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')]
            }
        }
    } else {
        return {
            and: [{
                executor: {id: {eq: filter}}
            }, {
                date: {
                    between: [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')]
                }
            }]
        }
    }
}

function Calendar() {

    const {date, user} = useParams();

    const {auth} = useInstance(Store);

    const navigate = useNavigate();

    // листание вперед
    function addWeek() {
        navigate(calendar(moment(date).add(7, 'days').format('YYYY-MM-DD'), user));
    }
    // листание назад
    function substractWeek() {
        navigate(calendar(moment(date).subtract(7, 'days').format('YYYY-MM-DD'), user));
    }

    // копирование тренировки
    const [modalOpened, setModalOpened] = useState(false);
    const [inCopy, setInCopy] = useState(false);
    const [inLoadingCopy, setInLoadingCopy] = useState(false);
    const [inCopyDone, setInCopyDone] = useState(false);
    const [newStartDate, setNewStartDate] = useState(dayjs().startOf('week'));

    function showModal() {
        setModalOpened(true)
    }

    function closeModal() {
        if (!inLoadingCopy) {
            setModalOpened(false);
            setInCopy(false);
        }
    }

    async function copyTraining() {
        setInCopy(true);
        setInLoadingCopy(true);
        const res = await copyTrainingMutation({
            variables: {
                startDate: moment(date).format('YYYY-MM-DD'),
                endDate: moment(date).add(7, 'days').format('YYYY-MM-DD'),
                newStartDate: dayjs(newStartDate).startOf('week').format('YYYY-MM-DD'),
            }
        });
        const done = res.data.copyTraining.done;
        if (done) {
            setInLoadingCopy(false);
            setInCopyDone(true);
        }
    }

    const [copyTrainingMutation] = useMutation(COPY_TRAINIG);

    // фильтр по тренерам
    // Для роли Админ и "Прогер" по умолчанию - все
    function onChangeUser(value) {
        navigate(calendar(date, value));
    }

    // Доступность кнопки "Копировать на неделю"
    // для всех, кроме роли "Тренер"
    let displayCopy = '';

    if (auth.role !== null) {
        if (auth.role !== "Trainer") {
            displayCopy =
                <Button size={'small'} shape={'circle'} onClick={showModal}>
                    <CopyOutlined/>
                </Button>;
        }
    }

    // Главный запрос
    const {data, loading, refetch} = useQuery(GET_TRAINIG, {
        variables: {

            trainingsFilter: getTrainingFilter(user, date),
            trainingsPagination: {limit: 10000},

            tasksFilter: getTaskFilter(user, date),
            tasksPagination: {limit: 10000},

            usersFilter: {role: {or: [{name: {eq: 'Trainer'}}, {name: {eq: 'Boss'}}]}, blocked: {ne: true}},
            usersPagination: {limit: 10000},
            usersSort: 'lastName:ASC',
        },
        fetchPolicy: 'network-only'
    });

    // console.log('data', data);

    // Фильтр по тренерам продолжение
    let users = [];
    if (data !== undefined) {
        users = data.usersPermissionsUsers.data;
    }
    const usersOptions = users.map(function (value) {
        return (
            <Option key={value.id} value={value.id}><CrmAvatar size='small' type='user'
                                                               firstName={value.attributes.firstName}
                                                               lastName={value.attributes.lastName}
                                                               color={value.attributes.color}/></Option>
        )
    });

    // обновлвение данных при смене фильтра
    useEffect(() => {
        refetch();
    }, [date, user, refetch]);

    // готовим переменные
    let trainings = [];
    if (data !== undefined && data.trainings !== null) {
        trainings = data.trainings.data;
    }
    let tasks = [];
    if (data !== undefined && data.tasks !== null) {
        tasks = data.tasks.data;
    }

    // рендер
    return (
        <>
            <CrmHeader>
                <Select
                    filterOption={false}
                    placeholder="Выберите тренера"
                    style={{width: '200px'}}
                    value={user}
                    loading={loading}
                    onChange={(value) => onChangeUser(value)}
                    dropdownMatchSelectWidth={false}
                >
                    <Option value={'all'}>Все тренеры</Option>
                    {usersOptions}
                </Select>
            </CrmHeader>

            <div>
                <div className={'crm-calendar-header crm-layout'}>

                    <div className={'crm-calendar-header-left'}>
                        <Link to={INDEX}>
                            <h3>
                                <b>{title(moment(date).add(4, 'days').format('MMMM'))}</b> {moment(date).add(4, 'days').format('YYYY')}
                            </h3>
                        </Link>
                    </div>

                    <div className={'crm-calendar-header-right'}>
                        {displayCopy}
                        <Button shape={'circle'} icon={<LeftOutlined/>} onClick={substractWeek}/>
                        <Button shape={'circle'} icon={<RightOutlined/>} onClick={addWeek}/>
                    </div>

                </div>

                <CrmModal isOpen={modalOpened}
                          title={'Скопировать расписание'}
                          onClose={closeModal}
                          onAction={copyTraining}
                          loading={inLoadingCopy}
                          disabled={inCopy}
                          actionName={'Скопировать'}>
                    <div className={'modal-basic-form'}>
                        <p className={'bold'}>Будет скопировано расписание на указанную дату</p>
                        <DatePicker value={newStartDate} onChange={(value) => setNewStartDate(value)} picker="week" allowClear={false}/>
                        <p>Мы сохраним информацию по времени, тренировкам и записанным клиентам.</p>
                        {inCopyDone &&
                        <p className={'bold color-success'}><CheckCircleOutlined/> Копирование завершено успешно!</p>}
                    </div>
                </CrmModal>

                <CalendarGrid loading={loading} trainings={trainings} tasks={tasks} startWeek={date}/>

            </div>
        </>
    );
}

export default observer(Calendar);