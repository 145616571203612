import React, {useState} from 'react';
import {gql, useQuery} from '@apollo/client';
import {Select, Spin} from 'antd';
import _ from 'lodash';

const QUERY = gql`
    query users($filters: UsersPermissionsUserFiltersInput, $pagination: PaginationArg, $sort: [String] = []) {
        usersPermissionsUsers(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    trainer {
                        data {
                            id
                            attributes {
                                firstName
                                lastName
                                color
                                avatar {
                                    data {
                                        attributes {
                                            previewUrl
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

const { Option } = Select;

function getFilter(id, searchText) {
    if (searchText !== '') {
        return {
            or: [
                {
                    firstName: {
                        contains: searchText,
                    },
                },
                {
                    lastName: {
                        contains: searchText,
                    },
                },
            ],
        };
    } else {
        if (id === '0') {
            return {};
        } else {
            return {
                id: {
                    in: [id],
                },
            };
        }
    }
}

export default function SelectTrainer({ initialValue = 'Зал не выбран', emptyValue = '', ...field }) {
    // Search
    const [searchText, setSearchText] = useState('');
    const onSearch = (value) => {
        setSearchText(value);
    };

    // Query
    const query = useQuery(QUERY, {
        variables: {
            filters: {
                role: { id: { in: [3, 4, 5] } },
                trainer: {
                    or: [{ firstName: { contains: searchText } }, { lastName: { contains: searchText } }],
                },
                blocked: { ne: true },
            },
            pagination: {
                limit: 200,
            },
            sort: ['trainer.lastName:ASC'],
        },
    });

    // Render
    return (
        <Select
            {...field}
            filterOption={false}
            notFoundContent={query.loading ? <Spin size="small" /> : null}
            placeholder={initialValue}
            showSearch
            onSearch={onSearch}
            loading={query.loading}
            dropdownMatchSelectWidth={false}
        >
            <Option key={0} value={null}>
                {initialValue}
            </Option>
            {emptyValue && (
                <Option key={'empty'} value={'empty'}>
                    {emptyValue}
                </Option>
            )}
            {query.data &&
                _.map(query.data.usersPermissionsUsers.data, (value) => {
                    return (
                        <Option key={value.id} value={value.id}>
                            {value.attributes.trainer.data.attributes.firstName}{' '}
                            {value.attributes.trainer.data.attributes.lastName}
                        </Option>
                    );
                })}
        </Select>
    );
}