import React from 'react';
import {Space} from 'antd';


export default function IconAndText({icon, value}) {
    return (
            <Space size={8}>
                <span className='color-gray-3'>{icon}</span>
                <span>{value}</span>
            </Space>
    );
}
