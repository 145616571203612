import React, {useState} from 'react';
import {gql, useQuery} from "@apollo/client";
import {Select, Spin} from "antd";
import _ from "lodash";

const QUERY = gql`
    query users (
        $filters: UsersPermissionsUserFiltersInput,
        $pagination: PaginationArg,
        $sort: [String] = []
    ) {
        usersPermissionsUsers (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    username
                    firstName
                    lastName
                    role {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                    client {
                        data {
                            id
                            attributes {
                                firstName
                                lastName
                                color
                                avatar {
                                    data {
                                        attributes {
                                            previewUrl
                                            url
                                        }
                                    }
                                }
                                gym {
                                    data {
                                        attributes {
                                            name
                                            address
                                        }
                                    }
                                }
                            }
                        }
                    }
                    parent {
                        data {
                            id
                            attributes {
                                firstName
                                lastName
                                color
                                birthDate
                                avatar {
                                    data {
                                        attributes {
                                            previewUrl
                                            url
                                        }
                                    }
                                }
                                gym {
                                    data {
                                        attributes {
                                            name
                                            address
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

const {Option} = Select;

function getFilter(id, searchText) {
    if (searchText !== '') {
        return {
            role: {id: {in: [6, 7]}},
            or: [{
                client: {
                    or: [{firstName: {contains: searchText}}, {lastName: {contains: searchText}}]
                }
            }, {
                parent: {
                    or: [{firstName: {contains: searchText}}, {lastName: {contains: searchText}}]
                }
            }],
        }
    } else {
        if (id === '0') {
            return {
                role: {id: {in: [6, 7]}},
            }
        } else {
            return {
                id: {
                    in: [id]
                }
            }
        }
    }
}

export default function SelectClient(field) {

    // Search
    const [searchText, setSearchText] = useState('');
    const onSearch = (value) => {
        setSearchText(value);
    };

    // Query
    const query = useQuery(QUERY, {
        variables: {
            filters: getFilter(field.value, searchText),
            pagination: {
                limit: 200
            },
            sort: ['lastName:ASC', 'firstName:ASC'],
        }});

    // Render
    return (
        <Select
            {...field}
            filterOption={false}
            notFoundContent={query.loading ? <Spin size="small"/> : null}
            placeholder="Выберите клиента"
            showSearch
            onSearch={onSearch}
            loading={query.loading}
            dropdownMatchSelectWidth={false}
        >
            <Option key={0} value={null}>Клиент не выбран</Option>
            {query.data && _.map(query.data.usersPermissionsUsers.data, (value) => {

                const roleName = value.attributes.role.data.attributes.name;

                let person = null;
                let child = false;

                if (roleName === 'Child') {
                    person = value.attributes.client.data;
                    child = true;
                }

                if (roleName === 'Parent') {
                    person = value.attributes.parent.data;
                }


                const firstName = person.attributes.firstName;
                const lastName = person.attributes.lastName;
                const avatar = person.attributes.avatar.data;
                const color = person.attributes.color;

                return (
                    <Option key={value.id} value={value.id}>
                        {child && '👦'} {firstName} {lastName}
                    </Option>
                )
            })}
        </Select>
    )

}