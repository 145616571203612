import React from 'react';
import cls from './bonus.module.scss';

import {Button} from 'antd';
import {MinusOutlined, PlusOutlined} from '@ant-design/icons';

import cn from 'classnames';

export default function BonusVidget({value, onPlus, onMinus, updated}) {
    return (
        <div className={cls.bonusVidget}>
            <div className={cls.bonusVidgetMinus}>
                <Button icon={<MinusOutlined/>} type={'link'} onClick={onMinus} className={cls.button}/>
            </div>
            <div className={cn(['bonus-vidget-value', 'bold', updated && 'bonus-vidget-value-updated'])}>
                {value}
            </div>
            <div className={cls.bonusVidgetPlus}>
                <Button icon={<PlusOutlined/>} type={'link'} onClick={onPlus} className={cls.button}/>
            </div>
        </div>
    );
}
