import React from 'react';
import moment from "moment";
import {CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, StarOutlined} from '@ant-design/icons';

export default function SubscriptionStatus(props) {

    const {type, size, startDate, endDate, unlimited, limit, visitCount, paid} = props;

    let icon = undefined;
    let text = '';
    let color = '';

    if (type === 'date') {

        icon = <ClockCircleOutlined/>;

        const mStartDate = moment(startDate).startOf('day');
        const mEndDate = moment(endDate).startOf('day');

        const today = moment().startOf('day');

        if (mStartDate.diff(today, 'days') > 0) {
            color = 'color-gray-3';
            text = `Начнется с ${mStartDate.format('DD MMM YYYY')}`;
        }

        if (moment().isBetween(mStartDate, mEndDate, 'days', '[]')) {
            color = 'color-success';
            text = `Действует до ${mEndDate.format('DD MMM YYYY')}`;
        }

        if (mEndDate.diff(today, 'days') <= 5) {
            color = 'color-warning';
            text = `Действует до ${mEndDate.format('DD MMM YYYY')}`;
        }

        if (mEndDate.diff(today, 'days') < 0) {
            color = 'color-danger';
            text = `Закончился ${mEndDate.format('DD MMM YYYY')}`;
        }

    }

    if (type === 'limit') {

        icon = <StarOutlined/>;

        if (unlimited) {
            color = 'color-success';
            text = `Безлимит`;
        } else {
            text = `${visitCount}/${limit} тренировок`;
            color = 'color-success';
            if (visitCount / limit > 0.8) {
                color = 'color-warning';
            }
            if (visitCount >= limit) {
                color = 'color-danger';
            }
        }

    }

    if (type === 'payment') {

        if (paid) {
            color = 'color-success';
            text = `Оплачен`;
            icon = <CheckCircleOutlined/>;
        } else {
            color = 'color-danger';
            text = `Не оплачен`;
            icon = <CloseCircleOutlined/>
        }

    }



    const small = (size === 'small');

    return (
        <div className={small ? 'caption' : 'body'}>
            <span className={color} style={{marginRight: (small ? 5 : 8)}}>{icon}</span>
            {text}
        </div>
    );
}