import React from 'react';
import {Space} from 'antd';
import CrmAvatar from "../../../components/CrmAvatar";
import moment from 'moment';
import {defaultStyles, FileIcon} from 'react-file-icon';

export default function Message ({message}) {

    return (
        <div className='public-message'>
            <Space direction='vertical' size={5} className='block'>
                <div className='children-message-title caption'>
                    <CrmAvatar
                        type={'user'}
                        size='small'
                        color={message.author.data.attributes.color}
                        firstName={message.author.data.attributes.firstName}
                        lastName={message.author.data.attributes.lastName}
                    />
                    <span>{moment(message.date).fromNow()}</span>
                </div>
                <div className={'crm-message-text'}>

                    {message.message}

                    {message.fileUrl && <div className={'public-message-file'}>
                        <div className={'crm-message-file-icon'}>
                            <FileIcon extension={message.fileUrl.split('.').pop()} {...defaultStyles[message.fileUrl.split('.').pop()]}/>
                        </div>
                        <span><a href={message.fileUrl} about={'blank'}>{message.fileUrl.substring(message.fileUrl.lastIndexOf('/')+1)}</a></span>
                    </div>}

                </div>
            </Space>
        </div>
    );
}