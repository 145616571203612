import React from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';

import CrmLayout from "../../components/CrmLayout";

import {gql, useMutation, useQuery} from "@apollo/client";
import {message, Modal, Skeleton, Space} from "antd";
import {ExclamationCircleOutlined, ExportOutlined,} from "@ant-design/icons";
import {INDEX, NOT_FOUND, training_edit} from "../../routes";
import moment from "moment";
import CrmDisplayData from "../../components/CrmDisplayData";
import CrmAvatar from "../../components/CrmAvatar";
import CrmCenteredTitle from "../../components/CrmCenteredTitle";
import map from 'lodash/map';
import TrainingVisits from "./Visit/TrainingVisits";

const GET_TRAINING_INFO = gql`
    query training ($id: ID!) {
        training(id: $id) {
            data {
                id
                attributes {
                    name
                    startDate
                    comment
                    contentUrl
                    enabled
                    trainers {
                        data {
                            id
                            attributes {
                                firstName
                                lastName
                                color
                            }
                        }
                    }
                    gym {
                        data {
                            id
                            attributes {
                                name
                                city {
                                    data {
                                        attributes {
                                            name
                                        }
                                    }
                                }
                                address
                            }
                        }
                    }
                }
            }
        }
    }
`;

const DELETE_TRAINING = gql`
    mutation updateTraining($data: TrainingInput!, $id: ID!) {
        updateTraining(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

export default function TrainingInfo() {

    const {id} = useParams();
    const navigate = useNavigate();

    const query = useQuery(GET_TRAINING_INFO, {
        variables: {id: id,},
        onError: (error) => {
            message.error(error.message)
            console.error(error)
        }
    });

    const [deleteTrainingMutation] = useMutation(DELETE_TRAINING);

    // useEffect(() => {
    //     query.refetch();
    // }, []);

    let displayName = null;
    let displayDate = null;
    let displayGym = null;
    let displayUser = null;
    let displayContentUrl = null;
    let displayComment = null;

    if (query.data !== undefined) {
        if (query.data.training.data !== null) {

            if (query.data.training.data.attributes.enabled === false) {
                navigate(NOT_FOUND, {replace: true});
                return null;
            }

            displayName = query.data.training.data.attributes.name || `Тренировка №${query.data.training.data.id}`;

            displayDate = moment(query.data.training.data.attributes.startDate).format('DD MMMM YYYY, HH:mm');

            if (query.data.training.data.attributes.gym.data !== null) {
                let cityName = '';
                if (query.data.training.data.attributes.gym.data.attributes.city.data !== null) {
                    cityName = query.data.training.data.attributes.gym.data.attributes.city.data.attributes.name;
                }
                displayGym =
                    <CrmDisplayData label='Зал' value={query.data.training.data.attributes.gym.data.attributes.name}>
                        <span>{cityName}, {query.data.training.data.attributes.gym.data.attributes.address}</span>
                    </CrmDisplayData>
            }

            if (query.data.training.data.attributes.trainers.data !== null) {
                displayUser =
                    <CrmDisplayData
                        label='Тренеры'
                        value={
                            <Space direction={'vertical'} className={'block'}>
                                {map(query.data.training.data.attributes.trainers.data, (value, index) => {
                                    return (<CrmAvatar
                                        key={index}
                                        type='user'
                                        color={value.attributes.color}
                                        firstName={value.attributes.firstName}
                                        lastName={value.attributes.lastName}
                                    />)
                                })}

                            </Space>
                        }
                    />
            }

            if (query.data.training.data.attributes.contentUrl !== null) {
                displayContentUrl =
                    <CrmDisplayData
                        label='Ссылка на состав тренировки'
                        value={
                            <Link
                                to={query.data.training.data.attributes.contentUrl}><ExportOutlined/> {query.data.training.data.attributes.contentUrl}
                            </Link>
                        }
                    />
            }

            if (query.data.training.data.attributes.comment !== null) {
                displayComment =
                    <div className='crm-message'>
                        {query.data.training.data.attributes.comment}
                    </div>
            }

        } else {
            navigate(NOT_FOUND, {replace: true});
            return null;
        }
    }

    function onDelete() {
        Modal.confirm({
            title: 'Удалить',
            icon: <ExclamationCircleOutlined/>,
            content: 'Действительно удалить тренировку?',
            okText: 'Да',
            cancelText: 'Нет',
            onOk() {
                onConfirmedDelete()
            }
        });
    }

    async function onConfirmedDelete() {
        await deleteTrainingMutation({
            variables: {
                id: id,
                data: {
                    enabled: false
                }
            },
            onCompleted: (res) => {
                if (res) {
                    navigate(INDEX);
                }
            },
            onError: (error) => {
                message.error(error.message)
                console.error(error)
            }
        })
    }

    return (

        <CrmLayout>

            <CrmCenteredTitle
                title={displayName}
                editAction={() => {
                    navigate(training_edit(id));
                }}
                deleteAction={() => {
                    onDelete()
                }}
            />

            {query.loading && <Skeleton active/>}

            {query.data &&

                <Space direction='vertical' size={20} className='block'>

                    <CrmDisplayData label='Начало' value={displayDate}/>

                    {displayGym}

                    {displayUser}

                    <TrainingVisits id={id}/>

                    {displayContentUrl}

                    {displayComment}

                </Space>
            }

        </CrmLayout>
    );
}



