import React from 'react';
import map from 'lodash/map';
import FiveOrangeCircle254D from "../../../components/Charts/includes/FiveOrangeCircle254D";
import FiveGreenCircle554D from "../../../components/Charts/includes/FiveGreenCircle554D";
import FiveBlueCircle354D from "../../../components/Charts/includes/FiveBlueCircle354D";
import FiveWhiteCircle654D from "../../../components/Charts/includes/FiveWhiteCircle654D";
import textEllipsis from '../../../components/Charts/includes/TextEllipsis';
import FivePurpleCircle454D from "../../../components/Charts/includes/FivePurpleCircle454D";

const colors = [
    '#FFFFFF',
    '#B2F900',
    '#E100FF',
    '#00EAFF',
    '#FFAE00',
    '#00FF7B',
    '#6D33FF',
    '#FF2D1A',
    '#33FF33',
    '#0051FF',
    '#FF1A90',
    '#3e77fd',
    '#baf341',
    '#6ff5ab',
    '#5fa4ee',
];

const FiveCirclesChart = (
    {
        params,
        ...rest
    }
) => {
    return (
        <svg width="1080" height="1080" viewBox="0 0 1080 1080" fill="none" style={{backgroundColor: '#2D68C4', borderRadius: 48}}
             xmlns="http://www.w3.org/2000/svg" {...rest}>

            <g transform="translate(-20 -700)">
                {map(params, (param, index) => {
                    if (index === 0) {
                        return <FiveWhiteCircle654D percent={param.percent} key={index}/>
                    }

                    if (index === 1) {
                        return <FiveGreenCircle554D percent={param.percent} key={index}/>
                    }

                    if (index === 2) {
                        return <FivePurpleCircle454D percent={param.percent} key={index}/>
                    }

                    if (index === 3) {
                        return <FiveBlueCircle354D percent={param.percent} key={index}/>
                    }

                    if (index === 4) {
                        return <FiveOrangeCircle254D percent={param.percent} key={index}/>
                    }

                    return null;
                })}
            </g>

            <g>
                {map(params, (param, index) => {
                    const delta = 50;

                    let color = colors[index];

                    const title = textEllipsis(param.title, 'normal 35px Open Sans', 600);

                    const y = 1040 - (params.length - index - 1) * delta;

                    return (
                        <React.Fragment key={index}>
                            <text fontSize="35" fontWeight="normal" fill={color} fontFamily="'Open Sans', sans-serif">
                                <tspan x="80" y={y}>{title}</tspan>
                            </text>

                            <text fontSize="35" fontWeight="normal" fill={color} textAnchor="end"
                                  fontFamily="'Open Sans', sans-serif">
                                <tspan x="780" y={y}>{param.value}</tspan>
                            </text>

                            <text fontSize="35" fontWeight="normal" fill={color} textAnchor="end"
                                  fontFamily="'Open Sans', sans-serif">
                                <tspan x="1000" y={y}>Цель: {param.targetValue}</tspan>
                            </text>
                        </React.Fragment>
                    )
                })}
            </g>

        </svg>
    );
}

export default FiveCirclesChart;
