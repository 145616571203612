import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Input, Space} from "antd";
import React from "react";
import CrmModal from "../../components/CrmModal";
import useUpdateClientMutation from "../../hooks/useUpdateClientMutation";

const schemaAddIndicator = yup.object({
    name: yup.string().required('Обязательно нужно заполнить')
}).required();

export default function ClientModalAddSkill(props) {

    const updateClient = useUpdateClientMutation();

    const {handleSubmit, control, getValues, setError, setValue, formState: {isSubmitting, isValid}} = useForm({
        resolver: yupResolver(schemaAddIndicator),
        defaultValues: {
            name: '',
            own: false,
        },
        reValidateMode: 'onChange',
        mode: 'all',
    });

    const onSubmit = async (data) => {

        let newSkill = props.clientInfo.attributes.skill.map(function (value) {
            return {
                id: value.id,
            }
        });
        newSkill.push(data);

        try {
            const res = await updateClient({skill: newSkill}, props.id);
            props.onUpdate();
            props.onClose();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <CrmModal isOpen={props.isOpen} onClose={() => props.onClose()} title={'Добавить навык'}
                  onAction={handleSubmit(onSubmit)}>
            <div className={'modal-basic-form'}>
                <Space direction="vertical" size={10} style={{width: '100%'}}>
                    Название навыка
                    <Controller
                        name={"name"}
                        control={control}
                        render={({field, formState: {submitCount}, fieldState: {error, isTouched}}) => (
                            <React.Fragment>
                                <Input
                                    size="large"
                                    {...field}
                                />
                                {(error && submitCount>0) && <span className={'caption color-danger'}>{error.message}</span>}

                            </React.Fragment>
                        )}/>
                </Space>
            </div>
        </CrmModal>
    )

}