import {Button, Image, Space} from 'antd';
import React from 'react';
import {useMutation} from '@apollo/client';
import CrmDisplayData from '../../components/CrmDisplayData';
import {LeftOutlined} from '@ant-design/icons';
import {nanoid} from 'nanoid';
import _ from 'lodash';
import {useNavigate} from 'react-router-dom';
import {CREATE_POST} from "../../graphql/createPost";
import isImage from "../../helpers/isImageURL";

export const PostingSend = ({messageData, clients, setStage}) => {

    const navigate = useNavigate()

    const [createPost] = useMutation(CREATE_POST)

    const sendPost = () => {
        const receivers = _(clients).map((client) => {
            const {phone} = client
            return {
                address: phone
            }
        }).value()

        createPost({
            variables: {
                data: {
                    message: messageData.message,
                    mediaUrl: messageData.mediaUrl,
                    provider: 'telegram',
                    uid: nanoid(),
                    receivers
                }
            }
        }).then(() => {
            navigate(-1)
        })
    }

    const goToMessage = () => {
        setStage(1)
    }

    return (
        <div className={'postingWrap'}>
            <Space direction={'vertical'} size={20} className={'block sendBlock'}>
                {messageData.mediaUrl &&
                    <>
                        {isImage(messageData.mediaUrl)
                            ?
                            <CrmDisplayData label={'Медиа'} value={<Image src={messageData.mediaUrl} width={320} style={{borderRadius: 8}}/>}/>
                            :
                            <CrmDisplayData label={'Медиа'} value={messageData.mediaUrl.split('/').pop()}/>
                        }
                    </>
                }
                <CrmDisplayData label={'Сообщение'} value={messageData.message}/>
                <CrmDisplayData label={'Будет отправлено в Telegram'} value={clients.length + ' получателей'}/>
            </Space>
            <div className={'bottomButtons'}>
                <Button type={'primary'} size={'large'} onClick={goToMessage}>
                    <LeftOutlined /> Назад
                </Button>
                <Button onClick={sendPost} type={'primary'} size={'large'}>
                    Отправить
                </Button>
            </div>
        </div>
    )
}