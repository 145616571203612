import React, {useState} from 'react';

import {useNavigate, useParams} from 'react-router-dom';
import CrmLayout from "../../components/CrmLayout";
import {gql, useMutation} from "@apollo/client";
import CrmCenteredTitle from "../../components/CrmCenteredTitle";
import {task_info} from "../../routes";

import dayjs from 'dayjs';
import TaskForm from "./TaskForm";

const CREATE = gql`
    mutation create($data: TaskInput!) {
        createTask(data: $data) {
            data {
                id
            }
        }
    }
`;


export default function TaskNew () {

    const {date, client} = useParams();

    const [loading, setLoading] = useState(false);

    const [create] = useMutation(CREATE);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);
        const newData = {
            date: dayjs(data.date).format('YYYY-MM-DD'),
            name: data.name,
            comment: data.comment,
            executor: data.executor,
            user: data.user,
        };
        const res = await create({variables: {data: newData}});
        navigate(task_info(res.data.createTask.data.id));
    };

    const data = {
        user: parseInt(client) ? client : null,
        date: dayjs(date),
    };

    return (
        <CrmLayout>

            <CrmCenteredTitle
                title={`Новая задача`}
            />

            <TaskForm onSubmit={onSubmit} loading={loading} data={data} actionName={'Добавить'}/>

        </CrmLayout>

    );
}