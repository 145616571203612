import {Alert, Button, Form, Input, message} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';

import {gql, useMutation} from '@apollo/client';
import logo from '../../images/sila-skilla-logo.svg';
import {useInstance} from "react-ioc";
import {Store} from "../../model/store/Store";
import {FORGET_PASSWORD, INDEX} from "../../routes";
import {observer} from "mobx-react-lite";

const LOGIN_MUTATION = gql`
    mutation login ($input: UsersPermissionsLoginInput!) {
        login(input: $input) {
            jwt
            user {
                id
                blocked
            }
        }
    }
`;

function Login() {


    const [loginMutation] = useMutation(LOGIN_MUTATION);
    const {auth} = useInstance(Store);

    const navigate = useNavigate();
    const location = useLocation();

    const [form] = Form.useForm();

    const onFinish = (values) => {
        loginMutation({
            variables: {
                input: {
                    identifier: values.login,
                    password: values.password,
                }
            },
            onCompleted: (data) => {
                auth.setTokenAndId(data.login.jwt, data.login.user.id);

                // если мы былии на странице "/login" то отправляем в INDEX
                if (location.pathname === '/login') {
                    navigate(INDEX);
                }
            },
            onError: (error) => {
                if (error.message === 'Failed to fetch') {
                    message.error('Ошибка получения данных');
                } else {
                    message.error('Логин или пароль не верный');
                }
            },
        });
    };

    return (
        <div className="crm-login-layout dissolved">
            <div className="crm-login-logo">
                <img src={logo} className="App-logo" alt="logo"/>
            </div>

            {auth.authError && <Alert message='Войдите еще раз' type='info'/>}

            <Form name='login'
                  form={form}
                  layout='vertical'
                  size='large'
                  scrollToFirstError
                  onFinish={onFinish}
            >
                <Form.Item name='login' label='Логин/Email' required
                           rules={[{required: true, message: 'Заполните поле',},]}>
                    <Input
                        placeholder='example@site.com'
                    />
                </Form.Item>

                <Form.Item name='password' label='Пароль' required
                           rules={[{required: true, message: 'Заполните поле',},]}>
                    <Input.Password/>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        Войти
                    </Button>
                </Form.Item>

            </Form>

            <Button type={'link'} onClick={() => navigate(FORGET_PASSWORD)}>Забыли пароль?</Button>

        </div>
    );
}

export default (observer(Login))



