import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Input, InputNumber, Space} from "antd";
import React from "react";
import CrmModal from "../../components/CrmModal";
import useUpdateClientMutation from "../../hooks/useUpdateClientMutation";
import {ArrowRightOutlined} from "@ant-design/icons";
import moment from 'moment';

const schemaAddIndicator = yup.object({
    name: yup.string().required('Обязательно нужно заполнить'),
    goal: yup.number().required('Обязательно нужно заполнить').moreThan(0),
    startValue: yup.number().required('Обязательно нужно заполнить').moreThan(0)
}).required();

export default function ClientModalAddIndicator(props) {

    const updateClient = useUpdateClientMutation();

    const {handleSubmit, control, getValues, setError, setValue, formState: {isSubmitting, isValid}} = useForm({
        resolver: yupResolver(schemaAddIndicator),
        defaultValues: {
            name: '',
            startValue: 0,
            goal: 0,
        },
        reValidateMode: 'onChange',
        mode: 'all',
    });

    const onSubmit = async (data) => {

        let newIndicator = props.clientInfo.attributes.indicator.map(function (value) {
            return {
                id: value.id,
                // name: value.name,
                // goal: value.goal,
                // result: value.result
            }
        });
        newIndicator.push({
            name: data.name,
            startValue: data.startValue,
            goal: data.goal,
            result: {
                date: moment().format('YYYY-MM-DD'),
                value: data.startValue
            }
        });

        try {
            const res = await updateClient({indicator: newIndicator}, props.id);
            props.onUpdate();
            props.onClose();


        } catch (error) {
            console.error(error);
        }
    };

    return (
        <CrmModal isOpen={props.isOpen} onClose={() => props.onClose()} title={'Добавить цель'}
                  onAction={handleSubmit(onSubmit)}>
            <div className={'modal-basic-form'}>
                <Space direction="vertical" size={10} style={{width: '100%'}}>
                    Название показателя
                    <Controller
                        name={"name"}
                        control={control}
                        render={({field, formState: {submitCount}, fieldState: {error, isTouched}}) => (
                            <React.Fragment>
                                <Input
                                    size="large"
                                    {...field}
                                />
                            </React.Fragment>
                        )}/>
                </Space>
                <Space size={10} align="end">
                    <Space direction="vertical" size={10} style={{width: '100%'}}>
                        Текущее значение
                        <Controller
                            name={"startValue"}
                            control={control}
                            render={({field, formState: {submitCount}, fieldState: {error, isTouched}}) => (
                                <React.Fragment>
                                    <InputNumber
                                        size="large"
                                        {...field}
                                    />
                                </React.Fragment>
                            )}/>
                    </Space>

                    <ArrowRightOutlined style={{position: 'relative', bottom: 8}}/>

                    <Space direction="vertical" size={10} style={{width: '100%'}}>
                        Цель
                        <Controller
                            name={"goal"}
                            control={control}
                            render={({field, formState: {submitCount}, fieldState: {error, isTouched}}) => (
                                <React.Fragment>
                                    <InputNumber
                                        size="large"
                                        {...field}
                                    />
                                </React.Fragment>
                            )}/>
                    </Space>
                </Space>
            </div>
        </CrmModal>
    )

}