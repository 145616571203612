import React from 'react';

import {Link, useParams} from 'react-router-dom';
import {gql, useQuery} from "@apollo/client";
import {Skeleton} from "antd";
import map from 'lodash/map';

import CrmLayout from "../../../components/CrmLayout";
import CrmCenteredTitle from "../../../components/CrmCenteredTitle";
import CrmAvatar from "../../../components/CrmAvatar";
import {client_info, subscription_info} from "../../../routes";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";

const REPORT_USER = gql`
    query reportUser ($endDate: String!, $id: String!, $startDate: String!) {
        reportUser (startDate: $startDate, endDate: $endDate, id: $id) {
            subscriptionsPaid {
                count
                sum
                data {
                    id
                    attributes {
                        paid
                        price
                        user {
                            data {
                                id
                                attributes {
                                    role {
                                        data {
                                            id
                                            attributes {
                                                name
                                            }
                                        }
                                    }
                                    client {
                                        data {
                                            id
                                            attributes {
                                                firstName
                                                lastName
                                                color
                                                avatar {
                                                    data {
                                                        attributes {
                                                            url
                                                            previewUrl
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    parent {
                                        data {
                                            id
                                            attributes {
                                                firstName
                                                lastName
                                                color
                                                avatar {
                                                    data {
                                                        attributes {
                                                            url
                                                            previewUrl
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default function ReportUserSubscriptionsPaid() {

    const {id, startDate, endDate} = useParams();

    const reportUser = useQuery(REPORT_USER, {
        variables: {
            startDate: startDate,
            endDate: endDate,
            id: id
        }
    });

    return (
        <CrmLayout>
            <CrmCenteredTitle title={'Абонементы'}/>
            {reportUser.loading
                ?
                <Skeleton active />
                :
                <>
                    <div className={'crm-report-line caption'}>
                        Клиент
                        <div className={'crm-report-count caption'}>Номер</div>
                        <div className={'crm-report-price'}>Цена</div>
                        <CheckCircleOutlined style={{opacity: 0}}/>
                    </div>

                    {map(reportUser.data.reportUser.subscriptionsPaid.data, (value, index) => {

                        const user = value.attributes.user.data;
                        const roleName = user.attributes.role.data.attributes.name;

                        let person = null;
                        let child = false;

                        if (roleName === 'Child') {
                            person = user.attributes.client.data;
                            child = true;
                        }

                        if (roleName === 'Parent') {
                            person = user.attributes.parent.data;
                        }


                        const firstName = person.attributes.firstName;
                        const lastName = person.attributes.lastName;
                        const avatar = person.attributes.avatar.data;

                        let avatarUrl = null;

                        if (avatar) {
                            avatarUrl = avatar.attributes.previewUrl;
                        }

                        const color = person.attributes.color;

                        return (
                            <div className={'crm-report-line'} key={index}>

                                <Link to={client_info(value.attributes.user.data.id)}>
                                    <CrmAvatar
                                        avatarUrl={avatarUrl}
                                        firstName={firstName}
                                        lastName={lastName}
                                        child={child}
                                        color={color}
                                    />
                                </Link>

                                <div className={'crm-report-count'}>
                                    <Link to={subscription_info(value.id)}>
                                        № {value.id}
                                    </Link>
                                </div>

                                <div className={'crm-report-price'}>{value.attributes.price}</div>

                                {value.attributes.paid === true
                                    ?
                                    <CheckCircleOutlined className={'color-success'}/>
                                    :
                                    <CloseCircleOutlined className={'color-danger'}/>
                                }

                            </div>
                        )
                    })}

                    <div className={'crm-report-line bold'}>Сумма: {reportUser.data.reportUser.subscriptionsPaid.sum}</div>

                </>
            }
        </CrmLayout>
    );
}



