import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import CrmLayout from "../../components/CrmLayout";
import ClientListRow from "./ClientListRow";

import {Empty, Input, Select, Skeleton} from 'antd';
import {EnvironmentOutlined} from '@ant-design/icons';

import {client_new} from "../../routes";
import {gql, useLazyQuery, useQuery} from "@apollo/client";

import InfiniteScroll from 'react-infinite-scroll-component';

import CrmCenteredTitle from "../../components/CrmCenteredTitle";

const QUERY_GYMS = gql`
    query users (
        $filters: GymFiltersInput,
        $pagination: PaginationArg = {},
        $sort: [String] = []) {
        gyms (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    name
                    address
                    enabled
                    city {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

const QUERY_CLIENTS = gql`
    query users (
        $filters: UsersPermissionsUserFiltersInput,
        $pagination: PaginationArg,
        $sort: [String] = []
    ) {
        usersPermissionsUsers (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    username
                    firstName
                    lastName
                    role {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                    client {
                        data {
                            id
                            attributes {
                                firstName
                                lastName
                                color
                                birthDate
                                avatar {
                                    data {
                                        attributes {
                                            previewUrl
                                            url
                                        }
                                    }
                                }
                                gym {
                                    data {
                                        attributes {
                                            name
                                            address
                                        }
                                    }
                                }
                            }
                        }
                    }
                    parent {
                        data {
                            id
                            attributes {
                                firstName
                                lastName
                                color
                                birthDate
                                avatar {
                                    data {
                                        attributes {
                                            previewUrl
                                            url
                                        }
                                    }
                                }
                                gym {
                                    data {
                                        attributes {
                                            name
                                            address
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

export default function Clients() {

    const {Option} = Select;

    // фильтр по странице
    const [page, setPage] = useState(0);

    // фильтр по роли пользователя
    const roleFilter = {
        0: {
            name: 'Взрослые и дети',
            role: {id: { in: [6,7] }},
            enabled: {eq: true}
        },
        1: {
            name: 'Взрослые',
            role: {name: {eq: 'Parent'}},
            enabled: {eq: true}
        },
        2: {
            name: 'Дети',
            role: {name: {eq: 'Child'}},
            enabled: {eq: true}
        },
        3: {
            name: 'Удаленные',
            enabled: {eq: false},
        },
    };
    const [selectedRole, setSelectedRole] = useState(0);

    // фильр по залу
    const [selectedGym, setSelectedGym] = useState(0);

    // фильтр по текстовому поиску
    const [searchText, setSearchText] = useState('');

    // main data
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(20);

    // запрос залов
    const [gyms, setGyms] = useState([]);
    const query = useQuery(QUERY_GYMS, {
        variables: {
            filters: {
                or: [{
                    enabled: {eq: true}
                }, {
                    enabled: {null: true}
                }]
            },
            pgination: {
                limit: 200
            }
        },
        onCompleted: (data) => {
            setGyms([...data.gyms.data]);
        }
    });

    const [fetch, fetchedData] = useLazyQuery(QUERY_CLIENTS, {
            fetchPolicy: 'network-only',
            onCompleted: (newData) => {
                setData([...data, ...newData.usersPermissionsUsers.data]);
                setTotal(newData.usersPermissionsUsers.meta.pagination.total)
            }
        }
    );

    const loadMoreData = () => {
        setPage(page + 1);
        fetch({
            variables: {
                filters: {
                    role: roleFilter[selectedRole].role,
                    or: [{
                        client: {
                            gym: selectedGym !== 0 ? {id: {eq: selectedGym}} : {},
                            or: [{firstName: {contains: searchText}}, {lastName: {contains: searchText}}]
                        }
                    }, {
                        parent: {
                            gym: selectedGym !== 0 ? {id: {eq: selectedGym}} : {},
                            or: [{firstName: {contains: searchText}}, {lastName: {contains: searchText}}]
                        }
                    }],
                    blocked: {ne: true},
                },
                sort: ['lastName:ASC', 'firstName:ASC'],
                pagination: {
                    pageSize: 20,
                    page: page + 1
                }
            }
        });
    };

    useEffect(() => {
        loadMoreData();
        // eslint-disable-next-line
    }, [selectedRole, selectedGym, searchText]);

    const navigate = useNavigate();

    const items = data.map(function (value, index) {

        let person = null;
        let role = null;

        if (value.attributes.client.data) {
            person = value.attributes.client.data;
            role = 'Child';
        }

        if (value.attributes.parent.data) {
            person = value.attributes.parent.data;
            role = 'Parent';
        }

        return (<ClientListRow
            key={value.id}
            id={person.id}
            firstName={person.attributes.firstName}
            lastName={person.attributes.lastName}
            role={role}
            birthDate={person.attributes.birthDate}
            color={person.attributes.color}
            avatarUrl={person.attributes.avatar.data && person.attributes.avatar.data.attributes.previewUrl}
            gym={person.attributes.gym.data && person.attributes.gym.data.attributes}
        />)

    });

    return (

        <CrmLayout>
            <CrmCenteredTitle
                title={'Клиенты'}
                addAction={() => {navigate(client_new())}}
            />

            <div className={'crm-filters'}>

                <Select className={'crm-filters-2-item'} value={selectedRole} dropdownMatchSelectWidth={false} onSelect={(value) => {
                    setSelectedRole(value);
                    setData([]);
                    setPage(0);
                }}>
                    <Option value={0}>Взрослые и дети</Option>
                    <Option value={1}>Взрослые</Option>
                    <Option value={2}>Дети</Option>
                </Select>

                <Select className={'crm-filters-2-item'} loading={query.loading} dropdownMatchSelectWidth={false} value={selectedGym}
                        onSelect={(value) => {
                            setSelectedGym(value);
                            setData([]);
                            setPage(0);
                        }}>
                    <Option value={0}>Все залы</Option>
                    {gyms.map(function (value) {
                        return (
                            <Option key={value.id} value={value.id}>
                                <EnvironmentOutlined/> <span
                                className={'bold'}>{value.attributes.name}</span> {value.attributes.address}
                            </Option>

                        )
                    })}
                </Select>

                <Input.Search className={'crm-filters-1-item'} onSearch={(value) => {
                    setSearchText(value);
                    setData([]);
                    setPage(0);
                }}/>

            </div>

            <InfiniteScroll
                className={'infinite_component'}
                dataLength={data.length}
                next={loadMoreData}
                hasMore={data.length < total}
                loader={<div className='client-list-row'><Skeleton avatar active paragraph={{rows: 0}}/></div>}
            >
                {items}
            </InfiniteScroll>

            {(data.length === 0 && !fetchedData.loading) && (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>)}

        </CrmLayout>
    );
}



