import React from 'react';

import {Link, useParams} from 'react-router-dom';
import {gql, useQuery} from "@apollo/client";
import {Skeleton} from "antd";
import map from 'lodash/map';

import CrmLayout from "../../../components/CrmLayout";
import CrmCenteredTitle from "../../../components/CrmCenteredTitle";
import CrmAvatar from "../../../components/CrmAvatar";
import {client_info} from "../../../routes";

const REPORT_GYM = gql`
    query reportGym ($endDate: String!, $id: String!, $startDate: String!) {
        reportGym (startDate: $startDate, endDate: $endDate, id: $id) {
            clientsWithoutSubscription {
                count
                data {
                    id
                    attributes {
                        role {
                            data {
                                id
                                attributes {
                                    name
                                }
                            }
                        }
                        client {
                            data {
                                id
                                attributes {
                                    firstName
                                    lastName
                                    color
                                    avatar {
                                        data {
                                            attributes {
                                                url
                                                previewUrl
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        parent {
                            data {
                                id
                                attributes {
                                    firstName
                                    lastName
                                    color
                                    avatar {
                                        data {
                                            attributes {
                                                url
                                                previewUrl
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default function ReportGymClientsWithoutSubscription() {

    const {id, startDate, endDate} = useParams();

    // console.log('startDate', startDate);
    // console.log('endDate', endDate);

    const reportGym = useQuery(REPORT_GYM, {
        variables: {
            startDate: startDate,
            endDate: endDate,
            id: id
        }
    });

    return (
        <CrmLayout>
            <CrmCenteredTitle title={'Без абонемента'}/>
            {reportGym.loading
                ?
                <Skeleton active/>
                :
                <>
                    {/*<p className={'caption color-gray-3'}>Клиенты, у которых нет абонемента в этом зале и выбранном периоде</p>*/}

                    <div className={'crm-report-line caption'}>
                        Клиент
                    </div>

                    {map(reportGym.data.reportGym.clientsWithoutSubscription.data, (value, index) => {

                        const user = value;
                        const roleName = user.attributes.role.data.attributes.name;

                        let person = null;
                        let child = false;

                        if (roleName === 'Child') {
                            person = user.attributes.client.data;
                            child = true;
                        }

                        if (roleName === 'Parent') {
                            person = user.attributes.parent.data;
                        }


                        const firstName = person.attributes.firstName;
                        const lastName = person.attributes.lastName;
                        const avatar = person.attributes.avatar.data;

                        let avatarUrl = null;

                        if (avatar) {
                            avatarUrl = avatar.attributes.previewUrl;
                        }

                        const color = person.attributes.color;

                        return (
                            <div className={'crm-report-line'} key={index}>
                                <Link to={client_info(user.id)}>
                                    <CrmAvatar
                                        avatarUrl={avatarUrl}
                                        firstName={firstName}
                                        lastName={lastName}
                                        child={child}
                                        color={color}
                                    />
                                </Link>

                            </div>
                        )
                    })}
                </>
            }
        </CrmLayout>
    );
}



