import React from 'react';
import WC from "./circle_32x32_green.png";

const FiveGreenCircle554D = ({percent}) => {
  const r = 277;
  let strokeDashoffset = ((100 - percent) / 100) * Math.PI * r * 2;
  const rotate = (percent / 100) * 360;
  const strokeDasharray = Math.PI * r * 2;
  if (percent >= 100) {
    strokeDashoffset = 0;
  }

  return (
    <>
      <circle
        cx="560"
        cy="1130"
        r={r}
        stroke="#B2F900"
        strokeOpacity="0.3"
        strokeWidth="32"
      />
      <circle
        cx="560"
        cy="1130"
        r={r}
        stroke="#B2F900"
        strokeOpacity="1"
        strokeWidth="32"
        strokeDashoffset={strokeDashoffset}
        strokeDasharray={strokeDasharray}
        strokeLinecap="round"
        transform="rotate(-90 560 1130)"
      />

      <image
        width="60"
        height="60"
        href={WC}
        x="530"
        y="823"
        transform={`rotate(${rotate} 560 1130)`}
      />s
    </>
  );
}

export default FiveGreenCircle554D;
