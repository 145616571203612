import React, {useState} from 'react';
import {Skeleton,} from 'antd';

import {useNavigate, useParams} from 'react-router-dom';
import CrmLayout from "../../components/CrmLayout";
import {gql, useMutation, useQuery} from "@apollo/client";
import CrmCenteredTitle from "../../components/CrmCenteredTitle";
import {task_info} from "../../routes";

import dayjs from 'dayjs';
import TaskForm from "./TaskForm";

const QUERY = gql`
    query query ($id: ID) {
        task(id: $id) {
            data {
                id
                attributes {
                    name
                    comment
                    date
                    user {
                        data {
                            id
                        }
                    }
                    executor {
                        data {
                            id
                        }
                    }
                }
            }
        }
    }
`;

const UPDATE = gql`
    mutation update($data: TaskInput!, $id: ID!) {
        updateTask(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;


export default function TaskEdit () {

    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const query = useQuery(QUERY, {
        variables: {id: id,},
        onCompleted: ((data) => {
            setData({
                date: dayjs(data.task.data.attributes.date),
                name: data.task.data.attributes.name,
                comment: data.task.data.attributes.comment,
                executor: data.task.data.attributes.executor.data && data.task.data.attributes.executor.data.id,
                user: data.task.data.attributes.user.data && data.task.data.attributes.user.data.id,
            });
        })
    });

    const [update] = useMutation(UPDATE);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);
        const newData = {
            date: dayjs(data.date).format('YYYY-MM-DD'),
            name: data.name,
            comment: data.comment,
            executor: data.executor,
            user: data.user,
        };
        await update({variables: {id: id, data: newData}});
        navigate(task_info(id));
    };

    return (
        <CrmLayout>

            <CrmCenteredTitle
                title={`Изменить задачу №${id}`}
            />

            {query.loading && <Skeleton active/>}

            {data &&
            <>
                <TaskForm onSubmit={onSubmit} loading={loading} data={data} actionName={'Сохранить'}/>
            </>
            }

        </CrmLayout>

    );
}