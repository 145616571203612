import React from 'react';
import WC from "./circle_60х60_purple.png";

const PurpleCircleChart133R = ({percent}) => {
  const r = 133;
  let strokeDashoffset = ((100 - percent) / 100) * Math.PI * r * 2;
  const rotate = (percent / 100) * 360;
  const strokeDasharray = Math.PI * r * 2;
  if (percent >= 100) {
    strokeDashoffset = 0;
  }

  return (
    <>
      <circle
        cx="560"
        cy="1180"
        r={r}
        stroke="#E100FF"
        strokeOpacity="0.3"
        strokeWidth="60"
      />
      <circle
        cx="560"
        cy="1180"
        r={r}
        stroke="#E100FF"
        strokeOpacity="1"
        strokeWidth="60"
        strokeDashoffset={strokeDashoffset}
        strokeDasharray={strokeDasharray}
        strokeLinecap="round"
        transform="rotate(-90 560 1180)"
      />

      <image
        width="120"
        height="120"
        href={WC}
        x="500"
        y="987"
        transform={`rotate(${rotate} 560 1180)`}
      />
    </>
  );
}

export default PurpleCircleChart133R;
