import {RootStore} from './RootStore';
import persist from 'mst-persist';

const STORE_IN_LOCAL_STORAGE_KEY = 'crm-trainer-store';

export class Store {
  static create() {
    const StoreInstance = RootStore.create({});

    persist(
      STORE_IN_LOCAL_STORAGE_KEY,
      StoreInstance,
      {
        jsonify: true,
        whitelist: [ // перечисление ключей хранилища которые надо сохранять между сессиями
          'auth',
          'deviceVars',
        ],
      },
    ).finally(() => {
      StoreInstance.setHydrated(true);
    });

    return StoreInstance;
  }
}
