import CrmCenteredTitle from '../../components/CrmCenteredTitle';
import React, {useState} from 'react';
import {Steps} from 'antd';
import {PostingClients} from './PostingClients';
import {PostingMessage} from './PostingMessage';
import {PostingSend} from './PostingSend';
import CrmHeader from '../../components/Header/CrmHeader';
import {useParams} from "react-router-dom";

export const Posting = () => {

    const { ids } = useParams();
    let userIds = null;
    if (ids) {
        userIds = JSON.parse(ids)
    }

    const [stage, setStage] = useState(0);
    const [messageData, setMessageData] = useState({message: '', mediaUrl: ''});
    const [clients, setClients] = useState([]);

    return (
        <>
            <CrmHeader />
            <div className={'postingWrap'}>
                <CrmCenteredTitle
                    title={'Новая рассылка'}
                />
                <Steps
                    progressDot
                    current={stage}
                    className={'steps'}
                    items={[
                        {
                            title: 'Аудитория',
                        },
                        {
                            title: 'Сообщение',
                        },
                        {
                            title: 'Отправка',
                        },
                    ]}
                />
            </div>




            {
                stage === 0 &&
                <PostingClients setStage={setStage} setClients={setClients} userIds={userIds}/>
            }
            {
                stage === 1 &&
                <PostingMessage setStage={setStage} messageData={messageData} setMessageData={setMessageData} />
            }
            {
                stage === 2 &&
                <PostingSend setStage={setStage} clients={clients} messageData={messageData} />
            }
        </>
    )
}