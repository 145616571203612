import {gql} from "@apollo/client";

export const UPLOAD_FILES = gql`
    query uploadFiles($fileName: String!) {
        uploadFiles(
            filters: {name: {eq: $fileName}}) {
            data {
                id
                attributes {
                    url
                }
            }
        }
    }
`;