import React, {useState} from 'react';

import {useNavigate, useParams} from 'react-router-dom';
import CrmLayout from "../../components/CrmLayout";
import {gql, useMutation} from "@apollo/client";
import CrmCenteredTitle from "../../components/CrmCenteredTitle";
import {subscription_info} from "../../routes";
import SubscriptionForm from "./SubscriptionForm";

import dayjs from 'dayjs';

const CREATE = gql`
    mutation create($data: SubscriptionInput!) {
        createSubscription(data: $data) {
            data {
                id
            }
        }
    }
`;


export default function SubscriptionNew() {

    const {id} = useParams();

    const [loading, setLoading] = useState(false);

    const [create] = useMutation(CREATE);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);
        const newData = {
            user: data.user,
            gym: data.gym,
            startDate: dayjs(data.range[0]).format('YYYY-MM-DD'),
            endDate: dayjs(data.range[1]).format('YYYY-MM-DD'),
            unlimited: data.unlimited,
            limit: data.limit,
            visitCount: data.visitCount,
            price: data.price,
            paid: data.paid,

        };
        const res = await create({variables: {data: newData}});
        navigate(subscription_info(res.data.createSubscription.data.id));
    };

    const data = {
        user: parseInt(id) ? id : null,
        range: [dayjs(), dayjs().add(1, 'month')],
        visitCount: 0,
    };

    return (
        <CrmLayout>

            <CrmCenteredTitle
                title={`Новый абонемент`}
            />

            <SubscriptionForm onSubmit={onSubmit} loading={loading} data={data} actionName={'Добавить'} selectGymAndUser/>

        </CrmLayout>

    );
}