import React from 'react';
import {useNavigate} from 'react-router-dom';
import {EnvironmentOutlined} from '@ant-design/icons';
import {training_info} from "../../routes";
import map from 'lodash/map';

export default function CalendarTraining(props) {

    const {id, name, trainers, color, gymName} = props;

    const navigate = useNavigate();

    function go() {
        navigate(training_info(props.id));
    }

    const displayColor = color ? color : '#F0F0F0';
    const displayTrainers = map(trainers, (value, index) => {
        const userColor = value.attributes.color ? value.attributes.color : '#F0F0F0';
        return (
            <div key={index}
                 className={'calendar-training-user'}
                 style={{backgroundColor: userColor}}
            >
                {value.attributes.firstName.charAt(0)}{value.attributes.lastName.charAt(0)}
            </div>
        )
    })
    const gym = gymName ? <><EnvironmentOutlined/> {gymName}</> : null

    return (
        <div className='calendar-training' style={{backgroundColor: displayColor}} onClick={go}>
            <div>{name || id}</div>
            <div>
                {displayTrainers} {gym}
            </div>
        </div>
    );
}