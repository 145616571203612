import React, {useState} from 'react';

import {Radio, Space} from 'antd';

import CrmLayout from "../../components/CrmLayout";
import CrmCenteredTitle from "../../components/CrmCenteredTitle";
import ChildNew from "./Child/ChildNew";
import ParentNew from "./Parent/ParentNew";
import {useParams} from "react-router-dom";

export default function ClientNew() {

    const {phone} = useParams();

    const [formType, setFormType] = useState('child');

    return (
        <CrmLayout>

            <CrmCenteredTitle
                title={'Новый клиент'}
            />

            <Space direction="vertical" size={20} style={{width: '100%'}}>

                <Radio.Group defaultValue={formType} buttonStyle="solid" onChange={(e) => setFormType(e.target.value)}>
                    <Radio.Button value="child">Ребенок</Radio.Button>s
                    <Radio.Button value="adult">Взрослый</Radio.Button>
                </Radio.Group>

                { formType === 'child' ? <ChildNew phone={phone === '0' ? null : phone}/> : <ParentNew phone={phone === '0' ? null : phone}/>}

            </Space>

        </CrmLayout>
    );
}
