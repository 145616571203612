import {Button, Image, Input, message, Upload} from 'antd';
import React from 'react';
import {CloseOutlined, LeftOutlined, LinkOutlined, RightOutlined, FileOutlined} from '@ant-design/icons';
import {getApiBase} from "../../env";
import {useMutation} from "@apollo/client";
import {UPLOAD} from "../../graphql/upload";
import isImage from "../../helpers/isImageURL";

export const PostingMessage = ({setStage, messageData, setMessageData}) => {

    const onChangeMessage = (value) => {
        setMessageData({...messageData, message: value})
    }

    const goToClients = () => {
        setStage(0)
    }

    const goToSend = () => {
        setStage(2)
    }

    const [uploadFileMutation, {loading}] = useMutation(UPLOAD);

    // const uploadImageProps = {
    //     maxCount: 1,
    //     showUploadList: false,
    //     accept: 'image/png, image/jpeg',
    // };

    const uploadProps = {
        maxCount: 1,
        showUploadList: false,
    };

    return (
        <div className={'postingWrap'}>

            {messageData.mediaUrl &&
                <div className={'postFile'}>
                    <Button className={'close'} icon={<CloseOutlined/>}
                            onClick={() => setMessageData({...messageData, mediaUrl: ''})}/>
                    {isImage(messageData.mediaUrl)
                        ?
                        <Image className={'image'} src={messageData.mediaUrl}/>
                        :
                        <div className={'file'}><FileOutlined/> {messageData.mediaUrl.split('/').pop()}</div>
                    }
                </div>
            }

            <div className={'postMessage'}>


                <Upload
                    {...uploadProps}
                    customRequest={async ({file, onError, onSuccess}) => {
                        try {
                            const res = await uploadFileMutation({variables: {file: file}});
                            const fileUrl = `${getApiBase()}${res.data.upload.data.attributes.url}`;
                            setMessageData({...messageData, mediaUrl: fileUrl})
                            onSuccess();
                        } catch (error) {
                            message.error(error.message)
                            console.error(error);
                            onError();
                            return false;
                        }
                    }}
                    className={'upload-file'}
                >
                    <Button
                        size={'large'}
                        icon={<LinkOutlined/>}
                        loading={loading}
                    />
                </Upload>

                <Input.TextArea
                    size={'large'}
                    autoSize={{minRows: 1, maxRows: 5}}
                    placeholder={'Сообщение'}
                    onChange={(event) => onChangeMessage(event.target.value)}
                    value={messageData.message}
                />
            </div>

            <div className={'bottomButtons'}>
                <Button type={'primary'} size={'large'} onClick={goToClients}>
                    <LeftOutlined/> Назад
                </Button>
                <Button type={'primary'} size={'large'} onClick={goToSend}
                        disabled={!messageData.message && !messageData.mediaUrl}>
                    <RightOutlined/> Далее
                </Button>
            </div>
        </div>
    )
}