import {useInstance} from "react-ioc";
import {Store} from "../../model/store/Store";
import {gql, useQuery} from "@apollo/client";
import {observer} from "mobx-react-lite";

const ME = gql`
    query usersPermissionsUser ($id: ID!) {
        usersPermissionsUser(id: $id) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    color
                }
            }
        }
        me {
            role {
                id
                name
            }
        }
    }
`;

function AboutMe ({children}) {

    const {auth} = useInstance(Store);

    const skip = auth.id === null;

    useQuery(ME, {
        variables: {id: auth.id},
        skip: skip,
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            if (data.usersPermissionsUser.data && data.me) {

                const authData = {
                    firstName: data.usersPermissionsUser.data.attributes.firstName,
                    lastName: data.usersPermissionsUser.data.attributes.lastName,
                    color: data.usersPermissionsUser.data.attributes.color !== null ? data.usersPermissionsUser.data.attributes.color : ''
                };

                const role = data.me.role.name;

                auth.setRoleAndData(role, authData);

            } else {
                auth.setAuthError(true);
            }

        },
        onError: (error) => {
            auth.setAuthError(true);
        }
    });

    return children;
}

export default observer(AboutMe);
