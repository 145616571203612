import cls from './bannerTelegram.module.scss';
import img from './background.jpg';
import logo from './tg_logo.svg';
import {Button} from "antd";

const BannerTelegram = ({url}) => {

    return (
        <a href={url} target={'_blank'} rel={'noreferrer'}>
            <div className={cls.banner} style={{backgroundImage: `url(${img})`}}>
                <img src={logo} alt={'Telegram'} className={cls.logo}/>
                <p className={cls.text}>Сила Скилла<br/>
                    теперь в популярном
                    мессенджере Telegram</p>
                <Button type={'primary'} size={'large'} className={cls.button}>Подключится</Button>
            </div>
        </a>
    );
}

export default BannerTelegram



