import React from 'react';
import {gql, useQuery} from "@apollo/client";
import {Button, Skeleton} from 'antd';
import {ArrowRightOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import {get_report_user} from "../../routes";

const REPORT_USER = gql`
    query reportUser ($endDate: String!, $id: String!, $startDate: String!) {
        reportUser (startDate: $startDate, endDate: $endDate, id: $id) {
            trainingsTotal {
                count
            }
            subscriptionsTotal {
                count
                sum
            }
            subscriptionsPaid {
                count
                sum
                data {
                    id
                }
            }
            subscriptionsNotPaid {
                count
                sum
            }
        }
    }
`;

export default function ReportUsersGroup({id, range}) {

    const navigate = useNavigate();

    const reportUser = useQuery(REPORT_USER, {
        variables: {
            startDate: range[0].format('YYYY-MM-DD'),
            endDate: range[1].format('YYYY-MM-DD'),
            id: id
        }
    });

    return (
        <>
            {reportUser.loading
                ?
                <Skeleton active />
                :
                <>
                    <div className={'crm-report-line'}>
                        Тренировок
                        <div className={'crm-report-count'}>{reportUser.data.reportUser.trainingsTotal.count}</div>
                        <Button type={'link'} icon={<ArrowRightOutlined/>} onClick={() => {navigate(get_report_user('trainingsTotal', id, range[0].format('YYYY-MM-DD'),  range[1].format('YYYY-MM-DD')))}}/>
                    </div>

                    <div className={'crm-report-line'}>
                        Оплаты абонементов
                        <div className={'crm-report-count'}>{reportUser.data.reportUser.subscriptionsTotal.count}</div>
                        <Button type={'link'} icon={<ArrowRightOutlined/>} onClick={() => {navigate(get_report_user('subscriptionsTotal', id, range[0].format('YYYY-MM-DD'),  range[1].format('YYYY-MM-DD')))}}/>
                    </div>
                    <div className={'crm-report-line inclose'}>
                        Оплачены
                        <div className={'crm-report-count'}>{reportUser.data.reportUser.subscriptionsPaid.count}</div>
                        <Button type={'link'} icon={<ArrowRightOutlined/>} onClick={() => {navigate(get_report_user('subscriptionsPaid', id, range[0].format('YYYY-MM-DD'),  range[1].format('YYYY-MM-DD')))}}/>
                    </div>
                    <div className={'crm-report-line inclose'}>
                        Не оплачены
                        <div className={'crm-report-count'}>{reportUser.data.reportUser.subscriptionsNotPaid.count}</div>
                        <Button type={'link'} icon={<ArrowRightOutlined/>} onClick={() => {navigate(get_report_user('subscriptionsNotPaid', id, range[0].format('YYYY-MM-DD'),  range[1].format('YYYY-MM-DD')))}}/>
                    </div>
                </>
            }

        </>
    );
}



