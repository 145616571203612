import React, {useState} from 'react';
import ParentForm from "../Parent/ParentForm";
import {gql, useMutation} from "@apollo/client";
import generatePassword from "../../../helpers/generatePassword";
import {useNavigate} from 'react-router-dom';
import {child_info} from "../../../routes";
import {PlusOutlined} from "@ant-design/icons";

const CREATE_USER = gql`
    mutation users ($data: UsersPermissionsUserInput!) {
        createUsersPermissionsUser (data: $data) {
            data {
                id
            }
        }
    }
`;

const CREATE_CLIENT = gql`
    mutation users ($data: ClientInput!) {
        createClient (data: $data) {
            data {
                id
            }
        }
    }
`;

export default function ChildNew({phone}) {

    const [createUser] = useMutation(CREATE_USER);
    const [createChild] = useMutation(CREATE_CLIENT);

    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState(generatePassword());

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);

        const userData = {
            firstName: data.firstName,
            lastName: data.lastName,
            username: data.email,
            email: data.email,
            password: password,
            contacts: data.contacts,
            blocked: false,
            role: 6,
        }

        const res = await createUser({variables: {data: userData}});
        const userId = res.data.createUsersPermissionsUser.data.id;

        const childData = {
            firstName: data.firstName,
            lastName: data.lastName,
            birthDate: data.birthDate,
            account: userId,
            gym: data.gym,
            enabled: true,
        }
        const res2 = await createChild({variables: {data: childData}});
        const childId = res2.data.createClient.data.id;

        navigate(child_info(childId), {replace: true});

    }

    let data = {};
    if (phone) {
        data.contacts = [
            {
                type: 'phoneWhatsapp',
                value: phone,
            }
        ]
    }

    return (
        <>
            <ParentForm data={data} onSubmit={onSubmit} loading={loading} withEmail withGym actionName={'Добавить'} actionIcon={<PlusOutlined/>}/>
        </>
    );
}
