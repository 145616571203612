import React from 'react';

import {useNavigate} from 'react-router-dom';

import {Button, message, notification} from 'antd';
import {PhoneOutlined, PlusOutlined} from '@ant-design/icons';

import {client_info, client_new} from '../../routes';
import {gql, useQuery} from '@apollo/client';

import _ from 'lodash';
import phoneFormatExpand from "../../helpers/phoneFormat";

const QUERY = gql`
    query calls (
        $filters: CallFiltersInput,
    ) {
        calls(filters: $filters) {
            data {
                id
                attributes {
                    phoneNumber
                    date
                    type
                    user {
                        data {
                            id
                            attributes {
                                firstName
                                lastName
                                role {
                                    data {
                                        id
                                        attributes {
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default function Call() {

    // const [api, contextHolder] = notification.useNotification();

    const navigate = useNavigate();

    // основной запрос
    const query = useQuery(QUERY, {
        variables: {
            filters: {or: [{status: {eq: 'ring'}}, {status: {eq: 'call'}}]},
        },
        pollInterval: 5000,
        onCompleted: (data) => {

            notification.destroy();

            _.map(data.calls.data, (call) => {

                // задаем изначальную кнопку - создания карточки с номером телефона
                // и задаем изначальный текст
                const phone = phoneFormatExpand(call.attributes.phoneNumber);

                let message = <h3>{phone}</h3>;
                let description = ``;
                let btn = (
                    <Button type='link' size='large' icon={<PlusOutlined/>} onClick={() => navigate(client_new(phone))}>
                        Создать пользователя
                    </Button>
                );

                // если указан пользователь, то показываем кнопку перехода в карточку пользователя
                // и показываем имя/фамилию кого определили
                const user = call.attributes.user.data;
                if (user) {
                    const role = user.attributes.role.data;

                    if (role.attributes.name === 'Child' || role.attributes.name === 'Parent') {
                        btn = (
                            <Button type='link' size='large' onClick={() => navigate(client_info(user.id))}>
                                Перейти в карточку
                            </Button>
                        );
                    } else {
                        btn = (
                            <>Сотрудник</>
                        );
                    }

                    message = <h3>{user.attributes.firstName} {user.attributes.lastName}</h3>;
                    description = `${phone}`;
                }


                notification.info({
                        key: call.id,
                        message: message,
                        description: description,
                        icon: (<PhoneOutlined/>),
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                        btn: btn,
                        duration: 0,
                    }
                )
            });


        },
        onError: (error) => {
            console.error(error);
            message.error('Ошибка получения данных звонков');
        },
    });

    return (
        <>
        </>
    );

}

