import React from 'react';
import {Avatar} from 'antd';

import './crm-avatar.scss';

export default function CrmAvatar({firstName, lastName, color, avatarUrl, caption, child, size, onClick}) {

    const displayFirstName = firstName || '';
    const displayLastName = lastName || '';
    const displayColor = color || '#F0F0F0';
    const displayAvatarUrl = avatarUrl || null;


    return (
        <div className={'crm-avatar'}>
            <Avatar
                size={size}
                style={{
                    backgroundColor: displayColor,
                    color: '#333333',
                }}
                src={avatarUrl}
            >
                {displayFirstName.charAt(0)}
                {displayLastName.charAt(0)}
            </Avatar>
            <div className={'text-wrapper childInfo_card'}>
                <div className={'text'}>
                    {child && '👦'} {displayFirstName} {displayLastName}
                </div>
                {caption && <div className={'caption color-gray-3'}>{caption}</div>}
            </div>
        </div>
    );

    // return (
    //     <span onClick={onClick}>
    //         <Space size={10}>
    //             <Avatar
    //                 size={size}
    //                 style={{backgroundColor: displayColor, color: '#333333'}}
    //                 src={displayAvatarUrl}
    //             >
    //                 {displayFirstName.charAt(0)}{displayLastName.charAt(0)}
    //             </Avatar>
    //             <div>
    //                 <div>{child && '👦'} {displayFirstName} {displayLastName}</div>
    //                 <div className={'caption color-gray-3'}>{caption}</div>
    //             </div>
    //         </Space>
    //     </span>
    // );
}