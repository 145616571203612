import React from 'react';
import CrmAvatar from "../../components/CrmAvatar";
import cn from 'classnames';
import {CheckOutlined} from '@ant-design/icons';

export default function ClientSelectRow({firstName, lastName, color, avatarUrl, selected, onClick, child}) {

    return (
        <div className={cn(['modal-selected-user-item', selected && 'selected'])} onClick={onClick}>
            <CrmAvatar type={'client'} firstName={firstName} lastName={lastName} avatarUrl={avatarUrl} color={color} child={child}/>
            {selected && <CheckOutlined/>}
        </div>
    );
}