function roleLocation(name) {
    const role = {
        'mother': 'Мама',
        'father': 'Папа',
        'granny': 'Бабушка',
        'grandFather': 'Дедушка',
        'other': 'Прочее',
    };

    return role[name];

}

export default roleLocation;