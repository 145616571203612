import React from 'react';
import PublicHeader from "./PublicHeader";

export default function PublicLayout(props) {
    return (
        <>
            <PublicHeader/>
            <div className="crm-layout crm-layout-content dissolved">
                {props.children}
            </div>
        </>
    );
}