import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {Skeleton} from 'antd';

import {NOT_FOUND} from "../../routes";
import {gql, useQuery} from "@apollo/client";

import CrmCenteredTitle from "../../components/CrmCenteredTitle";
import CrmLayout from "../../components/CrmLayout";
import AutomationEditForm from "./AutomationEditForm";

const QUERY = gql`
    query automation ($id: ID) {
        automation (id: $id) {
            data {
                id
                attributes {
                    action
                    cronOffset
                    taskOffset
                    author
                    name
                    comment
                }
            }
        }
    }
`;

export default function AutomationEdit() {

    const {id} = useParams();

    const navigate = useNavigate();

    const query = useQuery(QUERY, {
        variables: {
            id: id
        }
    });

    if (query.data !== undefined) {

        if (query.data.automation.data === null) {
            navigate(NOT_FOUND, {replace: true})
        }
    }

    return (

        <CrmLayout>

            <CrmCenteredTitle
                title={'Изменить настройку'}
            />

            {query.loading && <Skeleton active/>}

            {query.data &&
            <AutomationEditForm
                id={query.data.automation.data.id}
                data={query.data.automation.data.attributes}
            />
            }

        </CrmLayout>

    );
}



