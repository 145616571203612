import React from 'react';
import WC from "./circle_60х60_green.png";

const GreenCircleChart300R = ({percent}) => {
  let strokeDashoffset = ((100 - percent) / 100) * Math.PI * 232 * 2;
  const rotate = (percent / 100) * 360;
  const strokeDasharray = Math.PI * 232 * 2;

  if (percent >= 100) {
    strokeDashoffset = 0;
  }

  return (
    <>
      <circle
        cx="560"
        cy="1180"
        r="232"
        stroke="#B2F900"
        strokeOpacity="0.3"
        strokeWidth="60"
      />
      <circle
        cx="560"
        cy="1180"
        r="232"
        stroke="#B2F900"
        strokeOpacity="1"
        strokeWidth="60"
        strokeDashoffset={strokeDashoffset}
        strokeDasharray={strokeDasharray}
        strokeLinecap="round"
        transform="rotate(-90 560 1180)"
      />

      <image
        width="120"
        height="120"
        href={WC}
        x="500"
        y="888"
        transform={`rotate(${rotate} 560 1180)`}
      />
    </>
  );
}

export default GreenCircleChart300R;
