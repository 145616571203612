import React, {useState} from 'react';

import {gql, useMutation} from "@apollo/client";
import BonusVidget from "../../../vidgets/bonus/BonusVidget";
import {message} from "antd";

const UPDATE_CLIENT = gql`
    mutation updateClient ($data: ClientInput!, $id: ID!) {
        updateClient(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

const UPDATE_PARENT = gql`
    mutation updateParent ($data: ParentInput!, $id: ID!) {
        updateParent(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

export default function Bonus({id, value, parent}) {

    const [bonus, setBonus] = useState(value);
    const [updated, setUpdated] = useState(false);

    const [updateMutation] = useMutation(parent ? UPDATE_PARENT : UPDATE_CLIENT);

    async function onMinus() {

        setUpdated(true);

        const newBonus = Math.max((bonus -1), 0)
        setBonus(newBonus);

        await updateMutation({
            variables: {
                data: {bonus: newBonus},
                id: id,
            },
            onCompleted: () => {
                setUpdated(false);
            },
            onError: (error) => {
                message.error('Ошибка списания бонусов');
                console.error(error);
            },
        });

    }

    async function onPlus() {

        setUpdated(true);

        const newBonus = bonus +1;
        setBonus(newBonus);

        await updateMutation({
            variables: {
                data: {bonus: newBonus},
                id: id,
            },
            onCompleted: () => {
                setUpdated(false);
            },
            onError: (error) => {
                message.error('Ошибка начисления бонусов');
                console.error(error);
            },
        });

    }

    return (
        <BonusVidget
            value={bonus}
            updated={updated}
            onMinus={() => {onMinus()}}
            onPlus={() => {onPlus()}}
        />
    )

}


