import React, {useState} from 'react';
import {Skeleton,} from 'antd';

import {useNavigate, useParams} from 'react-router-dom';
import CrmLayout from "../../components/CrmLayout";
import {gql, useMutation, useQuery} from "@apollo/client";
import CrmCenteredTitle from "../../components/CrmCenteredTitle";
import {training_info} from "../../routes";

import dayjs from 'dayjs';
import TrainingForm from "./TrainingForm";
import _ from 'lodash';

const QUERY = gql`
    query query ($id: ID) {
        training(id: $id) {
            data {
                id
                attributes {
                    startDate
                    name
                    comment
                    contentUrl
                    trainers {
                        data {
                            id
                        }
                    }
                    gym {
                        data {
                            id
                        }
                    }
                }
            }
        }
    }
`;

const UPDATE = gql`
    mutation update($data: TrainingInput!, $id: ID!) {
        updateTraining(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;


export default function TrainingEdit () {

    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const query = useQuery(QUERY, {
        variables: {id: id,},
        onCompleted: ((data) => {
            setData({
                name: data.training.data.attributes.name,
                comment: data.training.data.attributes.comment,
                contentUrl: data.training.data.attributes.contentUrl,
                gym: data.training.data.attributes.gym.data.id,
                trainers: _.map(data.training.data.attributes.trainers.data, (item) => {return (item.id);}),
                startDate: dayjs(data.training.data.attributes.startDate),
            });
        })
    });

    const [update] = useMutation(UPDATE);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);
        const newData = {
            name: data.name,
            comment: data.comment,
            contentUrl: data.contentUrl,
            gym: data.gym,
            trainers: data.trainers,
            startDate: dayjs(data.startDate).startOf('hour').format(),
        };
        await update({variables: {id: id, data: newData}});
        navigate(training_info(id));
    };

    return (
        <CrmLayout>

            <CrmCenteredTitle
                title={`Изменить тренировку №${id}`}
            />

            {query.loading && <Skeleton active/>}

            {data &&
            <>
                <TrainingForm onSubmit={onSubmit} loading={loading} data={data} actionName={'Сохранить'}/>
            </>
            }

        </CrmLayout>

    );
}