import {Button} from 'antd';

import logo from '../../images/sila-skilla-logo.svg';
import {Link} from "react-router-dom";

import {LeftOutlined} from '@ant-design/icons';

const ForgetPassword = () => {

    return (
        <div className="crm-login-layout dissolved">
            <div className="crm-login-logo">
                <img src={logo} className="App-logo" alt="logo"/>
            </div>


            <p>
                Восстановление паролей временно не&nbsp;работает. Если вы&nbsp;забыли свой пароль, обратитесь
                к&nbsp;Администратору платформы. Спасибо за&nbsp;понимание.
            </p>


            <Link to='/login'>
                <Button type="link" block={true} icon={<LeftOutlined/>}>Назад</Button>
            </Link>

        </div>
    );
};

export default ForgetPassword
