import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes,} from "react-router-dom";
import {provider, useInstance} from 'react-ioc';
import {ConfigProvider, theme} from 'antd';

// Routes & Pages
import {
    AUTOMATION,
    AUTOMATION_EDIT,
    AUTOMATION_INFO,
    AUTOMATION_NEW,
    CALENDAR,
    CHILD_EDIT,
    CHILD_INFO,
    CLIENT_INFO,
    CLIENT_NEW,
    CLIENTS,
    FORGET_PASSWORD,
    GYM,
    INDEX,
    LOGIN,
    LOGOUT,
    NOT_FOUND,
    PARENT_EDIT,
    PARENT_INFO,
    PAYMENT_ERROR,
    PAYMENT_OFFER,
    PAYMENT_SUCCESS,
    POSTING,
    POSTINGS,
    PUBLIC_CLIENT_INFO,
    REPORT,
    REPORT_GYM_CLIENTS_FIRST_SUBSCRIPTION,
    REPORT_GYM_CLIENTS_NOT_EXTEND,
    REPORT_GYM_CLIENTS_TOTAL,
    REPORT_GYM_CLIENTS_WITH_SUBSCRIPTION,
    REPORT_GYM_CLIENTS_WITHOUT_SUBSCRIPTION,
    REPORT_GYM_TOTAL_MONEY,
    REPORT_USER_SUBSRIPTIONS_NOT_PAID,
    REPORT_USER_SUBSRIPTIONS_PAID,
    REPORT_USER_SUBSRIPTIONS_TOTAL,
    REPORT_USER_TRAININGS_TOTAL,
    SUBSCRIPTION,
    SUBSCRIPTION_EDIT,
    SUBSCRIPTION_INFO,
    SUBSCRIPTION_NEW,
    TASK,
    TASK_EDIT,
    TASK_INFO,
    TASK_NEW,
    TRAINING_EDIT,
    TRAINING_INFO,
    TRAINING_NEW,
    USER,
    WHATS_APP,
} from './routes';

import Login from './pages/Auth/Login';
import ForgetPassword from "./pages/Auth/ForgetPassword";

import Clients from "./pages/Client/Clients";
import ClientNew from "./pages/Client/ClientNew";
import ClientInfo from "./pages/Client/ClientInfo";

import Gym from './pages/Gym/Gym';

import User from './pages/User/User';

import {observer} from "mobx-react-lite";
import ApolloClientDI from "./model/graphql/ApolloClientDI";
import StoreDI from "./model/store/StoreDI";
import {Store} from "./model/store/Store";
import {ApolloClient, ApolloProvider} from "@apollo/client";

import Calendar from "./pages/Main/Calendar";
import TrainingInfo from "./pages/Main/TrainingInfo";
import TrainingNew from "./pages/Main/TrainingNew";
import TrainingEdit from "./pages/Main/TrainingEdit";

import Logout from "./pages/Auth/Logout";
import NotFound from "./pages/NotFound";
import ScrollToTop from "./hooks/scrollToTop";
import CrmWindowSpin from "./components/CrmWindowSpin";
import AboutMe from "./pages/Auth/AboutMe";
import SubscriptionNew from "./pages/Subscription/SubscriptionNew";
import Subscription from "./pages/Subscription/Subscription";
import SubscriptionInfo from "./pages/Subscription/SubscriptionInfo";
import SubscriptionEdit from "./pages/Subscription/SubscriptionEdit";
import PaymentSuccess from "./pages/Payment/Success";
import PaymentError from "./pages/Payment/Error";
import PaymentOffer from "./pages/Payment/Offer";
import {QueryClient, QueryClientProvider} from "react-query";
import Report from "./pages/Report/Report";
import ReportGymClientsTotal from "./pages/Report/GymPages/ReportGymClientsTotal";
import ReportUserTrainingsTotal from "./pages/Report/UserPages/ReportUserTrainingsTotal";
import ReportUserSubscriptionsTotal from "./pages/Report/UserPages/ReportUserSubscriptionsTotal";
import ReportUserSupscriptionsPaid from "./pages/Report/UserPages/ReportUserSubscriptionsPaid";
import ReportUserSubscriptionsNotPaid from "./pages/Report/UserPages/ReportUserSubscriptionsNotPaid";
import ReportGymClientsWithoutSubscription from "./pages/Report/GymPages/ReportGymClientsWithoutSubscription";
import Task from "./pages/Task/Task";
import TaskNew from "./pages/Task/TaskNew";
import TaskInfo from "./pages/Task/TaskInfo";
import TaskEdit from "./pages/Task/TaskEdit";
import ReportGymClientsWithSubscription from "./pages/Report/GymPages/ReportGymClientsWithSubscription";
import ReportGymClientsFirstSubscription from "./pages/Report/GymPages/ReportGymClientsFirstSubscription";
import ReportGymClientsNotExtend from "./pages/Report/GymPages/ReportGymClientsNotExtend";
import ReportGymTotalMoney from "./pages/Report/GymPages/ReportGymTotalMoney";
import Automation from "./pages/Automation/Authomation";
import AutomationInfo from "./pages/Automation/AutomationInfo";
import AutomationNew from "./pages/Automation/AutomationNew";
import AutomationEdit from "./pages/Automation/AutomationEdit";
import Index from "./pages/Main/Index";
import Test from "./pages/Test";
import ChildInfo from "./pages/Client/Child/ChildInfo";
import ParentInfo from "./pages/Client/Parent/ParentInfo";
import ParentEdit from "./pages/Client/Parent/ParentEdit";
import ChildEdit from "./pages/Client/Child/ChildEdit";
import PublicClientInfo from "./pages/Public/PublicClientInfo";
import Call from "./components/Call/Call";
import RoleFunctions from "./config/roles";
import Postings from "./pages/Posting/Postings";
import {Posting} from './pages/Posting/Posting';
import WhatsApp from "./pages/Provider/WhatsApp";

const queryClient = new QueryClient();

const App = () => {
    const {hydrated, auth} = useInstance(Store);

    const {isAuthenticated} = auth;
    const apolloClient = useInstance(ApolloClient);

    if (!hydrated) {
        return (
            <div className="App">
                <CrmWindowSpin spinning/>
            </div>
        )
    }

    return (
        <ConfigProvider theme={{
            algorithm: theme.defaultAlgorithm,
            token: {
                colorPrimary: '#2D68C4',
                colorLink: '#2D68C4',
            },
        }}>
            <ApolloProvider client={apolloClient}>
                <QueryClientProvider client={queryClient}>

                    <Router>
                        <ScrollToTop/>
                        <AboutMe>
                            <Routes>
                                <React.Fragment>
                                    <Route path={PAYMENT_SUCCESS} element={<PaymentSuccess/>}/>
                                    <Route path={PAYMENT_ERROR} element={<PaymentError/>}/>
                                    <Route path={PAYMENT_OFFER} element={<PaymentOffer/>}/>
                                    <Route path={PUBLIC_CLIENT_INFO} element={<PublicClientInfo/>}/>
                                </React.Fragment>

                                {!isAuthenticated && (
                                    auth.authError ?
                                        <Route path='*' element={<Login/>}/>
                                        : (
                                            <React.Fragment>
                                                <Route path="*" element={<Login/>}/>
                                                <Route path={LOGOUT} element={<Navigate to={LOGIN}/>}/>
                                                <Route path={LOGIN} element={<Login/>}/>
                                                <Route path={FORGET_PASSWORD} element={<ForgetPassword/>}/>
                                                <Route path={NOT_FOUND} element={<NotFound/>}/>
                                            </React.Fragment>
                                        ))}

                                {isAuthenticated && (
                                    <React.Fragment>

                                        {RoleFunctions.notifyCalls() && <Route path='*' element={<Call/>}/>}

                                        <Route path={INDEX} element={<Index/>}/>
                                        <Route path={CALENDAR} element={<Calendar/>}/>

                                        <Route path={TRAINING_INFO} element={<TrainingInfo/>}/>
                                        <Route path={TRAINING_NEW} element={<TrainingNew/>}/>
                                        <Route path={TRAINING_EDIT} element={<TrainingEdit/>}/>

                                        <Route path={CLIENTS} element={<Clients/>}/>
                                        <Route path={CLIENT_NEW} element={<ClientNew/>}/>
                                        <Route path={CLIENT_INFO} element={<ClientInfo/>}/>

                                        <Route path={CHILD_INFO} element={<ChildInfo/>}/>
                                        <Route path={CHILD_EDIT} element={<ChildEdit/>}/>

                                        <Route path={PARENT_INFO} element={<ParentInfo/>}/>
                                        <Route path={PARENT_EDIT} element={<ParentEdit/>}/>

                                        <Route path={GYM} element={<Gym/>}/>

                                        <Route path={USER} element={<User/>}/>

                                        <Route path={SUBSCRIPTION} element={<Subscription/>}/>
                                        <Route path={SUBSCRIPTION_NEW} element={<SubscriptionNew/>}/>
                                        <Route path={SUBSCRIPTION_INFO} element={<SubscriptionInfo/>}/>
                                        <Route path={SUBSCRIPTION_EDIT} element={<SubscriptionEdit/>}/>

                                        <Route path={TASK} element={<Task/>}/>
                                        <Route path={TASK_NEW} element={<TaskNew/>}/>
                                        <Route path={TASK_INFO} element={<TaskInfo/>}/>
                                        <Route path={TASK_EDIT} element={<TaskEdit/>}/>

                                        <Route path={AUTOMATION} element={<Automation/>}/>
                                        <Route path={AUTOMATION_INFO} element={<AutomationInfo/>}/>
                                        <Route path={AUTOMATION_NEW} element={<AutomationNew/>}/>
                                        <Route path={AUTOMATION_EDIT} element={<AutomationEdit/>}/>

                                        <Route path={REPORT} element={<Report/>}/>
                                        <Route path={REPORT_GYM_CLIENTS_TOTAL} element={<ReportGymClientsTotal/>}/>
                                        <Route path={REPORT_GYM_CLIENTS_WITH_SUBSCRIPTION}
                                               element={<ReportGymClientsWithSubscription/>}/>
                                        <Route path={REPORT_GYM_CLIENTS_WITHOUT_SUBSCRIPTION}
                                               element={<ReportGymClientsWithoutSubscription/>}/>
                                        <Route path={REPORT_GYM_CLIENTS_FIRST_SUBSCRIPTION}
                                               element={<ReportGymClientsFirstSubscription/>}/>
                                        <Route path={REPORT_GYM_CLIENTS_NOT_EXTEND}
                                               element={<ReportGymClientsNotExtend/>}/>
                                        <Route path={REPORT_GYM_TOTAL_MONEY} element={<ReportGymTotalMoney/>}/>

                                        <Route path={REPORT_USER_TRAININGS_TOTAL}
                                               element={<ReportUserTrainingsTotal/>}/>
                                        <Route path={REPORT_USER_SUBSRIPTIONS_TOTAL}
                                               element={<ReportUserSubscriptionsTotal/>}/>
                                        <Route path={REPORT_USER_SUBSRIPTIONS_PAID}
                                               element={<ReportUserSupscriptionsPaid/>}/>
                                        <Route path={REPORT_USER_SUBSRIPTIONS_NOT_PAID}
                                               element={<ReportUserSubscriptionsNotPaid/>}/>

                                        <Route path={POSTINGS} element={<Postings/>}/>
                                        <Route path={POSTING} element={<Posting/>}/>

                                        <Route path={LOGOUT} element={<Logout/>}/>
                                        <Route path={NOT_FOUND} element={<NotFound/>}/>

                                        <Route path={WHATS_APP} element={<WhatsApp/>}/>

                                        <Route path={"/test/*"} element={<Test/>}/>
                                        <Route path="*" element={<Navigate to={INDEX}/>}/>
                                    </React.Fragment>
                                )}
                            </Routes>
                        </AboutMe>
                    </Router>
                </QueryClientProvider>
            </ApolloProvider>
        </ConfigProvider>
    )
};

export default provider(
    StoreDI(),
    ApolloClientDI(),
)(observer(App));
