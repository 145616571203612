import React from 'react';
import './bonus.module.scss';

export default function BonusValue({value}) {
    return (
        <div className='bonus-value bold'>
            {value}
        </div>
    );
}
