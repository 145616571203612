import React, {useState} from 'react';

import {Pagination, Skeleton} from 'antd';

import {gql, useQuery} from "@apollo/client";

import CrmAvatar from "../../components/CrmAvatar";
import TaskLine from "./TaskLine";

const QUERY_TASKS = gql`
    query tasks ($filters: TaskFiltersInput, $pagination: PaginationArg, $sort: [String] = []) {
        tasks (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    name
                    comment
                    date
                    done
                    user {
                        data {
                            id
                            attributes {
                                firstName
                                lastName
                                color
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

export default function TaskGroup(props) {

    const {id, user} = props;

    const [page, setPage] = useState(1);

    const changePage = (page, pageSize) => {
        setPage(page);
    };

    const query = useQuery(QUERY_TASKS, {
        variables: {
            filters: {
                executor: {id: {eq: id}}
            },
            order: 'date:DESC',
            pagination: {
                pageSize: 20,
                page: page
            }
        }
    });

    const onUpdate = () => {
        query.refetch();
    }

    let items = null;
    let group = null;

    if (query.data !== undefined) {

        if (query.data.tasks.data.length > 0) {

            items = query.data.tasks.data.map(function (value) {

                return (
                    <TaskLine id={value.id} data={value.attributes} key={value.id} onUpdate={onUpdate}/>
                );
            });
            group = (
                <div className={'crm-task-group'}>
                    <CrmAvatar type={'user'} firstName={user.firstName} lastName={user.lastName} color={user.color}/>
                    {items}
                    {query.data.tasks.meta.pagination.total > 20 &&
                    <Pagination
                        size={'small'}
                        className={'crm-task-pagination'}
                        defaultCurrent={query.data.tasks.meta.pagination.page}
                        current={query.data.tasks.meta.pagination.page}
                        total={query.data.tasks.meta.pagination.total}
                        pageSize={query.data.tasks.meta.pagination.pageSize}
                        onChange={changePage}
                    />}
                </div>
            )
        }
    }

    return (

        <>
            {query.loading
                ?
                <Skeleton active/>
                :
                group
            }
        </>

    );
}



