import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {Modal, Skeleton, Space} from 'antd';

import {automation_edit, INDEX, NOT_FOUND} from "../../routes";
import {gql, useMutation, useQuery} from "@apollo/client";

import {ExclamationCircleOutlined} from "@ant-design/icons";
import CrmCenteredTitle from "../../components/CrmCenteredTitle";
import CrmLayout from "../../components/CrmLayout";
import CrmDisplayData from "../../components/CrmDisplayData";
import {OFFSETS, OPTIONS} from "./OptionNames";

const QUERY = gql`
    query automation ($id: ID, $filters: UsersPermissionsUserFiltersInput, $pagination: PaginationArg, $sort: [String] = []) {
        automation (id: $id) {
            data {
                id
                attributes {
                    action
                    cronOffset
                    taskOffset
                    author
                    name
                    comment
                }
            }
        }
        usersPermissionsUsers (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    color
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;

const DELETE = gql`
    mutation delete($id: ID!) {
        deleteAutomation(id: $id) {
            data {
                id
            }
        }
    }
`;

export default function AutomationInfo() {

    const {id} = useParams();

    const navigate = useNavigate();

    const query = useQuery(QUERY, {
        variables: {
            id: id,
            filters: {
                role: {
                    name: {
                        ne: 'Authenticated'
                    }
                }
            },
            sort: 'lastName:ASC',
            pagination: {
                limit: 200
            }
        },
        fetchPolicy: "network-only"
    });

    let title = '...';
    let author = '';

    if (query.data !== undefined) {

        if (query.data.automation.data === null) {
            navigate(NOT_FOUND, {replace: true})
        } else {
            if (query.data.automation.data.attributes.author.substring(0, 8) === 'user_id:') {
                const id = query.data.automation.data.attributes.author.split(':')[1];
                const users = query.data.usersPermissionsUsers.data;
                const user = users.filter(item => item.id === id);
                const firstName  = user[0].attributes.firstName;
                const lastName = user[0].attributes.lastName;
                author = `${firstName} ${lastName}`;
            }
            if (query.data.automation.data.attributes.author === 'author') {
                author = OPTIONS[query.data.automation.data.attributes.action].author;
            }
            if (query.data.automation.data.attributes.author === 'trainer') {
                author = OPTIONS[query.data.automation.data.attributes.action].trainer;
            }
        }

        title = OPTIONS[query.data.automation.data.attributes.action].name;
    }

    const [deleteMutation] = useMutation(DELETE);

    function onDelete() {
        Modal.confirm({
            title: 'Удалить',
            icon: <ExclamationCircleOutlined/>,
            content: 'Действительно удалить настройку?',
            okText: 'Да',
            cancelText: 'Нет',
            onOk() {
                onConfirmedDelete()
            }
        });
    }

    async function onConfirmedDelete() {
        const res = await deleteMutation({
            variables: {
                id: id
            }
        })
        navigate(INDEX, {replace: true});
    }

    return (

        <CrmLayout>

            <CrmCenteredTitle
                title={title}
                editAction={() => {
                    navigate(automation_edit(id))
                }}
                deleteAction={() => {
                    onDelete()
                }}
            />

            {query.loading && <Skeleton active/>}

            {query.data &&
            <Space direction={'vertical'} size={20} className={'block'}>

                <CrmDisplayData label={'Событие'} value={OPTIONS[query.data.automation.data.attributes.action].name}/>
                <CrmDisplayData label={'Задача создается относительно события'} value={OFFSETS[query.data.automation.data.attributes.cronOffset].name}/>
                <CrmDisplayData label={'Задача назначается относительно события'} value={OFFSETS[query.data.automation.data.attributes.taskOffset].name}/>
                <CrmDisplayData label={'Исполнитель'} value={author}/>
                <CrmDisplayData label={'Название задачи'} value={query.data.automation.data.attributes.name}/>
                <CrmDisplayData label={'Комментарий'} value={query.data.automation.data.attributes.comment}/>

            </Space>
            }

        </CrmLayout>

    );
}



