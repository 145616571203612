import './Posting.scss'
import {Progress} from 'antd';
import _ from 'lodash';
import {gql, useQuery} from '@apollo/client';
import {useState} from 'react';
import cn from 'classnames';

import cls from '../../styles/list.module.scss';

const RECIEVERS = gql`
    query receivers($filters: ReceiverFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        receivers(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    status
                }
            }
        }
    }
`

export const PostRow = ({data}) => {
    const [successReceivers, setSuccessReceivers] = useState(0);
    const [totalReceivers, setTotalReceivers] = useState(0);

    useQuery(RECIEVERS, {
        variables: {
            filters: {
                postingUid: {eq: data.attributes.uid},
            },
            pagination: {
                limit: 1000,
            },
        },
        onCompleted: (data) => {
            const total = data.receivers.data.length
            setTotalReceivers(total)

            const completedCount = _(data.receivers.data).filter((receivers) => {
                return receivers.attributes.status === 'done'
            }).value()
            setSuccessReceivers(completedCount.length)
        },
        pollInterval: 1000,
    })

    const percentOfSuccess = successReceivers / totalReceivers * 100

    let strokeColor = '#B2F900'
    if (percentOfSuccess < 60) {
        strokeColor = '#F6C546'
    }

    return (
        <div className={cn([cls.row, 'postRow'])}>
            <span className={'postPreview'}>
                {data.attributes.message}
            </span>
            <Progress percent={percentOfSuccess} strokeColor={strokeColor} showInfo={false} className={'postProgress'} />
            <span className={'postTotal'}>
                {successReceivers}/{totalReceivers}
            </span>
        </div>
    );
};



