import React from 'react';
import BgSvg from "./BgSvg";
import WC from './white_circle.png';
import textEllipsis from './TextEllipsis';

const SingleCircleWhiteChart = (
  {
    percent,
    firstName,
    lastName,
    title: originalTitle,
    targetValue,
    value,
    date,
    ...rest
  }
) => {
  const r = 350;
  const strokeDasharray = Math.PI * r * 2;
  let strokeDashoffset = ((100 - percent) / 100) * Math.PI * r * 2;
  const rotate = (percent / 100) * 360;

  if (percent >= 100) {
    strokeDashoffset = 0;
  }

  const title = textEllipsis(originalTitle, 'normal 50px Open Sans', 700);

  return (
    <svg width="1080" height="1920" viewBox="0 0 1080 1920" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <BgSvg/>
      <text fontSize="60" fontWeight="bold" fill="#fff" fontFamily="'Open Sans', sans-serif">
        <tspan x="50%" textAnchor="middle" y="420">{firstName}</tspan>
        {lastName && (
          <tspan x="50%" textAnchor="middle" y="500">{lastName}</tspan>
        )}
      </text>

      <text fontSize="50" fontWeight="normal" fill="#fff" fontFamily="'Open Sans', sans-serif">
        <tspan x="80" y="705">{title}</tspan>
      </text>

      <text fontSize="50" fontWeight="normal" fill="#fff" textAnchor="end" fontFamily="'Open Sans', sans-serif">
        <tspan x="1000" y="705">Цель: {targetValue}</tspan>
      </text>

      <g transform="translate(-21 -134)">
        <circle
          cx="560"
          cy="1320"
          r="350"
          stroke="white"
          strokeOpacity="0.3"
          strokeWidth="80"
        />
        <circle
          cx="560"
          cy="1320"
          r="350"
          stroke="white"
          strokeOpacity="1"
          strokeWidth="80"
          strokeDashoffset={strokeDashoffset}
          strokeDasharray={strokeDasharray}
          strokeLinecap="round"
          transform="rotate(-90 560 1320)"
        />
        <image
          width="120"
          height="120"
          href={WC}
          x="500"
          y="910"
          transform={`rotate(${rotate} 560 1320)`}
        />
      </g>

      <text
        x={540}
        y={1180}
        width={1080}
        fontFamily="'Open Sans', sans-serif"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="200"
        fontWeight="400"
        fill="#fff"
      >
        {value}
      </text>

      <text
        x={540}
        y={1336}
        opacity={0.3}
        width={1080}
        fontFamily="'Open Sans', sans-serif"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="50"
        fontWeight="400"
        fill="#fff"
      >
        {date}
      </text>
    </svg>
  );
}

export default SingleCircleWhiteChart;
