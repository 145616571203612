import React, {useState} from 'react';
import {gql, useMutation} from "@apollo/client";
import CrmModal from "../../../components/CrmModal";
import ParentForm from "./ParentForm";
import {PlusOutlined} from "@ant-design/icons";
import generatePassword from "../../../helpers/generatePassword";

const CREATE_USER = gql`
    mutation users ($data: UsersPermissionsUserInput!) {
        createUsersPermissionsUser (data: $data) {
            data {
                id
            }
        }
    }
`;

const CREATE_PARENT = gql`
    mutation users ($data: ClientInput!) {
        createClient (data: $data) {
            data {
                id
            }
        }
    }
`;

export default function ModalChildAdd({isOpen, onClose, onFinish}) {

    const [createUser] = useMutation(CREATE_USER);
    const [createChild] = useMutation(CREATE_PARENT);

    const [loading, setLoading] = useState(false);
    const password = generatePassword();

    const onSubmit = async (data) => {
        setLoading(true);

        const userData = {
            firstName: data.firstName,
            lastName: data.lastName,
            username: data.email,
            email: data.email,
            password: password,
            contacts: data.contacts,
            blocked: false,
            role: 6,
        }

        const res = await createUser({variables: {data: userData}});
        const userId = res.data.createUsersPermissionsUser.data.id;

        const childData = {
            firstName: data.firstName,
            lastName: data.lastName,
            birthDate: data.birthDate,
            account: userId,
        }
        const res2 = await createChild({variables: {data: childData}});
        const childId = res2.data.createClient.data.id;

        onFinish(childId);

    }

    return (
        <CrmModal isOpen={isOpen} onClose={onClose} title={'Добавить ребенка'}>
            <div className={'modal-basic-form'}>

                <ParentForm onSubmit={onSubmit} loading={loading} withEmail actionName={'Добавить'} actionIcon={<PlusOutlined/>}/>

            </div>
        </CrmModal>
    )

}