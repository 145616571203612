import React, {useState} from 'react';
import ParentForm from "./ParentForm";
import {gql, useMutation, useQuery} from "@apollo/client";
import {Skeleton} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import {parent_info} from "../../../routes";
import CrmCenteredTitle from "../../../components/CrmCenteredTitle";
import CrmLayout from "../../../components/CrmLayout";
import _ from "lodash";

const QUERY = gql`
    query query (
        $id: ID,
    ) {
        parent(id: $id) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    birthDate
                    enabled
                    account {
                        data {
                            id
                            attributes {
                                email
                                blocked
                                contacts {
                                    type
                                    value
                                    data
                                }
                            }
                        }
                    }
                    gym {
                        data {
                            id
                        }
                    }
                }
            }
        }
    }
`;

const UPDDATE_USER = gql`
    mutation users ($id: ID!, $data: UsersPermissionsUserInput!) {
        updateUsersPermissionsUser (id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;

const UPDATE_PARENT = gql`
    mutation users ($id: ID!, $data: ParentInput!) {
        updateParent (id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;

export default function ParentEdit() {

    const {id} = useParams();

    const [loading, setLoading] = useState(false);
    const [accountId, setAccountId] = useState(null);
    const [data, setData] = useState(null);

    const query = useQuery(QUERY, {
        variables: {id: id,},
        onCompleted: ((data) => {
            setData({
                firstName: data.parent.data.attributes.firstName,
                lastName: data.parent.data.attributes.lastName,
                birthDate: data.parent.data.attributes.birthDate,
                contacts: data.parent.data.attributes.account.data.attributes.contacts,
                gym: data.parent.data.attributes.gym.data && data.parent.data.attributes.gym.data.id,
            });
            setAccountId(data.parent.data.attributes.account.data.id);
        })
    });

    const [updateUser] = useMutation(UPDDATE_USER);
    const [updateParent] = useMutation(UPDATE_PARENT);

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);

        const userData = {
            firstName: data.firstName,
            lastName: data.lastName,
            contacts: _.map(data.contacts, item => {
                return {
                    ...item,
                    data: item.data && JSON.parse(item.data),
                }
            }),
        }
        await updateUser({variables: {id: accountId, data: userData}});

        const parentData = {
            firstName: data.firstName,
            lastName: data.lastName,
            birthDate: data.birthDate,
            gym: data.gym,
        }
        await updateParent({variables: {id: id, data: parentData}});

        navigate(parent_info(id));

    }

    return (
        <CrmLayout>

            <CrmCenteredTitle
                title={query.data ? `${query.data.parent.data.attributes.firstName} ${query.data.parent.data.attributes.lastName}` : '—'}
            />

            {query.loading && <Skeleton active/>}

            {query.data &&
            <>
                <ParentForm onSubmit={onSubmit} loading={loading} data={{
                    firstName: query.data.parent.data.attributes.firstName,
                    lastName: query.data.parent.data.attributes.lastName,
                    birthDate: query.data.parent.data.attributes.birthDate,
                    contacts: query.data.parent.data.attributes.account.data.attributes.contacts.map(function (value) {
                        return {type: value.type, value: value.value, data: JSON.stringify(value.data)}
                    }),
                    gym: query.data.parent.data.attributes.gym.data && query.data.parent.data.attributes.gym.data.id,

                }} actionName={'Сохранить'} withGym/>

            </>
            }

        </CrmLayout>
    );
}
