import React from 'react';
import {EnvironmentOutlined} from '@ant-design/icons';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {child_info, parent_info} from "../../routes";
import CrmAvatar from "../../components/CrmAvatar";

export default function ClientListRow(props) {

    const {id, role, firstName, lastName, color, avatarUrl, birthDate, gym} = props;

    const navigate = useNavigate();

    function calculateAge(value) {
        return moment().diff(value, 'years');
    }

    function go() {
        if (role === 'Child') {
            navigate(child_info(id));
        };
        if (role === 'Parent') {
            navigate(parent_info(id));
        };
    }

    const date = birthDate ? calculateAge(birthDate) + ' лет' : undefined;

    return (
        <div className='client-list-row' onClick={go}>
            <CrmAvatar
                size={'large'}
                firstName={firstName}
                lastName={lastName}
                color={color}
                avatarUrl={avatarUrl}
                caption={<>{role === 'Child' && '👦'} {date} {gym && <><EnvironmentOutlined/> {props.gym.name}</>}</>}
            />
        </div>
    );
}
