import React from 'react';
import CrmHeader from './Header/CrmHeader';

export default function CrmLayout (props) {
    return (
        <div>
            <CrmHeader/>
            <div className="crm-layout crm-layout-content dissolved">
                {props.children}</div>
        </div>
    );
}