import {gql} from "@apollo/client";

export const CREATE_POST = gql`
    mutation createPosting($data: PostingInput!) {
        createPosting(data: $data) {
            data {
                id
            }
        }
    }
`