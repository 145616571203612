import React, {useState} from 'react';
import {Button, Input, message, Upload} from 'antd';
import {PaperClipOutlined, SendOutlined} from '@ant-design/icons';
import moment from 'moment';
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {gql, useMutation} from "@apollo/client";
import {useInstance} from "react-ioc";
import {Store} from "../../model/store/Store";
import {getApiBase} from "../../env";

const schemaAddIndicator = yup.object().shape({
    'fileUrl': yup.string()
        .when('message', {
            is: (message) => !message || message.length === 0,
            then: yup.string()
                .required('At least one of the fields is required'),
        }),
    'message': yup.string()
        .when('fileUrl', {
            is: (fileUrl) => !fileUrl || fileUrl.length === 0,
            then: yup.string()
                .required('At least one of the fields is required')
        })
}, [['fileUrl', 'message']]);

const CREATE_MESSAGE = gql`
    mutation createMessage($data: MessageInput!) {
        createMessage(data: $data) {
            data {
                id
            }
        }
    }
`;

const UPLOAD_FILE_MUTATION = gql`
    mutation upload($file: Upload!) {
        upload(file: $file) {
            data {
                id
                attributes {
                    url
                    formats
                }
            }
        }
    }
`;

export default function TaskAddComment (props) {

    const {taskId, onUpdate} = props;

    const {handleSubmit, control, setValue, reset, formState: {isValid}} = useForm({
        resolver: yupResolver(schemaAddIndicator),
        defaultValues: {
            fileUrl: '',
            message: ''
        },
        reValidateMode: 'onChange',
        mode: 'all',
    });

    const [uploadFileMutation] = useMutation(UPLOAD_FILE_MUTATION);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(false);
    const [uploadFileName, setUploadFileName] = useState('');

    const uploadProps = {
        maxCount: 1,
        showUploadList: false,
        // fileList: [],
        onChange(info) {
            if (info.file.status === 'uploading') {
                setUploadingFile(true);
            }
            if (info.file.status === 'done') {
                setUploadingFile(false);
                setUploadedFile(true);
                setUploadFileName(info.file.name);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const [createMutation] = useMutation(CREATE_MESSAGE);

    const {auth} = useInstance(Store);

    const onSubmit = async (formData) => {

        const data = {
            author: auth.id,
            task: taskId,
            message: formData.message,
            fileUrl: formData.fileUrl,
            date: moment().format()
        };

        try {
            await createMutation({variables: {data: data}});
            reset();
            onUpdate();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <div className={'client-form-message'}>
                <Upload
                    {...uploadProps}
                    customRequest={async ({file, onError, onSuccess}) => {
                        try {
                            const res = await uploadFileMutation({variables: {file: file}});
                            const fileUrl = `${getApiBase()}${res.data.upload.data.attributes.url}`;
                            setValue('fileUrl', fileUrl, { shouldValidate: true });
                            onSuccess();
                        } catch (error) {
                            console.error(error);
                            onError();
                            return false;
                        }
                    }}
                    className={'upload-file'}
                >
                    <Button
                        size={'large'}
                        icon={<PaperClipOutlined/>}
                        loading={uploadingFile}
                    />
                </Upload>


                <div className={'client-form-message-input'}>
                    <Controller
                        name={"message"}
                        control={control}
                        render={({field, formState: {submitCount}, fieldState: {error, isTouched}}) => (
                            <React.Fragment>
                                <Input.TextArea
                                    rows={field.value.split(/\r?\n|\r/).length < 10 ? field.value.split(/\r?\n|\r/).length : 10}
                                    size="large"
                                    placeholder={'Пожелания, комментарии'}
                                    {...field}
                                />
                                {(error && submitCount > 0) &&
                                <span className={'caption color-danger'}>{error.message}</span>}
                            </React.Fragment>
                        )}/>
                </div>

                <Button
                    size={'large'}
                    type={'primary'}
                    shape={'square'}
                    icon={<SendOutlined/>}
                    disabled={!isValid}
                    onClick={handleSubmit(onSubmit)}
                />
            </div>

            {uploadedFile && <div className={'client-form-message-file'}><PaperClipOutlined/> {uploadFileName}</div>}

        </div>
    );
}