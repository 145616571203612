import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {Checkbox, message} from 'antd';

import {task_info} from "../../routes";
import {gql, useMutation} from "@apollo/client";

import moment from 'moment';

const UPDATE_TASK = gql`
    mutation updateTask($data: TaskInput!, $id: ID!) {
        updateTask(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

export default function TaskLine(props) {

    const {id, data, onUpdate} = props;

    const navigate = useNavigate();

    const [updateTaskMutation, {loading}] = useMutation(UPDATE_TASK);

    const [done, setDone] = useState(data.done);

    const onChengeDone = async (value) => {

        const newDone = !done;

        try {
            await updateTaskMutation({variables: {id: id, data: {done: newDone}}});
            setDone(newDone);
            onUpdate();
        } catch (error) {
            console.error(error);
            message.error('Что-то пошло не так, попробуйте снова');
        }
    };

    return (

        <div className={'crm-task-line'} onClick={() => {navigate(task_info(id))}}>
            <div className={'crm-task-date'}>{moment(data.date).format('DD MMM YYYY')}</div>
            <div className={'crm-task-name'}>{data.name}</div>
            <div className={'crm-task-done'}><Checkbox checked={done} onChange={onChengeDone} disabled={loading}/></div>
        </div>

    );
}



