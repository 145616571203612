import React from 'react';
import {Button, Modal, Space, Switch} from 'antd';
import {DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {gql, useMutation} from "@apollo/client";

const UPDATE = gql`
    mutation update($data: ParentInput!, $id: ID!) {
        updateParent(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

export default function ParentSkill(props) {

    const [update] = useMutation(UPDATE);

    const skillID = props.skill.id;

    const onSubmit = async (data) => {

        let newSkill = props.clientInfo.attributes.skills.map(function (value) {
            if (value.id === skillID) {
                return {
                    id: value.id,
                    own: data
                }
            } else {
                return {id: value.id}
            }
        });

        try {
            await update({variables: {data: {skills: newSkill}, id: props.id}});
            props.onUpdate();
        } catch (error) {
            console.error(error);
        }
    };

    function onDelete() {
        Modal.confirm({
            title: 'Удалить',
            icon: <ExclamationCircleOutlined/>,
            content: 'Действительно удалить навык?',
            okText: 'Да',
            cancelText: 'Нет',
            onOk() {
                deleteSkill()
            }
        });
    }

    async function deleteSkill() {


        let skill = props.clientInfo.attributes.skills.map(function (value) {
            return {id: value.id};
        });

        const newSkill = skill.filter(item => item.id !== skillID);

        try {
            await update({variables: {data: {skills: newSkill}, id: props.id}});
            props.onUpdate();
        } catch (error) {
            console.error(error);
        }

    }

    return (
        <div className='crm-indicator'>
            <div className={'crm-indicator-title'}>
                <Space size={10}><Switch defaultChecked={props.skill.own} onChange={onSubmit}/>{props.skill.name}</Space>
                <span>
                    <Button icon={<DeleteOutlined/>} type={'link'} onClick={onDelete}/>
                </span>
            </div>
        </div>
    );
}