import React, {useState} from 'react';
import {DatePicker, Select, Space} from "antd";
import dayjs from 'dayjs'

export default function DateAndTimePicker (field) {

    const [selectedTime, setSelectedTime] = useState(dayjs(field.value).hour());
    const [selectedDate, setSelectedDate] = useState(dayjs(field.value));

    function dateAndTime(date, time) {
        return dayjs(date).hour(time);
    }

    function newTime (value) {
        setSelectedTime(value);
        field.onChange(dateAndTime(selectedDate, value));
    }

    function newDate (value) {
        setSelectedDate(value);
        field.onChange(dateAndTime(value, selectedTime));
    }

    let timeOptions = [];

    for (let i = 0; i <= 23; i++) {
        timeOptions.push(
            <Select.Option key={i} value={i}>{i}:00</Select.Option>
        )
    }

    // Render
    return (
        <Space size={10}>
            <DatePicker
                format={'DD MMM YYYY'}
                value={selectedDate}
                size={field.size}
                onChange={newDate}
            />
            <Select
                onChange={newTime}
                size={field.size}
                style={{width: 100}}
                value={selectedTime}
                dropdownMatchSelectWidth={false}
            >
                {timeOptions}
            </Select>
        </Space>
    )

}