import React from 'react';
import {useNavigate} from 'react-router-dom';
import {CheckOutlined} from '@ant-design/icons';
import {task_info} from "../../routes";
import cn from 'classnames';
import moment from "moment";

export default function CalendarTask ({id, name, executor, done, date}) {

    const navigate = useNavigate();

    function go() {
        navigate(task_info(id));
    }

    const trainer = executor && executor.attributes.trainer.data;

    const userColor = executor ? trainer.attributes.color ? trainer.attributes.color : '#F0F0F0' : '#F0F0F0';
    const userName = executor ? <div className={'calendar-training-user'} style={{backgroundColor: userColor}}>{trainer.attributes.firstName.charAt(0)}{trainer.attributes.lastName.charAt(0)}</div> : null;

    let status = null;
    if (done) {
        status = 'done';
    } else {
        if (moment(date).diff(moment(), 'days') < 0) {
            status = 'danger'
        }
        if (moment(date).diff(moment(), 'days') === 0) {
            status = 'warning'
        }
    }

    return (
        <div className={cn(['calendar-training', 'task', status])} onClick={go}>
                <div>{name || id}</div>
                <div>
                    {userName} {done && <CheckOutlined/>}
                </div>
        </div>
    );
}