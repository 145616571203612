import {types as t} from 'mobx-state-tree';

export const DeviceVarsStore = t
  .model({
    needShowOnBoarding: t.optional(t.boolean, true),
  })
  .actions((self) => ({
    setNeedShowOnBoarding: (value) => {
      self.needShowOnBoarding = value;
    },
  }));
