import React from 'react';
import {Space} from 'antd';
import {CheckOutlined} from '@ant-design/icons';
import CrmAvatar from "../../components/CrmAvatar";
import moment from 'moment';
import cn from "classnames";
import {task_info} from "../../routes";
import {useNavigate} from "react-router-dom";

export default function ClientTask (props) {

    const {id, task} = props;

    const navigate = useNavigate();
    
    let status = null;
    if (task.done) {
        status = 'done';
    } else {
        if (moment(task.date).diff(moment(), 'days') < 0) {
            status = 'danger'
        }
        if (moment(task.date).diff(moment(), 'days') === 0) {
            status = 'warning'
        }
    }

    return (
        <div className={cn(['crm-message', status])} onClick={() => {navigate(task_info(id));}}>
            <Space direction='vertical' size={5} className='block'>
            <div className='children-message-title caption'>
                    <CrmAvatar
                        size='small'
                        firstName={task.executor.data.attributes.trainer.data.attributes.firstName}
                        avatarUrl={task.executor.data.attributes.trainer.data.attributes.avatar.data && task.executor.data.attributes.trainer.data.attributes.avatar.data.attributes.url}
                        color={task.executor.data.attributes.trainer.data.attributes.color}
                        lastName={task.executor.data.attributes.trainer.data.attributes.lastName}
                    />
                <span>{moment(task.date).format('DD MMM YYYY')}</span>
            </div>
            <div className={'crm-message-text'}>
                {task.done && <CheckOutlined/>} {task.name}
            </div>
            </Space>
        </div>
    );
}