import {React} from 'react';
import _ from 'lodash';
import '../../../pages/Client/Visit/visits.scss';
import {CheckOutlined, MinusOutlined} from "@ant-design/icons";

export default function Skills({skills}) {

    return (
        <>

            <div className={'visits-line'}>

                <div className={'line-6-8col ellipsis'}>
                    <span className={'caption color-gray-3'}>Навык</span>
                </div>

                <div className={'line-2-8col'}>
                    <span className={'caption color-gray-3'}>Владение</span>
                </div>

            </div>

            {_.map(skills, (skill, index) => {

                return (
                    <div className={'visits-line'} key={index}>
                        <div className={'line-6-8col ellipsis'}>
                            <span className={'body'}>{skill.name}</span>
                        </div>

                        <div className={'line-2-8col'}>
                            <span className={'body'}>{skill.own ? <CheckOutlined/> : <MinusOutlined className={'deleted'}/>}</span>
                        </div>
                    </div>

                )
            })}
        </>
    );
}



