import React, {useState} from 'react';

import {gql, useMutation} from "@apollo/client";
import {Button, Space,} from "antd";
import {PlusOutlined, UnorderedListOutlined,} from "@ant-design/icons";
import ModalChildAdd from "./ModalChildAdd";
import ModalChildSelect from "./ModalChildSelect";

const UPDATE_PARENT = gql`
    mutation parent($data: ParentInput!, $id: ID!) {
        updateParent(id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;

export default function ParentChildrenSelectAndAdd({parentId, children, onUpdate}) {

    const [updateParent] = useMutation(UPDATE_PARENT);

    // Select Clients
    const [modalSelectVisible, setModalSelectVisible] = useState(false);
    const onSaveList = async function (value) {
        let clients = value.map(function (item) {
            return (item.id);
        });
        await updateParent({variables: {data: {clients: clients}, id: parentId}});
        onUpdate();
        setModalSelectVisible(false);
    };

    // Add Client
    const [modalAddVisible, setModalAddVisible] = useState(false);
    const onAddClient = async function (value) {
        let clients = children.map(function (value) {
                return (value.id);
            });
        clients.push(value);
        await updateParent({variables: {data: {clients: clients}, id: parentId}});
        onUpdate();
        setModalAddVisible(false);
    };

    return (
        <>
            <Space>
                <Button size={'large'} icon={<UnorderedListOutlined/>} onClick={() => setModalSelectVisible(true)}/>
                <Button size={'large'} icon={<PlusOutlined/>} onClick={() => setModalAddVisible(true)}/>
            </Space>

            <ModalChildSelect isOpen={modalSelectVisible} onClose={() => setModalSelectVisible(false)} selected={children} onFinish={(value) => onSaveList(value)}/>
            <ModalChildAdd isOpen={modalAddVisible} onClose={() => setModalAddVisible(false)} parentId={parentId} onFinish={(value) => onAddClient(value)}/>
        </>

    );
}



