import React from 'react'
import {Link, Route, Routes} from 'react-router-dom';
import TestOne from "./TestOne";
import TestTwo from "./TestTwo";

export default function Test() {

    return (
        <>
            <div>Test</div>
            <Link to={'1'}>One</Link> <Link to={'2'}>Two</Link>

            <Routes>
                <Route path={'1'} element={<TestOne/>}/>
                <Route path={'2'} element={<TestTwo/>}/>
            </Routes>
        </>

    );
};