import React, {useEffect, useState} from 'react';
import {gql, useLazyQuery} from "@apollo/client";
import CrmModal from "../../../components/CrmModal";
import {Avatar, Button, Empty, Input, Skeleton,} from "antd";
import {CloseOutlined,} from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import ClientSelectRow from "../../Main/ClientSelectRow";
import {findIndex, map, remove} from 'lodash';

const QUERY_CLIENTS = gql`
    query clients ($filters: ClientFiltersInput!, $pagination: PaginationArg = {}, $sort: [String] = []) {
        clients (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    color
                    avatar {
                        data {
                            attributes {
                                previewUrl
                                url
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    page
                    pageCount
                    pageSize
                    total
                }
            }
        }
    }
`;


export default function ModalChildSelect({isOpen, onClose, onFinish, selected}) {

    const [selectedData, setSelectedData] = useState([...selected]);

    // main data
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(20);
    const [page, setPage] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [fetch, fetchedData] = useLazyQuery(QUERY_CLIENTS, {
        fetchPolicy: 'network-only',
        onCompleted: (newData) => {
                setData([...data, ...newData.clients.data]);
                setTotal(newData.clients.meta.pagination.total)
            }
        }
    );
    const loadMoreData = () => {
        setPage(page + 1);
        fetch({
            variables: {
                filters: {
                    or: [{firstName: {contains: searchText}}, {lastName: {contains: searchText}}],
                    enabled: {eq: true},
                },
                sort: ['lastName:ASC', 'firstName:ASC'],
                pagination: {
                    pageSize: 20,
                    page: page + 1
                }
            }
        });
    };
    useEffect(() => {
        loadMoreData();
    }, [searchText]);

    // Cahnge List
    const onRemoveItem = (data) => {
        let newData = selectedData;
        remove(newData, {id: data.id});
        setSelectedData([...newData]);
    };
    const onAddItem = (data) => {
        setSelectedData([...selectedData, data]);
    };

    // Items
    const items = data.map(function (value) {
        const selected = findIndex(selectedData, {id: value.id}) >= 0;
        const onClick = selected ? () => onRemoveItem(value) : () => onAddItem(value);
        return (
            <ClientSelectRow
                key={value.id}
                firstName={value.attributes.firstName}
                lastName={value.attributes.lastName}
                color={value.attributes.color}
                avatarUrl={value.attributes.avatar.data && value.attributes.avatar.data.attributes.previewUrl}
                selected = {selected}
                onClick = {onClick}
            />
        )
    });

    const onSave = function () {
        onFinish(selectedData);
    };

    return (
        <CrmModal isOpen={isOpen} onClose={onClose} title={'Выбрать из списка'} actionName={'Сохранить'}
                  onAction={onSave}>
            <div className={'modal-selected-users-container'}>
                <div className={'modal-selected-users'}>
                    {map(selectedData, (value) => {
                        return (
                            <React.Fragment key={value.id}>
                                <div className={'modal-selected-user'}>
                                    <div className={'modal-selected-user-avatar'}>
                                        <Avatar src={value.attributes.avatarUrl}>
                                            {value.attributes.firstName && value.attributes.firstName.charAt(0)}{value.attributes.lastName && value.attributes.lastName.charAt(0)}
                                        </Avatar>
                                        <Button icon={<CloseOutlined/>} shape="circle" size={'small'}
                                                className={'modal-selected-user-close'}
                                                onClick={() => onRemoveItem(value)}/>
                                    </div>
                                    <div className={'modal-selected-user-text'}>{value.attributes.firstName}</div>
                                    <div className={'modal-selected-user-text'}>{value.attributes.lastName}</div>
                                </div>
                            </React.Fragment>
                        )
                    })}
                </div>
            </div>

            <div className={'crm-modal-sheet-title'}>
                <Input.Search className={'crm-filters-1-item'} onSearch={(value) => {
                    setSearchText(value);
                    setData([]);
                    setPage(0);
                }}/>
            </div>

            <div className={'modal-selected-users-rows'} id="scrollableDiv">
                <InfiniteScroll
                    className={'infinite_component'}
                    dataLength={data.length}
                    next={loadMoreData}
                    hasMore={data.length < total}
                    loader={<div className='modal-selected-user-item'><Skeleton avatar={{size: 'normal'}} active paragraph={{rows: 0}}/></div>}
                    scrollableTarget="scrollableDiv"
                >
                    {items}
                </InfiniteScroll>

                {(data.length === 0 && !fetchedData.loading) && (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>)}

            </div>
        </CrmModal>
    )

}