import React from 'react';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;

const CrmWindowSpin = (props) => {

  if (props.spinning) {
      return (
          <Spin indicator={antIcon} className={'crm-window-spin'}/>
      )
  } else {
    return (
        null
    )
  }




};

export default CrmWindowSpin
