import React from 'react';
import {CloseCircleOutlined} from '@ant-design/icons';
import cls from "../Public/public.module.scss";
import cn from "classnames";
import PublicLayout from "../Public/PublicLayout";

export default function PaymentError() {

    return (
        <PublicLayout>

            <div className={cls.title}>
                <div className={cn([cls.icon, 'color-danger'])}>
                    <CloseCircleOutlined/>
                </div>
                <div className={cn([cls.text, 'color-danger'])}>
                    <div>
                        Ошибка
                    </div>
                    <div>
                        при оплате
                    </div>
                </div>
            </div>

            <div className={cls.content}>
                <div>
                    Обратитесь к администратору<br/>
                    команды «Сила Скилла»<br/>
                    для повторной оплаты
                </div>
            </div>
        </PublicLayout>
    );
}



