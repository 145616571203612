import React from 'react';
import WC from '../../../components/Charts/includes/white_circle.png';

const OneCircleChart = (
    {
        percent,
        targetValue,
        value,
        date,
        title,
        ...rest
    }
) => {

    const r = 350;
    const strokeDasharray = Math.PI * r * 2;
    let strokeDashoffset = ((100 - percent) / 100) * Math.PI * r * 2;
    const rotate = (percent / 100) * 360;

    if (percent >= 100) {
        strokeDashoffset = 0;
    }

    return (
        <svg width="1080" height="1080" viewBox="0 0 1080 1080" fill="none" style={{backgroundColor: '#2D68C4', borderRadius: 48}} xmlns="http://www.w3.org/2000/svg" {...rest}>

            <text fontSize="50" fontWeight="normal" fill="#fff" fontFamily="'Open Sans', sans-serif">
                <tspan x="80" y="80">{title}</tspan>
            </text>

            <text fontSize="50" fontWeight="normal" fill="#fff" textAnchor="end" fontFamily="'Open Sans', sans-serif">
                <tspan x="1000" y="80">Цель: {targetValue}</tspan>
            </text>

            <g>
                <circle
                    cx="540"
                    cy="540"
                    r="350"
                    stroke="white"
                    strokeOpacity="0.3"
                    strokeWidth="80"
                />
                <circle
                    cx="540"
                    cy="540"
                    r="350"
                    stroke="white"
                    strokeOpacity="1"
                    strokeWidth="80"
                    strokeDashoffset={strokeDashoffset}
                    strokeDasharray={strokeDasharray}
                    strokeLinecap="round"
                    transform="rotate(-90 540 540)"
                />
                <image
                    width="120"
                    height="120"
                    href={WC}
                    x="480"
                    y="130"
                    transform={`rotate(${rotate} 540 540)`}
                />
            </g>

            <text
                x={540}
                y={550}
                width={1080}
                fontFamily="'Open Sans', sans-serif"
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize="200"
                fontWeight="400"
                fill="#fff"
            >
                {value}
            </text>

            <text
                x={540}
                y={706}
                opacity={0.3}
                width={1080}
                fontFamily="'Open Sans', sans-serif"
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize="50"
                fontWeight="400"
                fill="#fff"
            >
                {date}
            </text>

        </svg>
    );
}

export default OneCircleChart;
