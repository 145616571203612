import React from 'react';
import {CheckCircleOutlined} from '@ant-design/icons';
import PublicLayout from "../Public/PublicLayout";
import cls from "../Public/public.module.scss";
import cn from 'classnames';

export default function PaymentSuccess() {

    return (
        <PublicLayout>

            <div className={cls.title}>
                <div className={cn([cls.icon, 'color-success'])}>
                    <CheckCircleOutlined/>
                </div>
                <div className={cn([cls.text, 'color-success'])}>
                    <div>
                        Оплата
                    </div>
                    <div>
                        прошла успешно
                    </div>
                </div>
            </div>

            <div className={cls.content}>
                <div>
                    Большое спасибо!
                </div>
            </div>
        </PublicLayout>

    );
}



