import React, {useState} from 'react'
import CrmLayout from "../../components/CrmLayout";
import CrmCenteredTitle from "../../components/CrmCenteredTitle";
import {gql, useQuery} from "@apollo/client";
import {Alert, Badge, QRCode, Space} from "antd";

const QUERY = gql`
    query whatsapp {
        WAGetState {
            status
            code
            auth
            errorMessage
        }
    }
`;

export default function WhatsApp() {

    const [WAState, setWAState] = useState({})

    useQuery(QUERY, {
        variables: {
            filters: {
                provider: {eq: "whatsapp"}
            },
        },
        fetchPolicy: "no-cache",
        pollInterval: 1000,
        onCompleted: (data) => {
            setWAState(data.WAGetState)
        }
    });

    return (

        <CrmLayout>
            <CrmCenteredTitle
                title={'WhatsApp'}
            />
            <Space direction={'vertical'} size={20} className={'block'}>
                {(WAState.status === "error") ? <Alert
                        message="Ошибка"
                        description={WAState.errorMessage}
                        type="error"
                        showIcon
                    /> :
                    <>
                        {(WAState.status !== "ready" && WAState.auth !== true) && <Alert
                            message="Внимание"
                            description="Не обновляйте и не закрывайте приложение"
                            type="warning"
                            showIcon
                        />}
                        {(WAState.status === "ready" && WAState.auth === true) && <Alert
                            message="Успешено"
                            description="WhatsApp подключен успешно, сессия будет восстановлена"
                            type="success"
                            showIcon
                        />}
                        {WAState.status === "init" && <Badge status={"processing"} text={"Подключение..."}/>}
                        {WAState.status === "ready" ? <Badge status={"success"} text={"Работает"}/> :
                            <QRCode value={WAState.code} size={260}/>}
                        {WAState.status === "error" &&
                            <Badge status={"error"} text={`Ошибка ${WAState.errorMessage}`}/>}
                    </>
                }
            </Space>
        </CrmLayout>
    );
};