import React from 'react';
import {useNavigate} from 'react-router-dom';
import {automation_info} from "../../routes";
import {OFFSETS, OPTIONS} from "./OptionNames";

export default function AutomationRow(props) {

    const navigate = useNavigate();

    function go() {
        navigate(automation_info(props.id));
    }

    return (
        <div className='crm-task-line' onClick={go}>
                <div className={'crm-task-name bold'}>
                    {OPTIONS[props.data.attributes.action].name}
                </div>
                <div>
                    {OFFSETS[props.data.attributes.cronOffset].name} ..
                </div>
        </div>
    );
}
