import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Input, Space} from "antd";
import React from "react";
import CrmModal from "../../../components/CrmModal";
import {gql, useMutation} from "@apollo/client";

const schemaAddIndicator = yup.object({
    name: yup.string().required('Обязательно нужно заполнить')
}).required();

const UPDATE = gql`
    mutation update($data: ParentInput!, $id: ID!) {
        updateParent(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;


export default function ParentModalAddSkill(props) {

    const [update] = useMutation(UPDATE);

    const {handleSubmit, control, getValues, setError, setValue, formState: {isSubmitting, isValid}} = useForm({
        resolver: yupResolver(schemaAddIndicator),
        defaultValues: {
            name: '',
            own: false,
        },
        reValidateMode: 'onChange',
        mode: 'all',
    });

    const onSubmit = async (data) => {

        let newSkill = props.clientInfo.attributes.skills.map(function (value) {
            return {
                id: value.id,
            }
        });
        newSkill.push(data);

        try {
            await update({variables: {data: {skills: newSkill}, id: props.id}});
            props.onUpdate();
            props.onClose();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <CrmModal isOpen={props.isOpen} onClose={() => props.onClose()} title={'Добавить навык'}
                  onAction={handleSubmit(onSubmit)}>
            <div className={'modal-basic-form'}>
                <Space direction="vertical" size={10} style={{width: '100%'}}>
                    Название навыка
                    <Controller
                        name={"name"}
                        control={control}
                        render={({field, formState: {submitCount}, fieldState: {error, isTouched}}) => (
                            <React.Fragment>
                                <Input
                                    size="large"
                                    {...field}
                                />
                                {(error && submitCount>0) && <span className={'caption color-danger'}>{error.message}</span>}

                            </React.Fragment>
                        )}/>
                </Space>
            </div>
        </CrmModal>
    )

}