import React, {useEffect, useState} from 'react';
import CrmLayout from "../../components/CrmLayout";
import UserListRow from "./UserListRow";

import {Empty} from 'antd';

import {gql, useLazyQuery} from "@apollo/client";
import CrmWindowSpin from "../../components/CrmWindowSpin";
import CrmCenteredTitle from "../../components/CrmCenteredTitle";

import cls from '../../styles/list.module.scss';

const GET_USER = gql`
    query usersPermissionsUsers ($filters: UsersPermissionsUserFiltersInput, $pagination: PaginationArg, $sort: [String] = []) {
        usersPermissionsUsers (filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    firstName
                    lastName
                    color
                }
            }
        }
    }
`;


export default function User() {

    const [allUsers, setAllUsers] = useState({data: [], meta: {}});

    const [getAllUsers, allUsersFetch] = useLazyQuery(GET_USER, {
        variables: {
            filters: {
                or: [{
                    role: {name: {eq: 'Trainer'}}
                }, {
                    role: {name: {eq: 'Boss'}}
                }],
                blocked: {ne: true},
            },
            sort: "lastName:asc"
        }
    });

    useEffect(() => {

        getAllUsers();

        if (allUsersFetch.data !== undefined) {
            setAllUsers(allUsersFetch.data.usersPermissionsUsers);
        }

    }, [getAllUsers, allUsersFetch.data]);

    let items = null;

    if (allUsers.data.length > 0) {
        items = allUsers.data.map(function (value) {
            return (
                <UserListRow
                        key={value.id}
                        id={value.id}
                        firstName={value.attributes.firstName}
                        lastName={value.attributes.lastName}
                        color={value.attributes.color}
                        // gym={value.a}
            //             enabled={value.enabled}
                    />
            )

            })

    } else {
        if (allUsersFetch.loading) {
            items = <CrmWindowSpin spinning/>
        } else {
            items = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

        }
    }

    return (

        <CrmLayout>
            <CrmCenteredTitle title={'Тренеры'}/>

            <div className={cls.list}>
                {items}
            </div>

        </CrmLayout>
    );
}



