import React from 'react';
import {Button, Input, InputNumber, Modal} from 'antd';
import {ArrowRightOutlined, DeleteOutlined, ExclamationCircleOutlined, PlusOutlined} from '@ant-design/icons';
import moment from 'moment';
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {gql, useMutation} from "@apollo/client";

const schemaAddIndicator = yup.object({
    value: yup.number().required()
}).required();

const UPDATE = gql`
    mutation update($data: ParentInput!, $id: ID!) {
        updateParent(data: $data, id: $id) {
            data {
                id
            }
        }
    }
`;

export default function ParentIndicator (props) {

    const [update] = useMutation(UPDATE);

    const indicatorID = props.indicator.id;

    const {handleSubmit, control, reset} = useForm({
        resolver: yupResolver(schemaAddIndicator),
        defaultValues: {
            value: 0,
        },
        reValidateMode: 'onChange',
        mode: 'all',
    });

    const onSubmit = async (data) => {

        let newResults = props.indicator.result.map(function (value) {
            return {
                id: value.id,
            }
        });
        newResults.push({
            date: moment().format('YYYY-MM-DD'),
            value: data.value
        });

        let newIndicator = props.clientInfo.attributes.indicators.map(function (value) {
            if (value.id === indicatorID) {
            return {
                id: value.id,
                result: newResults
            }} else {
                return {id: value.id}
            }
        });

        try {
            await update({variables: {data: {indicators: newIndicator}, id: props.id}});
            reset();
            props.onUpdate();
        } catch (error) {
            console.error(error);
        }
    };

    let results = null;

    if (props.indicator.result !== undefined && props.indicator.result !== null) {
        const lastThreeResults = props.indicator.result.slice(-3);

        results = lastThreeResults.map(function (value) {
            return (
                    <div key={'result'+value.id} className={'crm-indicator-results-col'}>
                        <div className={'caption'}>{moment(value.date).format('DD.MM.YYYY')}</div>
                        <Input size={'large'} value={value.value} disabled/>
                    </div>
            )

        });
    }

    function onDelete() {
        Modal.confirm({
            title: 'Удалить',
            icon: <ExclamationCircleOutlined />,
            content: 'Действительно удалить цель?',
            okText: 'Да',
            cancelText: 'Нет',
            onOk() {deleteIndicator()}
        });
    }

    async function deleteIndicator() {


        let indicator = props.clientInfo.attributes.indicators.map(function (value) {
            return {id: value.id};
        });

        const newIndicator = indicator.filter(item => item.id !== indicatorID);

        try {
            await update({variables: {data: {indicators: newIndicator}, id: props.id}});
            props.onUpdate();
        } catch (error) {
            console.error(error);
        }

    }


    return (
        <div className='crm-indicator'>
            <div className={'crm-indicator-title'}>
                <span>{props.indicator.name}</span>
                <span className={'bold'}>
                    {props.indicator.startValue} <ArrowRightOutlined/> {props.indicator.goal}
                    <Button icon={<DeleteOutlined/>} type={'link'} onClick={onDelete}/>
                </span>
            </div>
            <div className={'crm-indicator-result'}>

                {results}

                <div className={'crm-indicator-results-col'}>
                    <div className={'caption'}>Сегодня</div>
                    <Controller
                        name={"value"}
                        control={control}
                        render={({field, formState: {submitCount}, fieldState: {error, isTouched}}) => (
                            <React.Fragment>
                                <InputNumber
                                    size="large"
                                    {...field}
                                />
                            </React.Fragment>
                        )}/>
                </div>


                <div className={'crm-indicator-results-col'}>
                    <div className={'caption'}>&nbsp;</div>
                    <Button size={'large'} icon={<PlusOutlined/>} onClick={handleSubmit(onSubmit)}/>
                </div>

            </div>

        </div>
    );
}