import React from 'react';
import Sheet from 'react-modal-sheet';
import {Button, Modal} from 'antd';
import {CloseOutlined} from "@ant-design/icons";

function CrmModal({isOpen, title, onClose, onAction, loading, disabled, actionName, children}) {

    const isSmallScreenWidth = window.innerWidth <= 425;

    if (isSmallScreenWidth) {
        return (
            <Sheet isOpen={isOpen} onClose={onClose} >
                <Sheet.Container>
                    <Sheet.Header/>

                    <div className={'crm-modal-sheet-title'}>
                        <span className={'crm-modal-sheet-title-text'}>{title}</span>
                        <Button type='link' icon={<CloseOutlined/>} onClick={onClose}/>
                    </div>

                    <Sheet.Content className={'crm-modal-sheet-content'}>

                        {children}

                    </Sheet.Content>

                    {onAction ?
                        <div className={'crm-modal-sheet-action'}>
                            <Button
                                onClick={onAction}
                                block
                                size='large'
                                loading={loading}
                                disabled={disabled || false}
                            >{actionName || 'ОК' }</Button>
                        </div>
                        :
                        null
                    }

                </Sheet.Container>

                <Sheet.Backdrop/>
            </Sheet>
        );
    }

    return (
        <Modal
            open={isOpen}
            onCancel={onClose}
            title={title}
            footer={onAction ?
                <Button
                    onClick={onAction}
                    type={'link'}
                    loading={loading}
                    disabled={disabled || false}
                >{actionName || 'ОК'}</Button> : null}
        >
            {children}
        </Modal>
    );
}

export default CrmModal;
