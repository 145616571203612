import React from 'react';
import cls from './bonus.module.scss';
import logo from './bonus-logo.svg';

export default function BonusLogo() {
    return (
        <div className={cls.bonusLogo}>
            <img src={logo} className={cls.bonusLogoImg} alt='Логотип'/>
        </div>
    );
}
