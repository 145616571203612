import React from 'react';
import {Carousel,} from 'antd';
import {ResponsiveContainer,} from 'recharts';

import hash from 'object-hash';
import moment from "moment";
import _ from 'lodash';

import TreeCircleChart from "./TreeCircleChart";
import OneCircleChart from "./OneCircleChart";
import FiveCirclesChart from "./FiveCirclesChart";
import EightCirclesChart from "./EightCirclesChart";

// import PolygonChart from "./PolygonChart";

export default function Graphs({data}) {

    const targetCount = data.length;

    const paramsToMulti = _.map(data, (item) => {
        const {goal, startValue} = item;
        const lastResult = _.last(item.result);
        const lastRecordedValue = lastResult.value;

        let percent = ((lastRecordedValue - startValue) * 100) / (goal - startValue);

        if (percent < 0) {
            percent = 0;
        }

        const date = lastResult.date;

        return {
            percent: percent,
            title: item.name,
            targetValue: goal,
            value: lastRecordedValue,
            date: moment(date).format('DD MMM YYYY'),
        };
    });

    // const paramsToPolygon = _.map(paramsToMulti, (item) => {
    //     return {
    //         ...item,
    //         percent: item.percent > 100 ? 100 : item.percent,
    //     }
    // });

    return (
        <div className={'public-graphs'}>
            <Carousel autoplay>

                {targetCount >= 2 && targetCount <= 3 && (
                    <ResponsiveContainer width='100%' height={400}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            maxHeight: 400
                        }}>
                            <div className="public-chart-root">
                                <TreeCircleChart
                                    key={new Date().getTime()}
                                    id={`chart-main1-${hash(paramsToMulti)}`}
                                    params={paramsToMulti}
                                />
                            </div>
                        </div>
                    </ResponsiveContainer>
                )}

                {targetCount > 3 && targetCount <= 5 && (
                    <ResponsiveContainer width='100%' height={400}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            maxHeight: 400
                        }}>
                            <div className="public-chart-root">
                                <FiveCirclesChart
                                    key={new Date().getTime()}
                                    id={`chart-main1-${hash(paramsToMulti)}`}
                                    params={paramsToMulti}
                                />
                            </div>
                        </div>
                    </ResponsiveContainer>
                )}

                {targetCount > 5 && targetCount <= 8 && (
                    <ResponsiveContainer width='100%' height={400}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            maxHeight: 400
                        }}>
                            <div className="public-chart-root">
                                <EightCirclesChart
                                    key={new Date().getTime()}
                                    id={`chart-main1-${hash(paramsToMulti)}`}
                                    params={paramsToMulti}
                                />
                            </div>
                        </div>
                    </ResponsiveContainer>
                )}

                {_.map(data, (item) => {
                    const {goal, startValue} = item;
                    const lastResult = _.last(item.result);
                    const lastRecordedValue = lastResult.value;

                    let percent = ((lastRecordedValue - startValue) * 100) / (goal - startValue);

                    if (percent < 0) {
                        percent = 0;
                    }

                    const date = lastResult.date;

                    const params =
                        {
                            percent: percent,
                            title: item.name,
                            targetValue: goal,
                            value: lastRecordedValue,
                            date: moment(date).format('DD MMM YYYY'),
                        }
                    ;

                    return (
                        <ResponsiveContainer width='100%' height={400}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                maxHeight: 400
                            }}>
                                <div className="public-chart-root">
                                    <OneCircleChart
                                        key={new Date().getTime()}
                                        id={`chart-main1-${hash(paramsToMulti)}`}
                                        {...params}
                                    />
                                </div>
                            </div>
                        </ResponsiveContainer>
                    )
                })}
            </Carousel>
        </div>
    );
}
