import React, {useState} from 'react';

import {gql, useQuery} from "@apollo/client";
import {Button, Divider, message, Tooltip} from "antd";
import VisitListRow from "./VisitListRow";
import TrainingClientsSelectAndAdd from "./TrainingClientsSelectAndAdd";

import _ from 'lodash';
import cn from 'classnames'

import './visit.scss'
import {useNavigate} from "react-router-dom";
import {postingClients} from "../../../routes";
import TelegramOutlined from "../../../vidgets/icon/TelegramOutlined";

const VISITS = gql`
    query query ($filters: VisitFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        visits(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    training {
                        data {
                            id
                            attributes {
                                startDate
                            }
                        }
                    }
                    user {
                        data {
                            id
                            attributes {
                                role {
                                    data {
                                        attributes {
                                            name
                                        }
                                    }
                                }
                                parent {
                                    data {
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                            enabled
                                        }
                                    }
                                }
                                client {
                                    data {
                                        attributes {
                                            firstName
                                            lastName
                                            color
                                            enabled
                                        }
                                    }
                                }
                            }
                        }
                    }
                    visited
                }
            }
            meta {
                pagination {
                    total
                    page
                    pageCount
                    pageSize
                }
            }
        }

    }
`;

export default function TrainingVisits({id, gymId}) {

    // navigate
    const navigate = useNavigate()

    // main data
    const [data, setData] = useState([]);

    const query = useQuery(VISITS, {
            variables: {
                filters: {
                    training: {id: {eq: id}}
                },
                sort: ['user.client.lastName:asc', 'user.parent.lastName:asc'],
                pagination: {
                    pageSize: 200,
                }
            },
            fetchPolicy: 'network-only',
            onCompleted: (newData) => {
                setData([...newData.visits.data]);
            },
            onError: (error) => {
                message.error(error.message)
                console.error(error)
            }
        }
    );

    const goPosting = () => {
        const ids = _.map(data, (visit) => {
            return visit.attributes.user.data.id
        })
        navigate(postingClients(ids))
    }

    const items = data.map(function (visit) {

        const user = visit.attributes.user.data;
        const role = user.attributes.role.data;
        let person = null;
        if (role.attributes.name === 'Child') {
            person = user.attributes.client.data;
        }
        if (role.attributes.name === 'Parent') {
            person = user.attributes.parent.data;
        }

        const training = visit.attributes.training.data;

        return (
            <VisitListRow
                key={visit.id}
                id={visit.id}

                userId={user.id}
                firstName={person.attributes.firstName}
                lastName={person.attributes.lastName}
                color={person.attributes.color}
                child={role.attributes.name === 'Child' ? true : false}
                enabled={person.attributes.enabled === false ? false : true}

                startDate={training.attributes.startDate}

                gymId={gymId}

                visited={visit.attributes.visited}

                onUpdate={query.refetch}
            />)

    });

    return (
        <>
            <Divider>Записаны на тренировку ({data.length})</Divider>

            <div className={cn(['visit-table-header', 'caption', 'align-right', 'color-gray-3'])}>
                Посещения
            </div>

            {items}

            {query.data &&
                <div className={'crm-line'}>
                    <TrainingClientsSelectAndAdd trainingId={id} clients={_.map(query.data.visits.data, (item) => {
                        return item.attributes.user.data
                    })} onUpdate={query.refetch}/>
                    <Tooltip title={'Создать рассылку'}>
                        <Button size={'large'} icon={<TelegramOutlined/>} onClick={goPosting}/>
                    </Tooltip>
                </div>
            }

        </>
    );
}



