import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import dayjs from 'dayjs';

import CrmLayout from "../../components/CrmLayout";
import {training_info} from "../../routes";
import {gql, useMutation} from "@apollo/client";
import CrmCenteredTitle from "../../components/CrmCenteredTitle";
import TrainingForm from "./TrainingForm";

const CREATE = gql`
    mutation create($data: TrainingInput!) {
        createTraining(data: $data) {
            data {
                id
            }
        }
    }
`;

export default function TrainingNew() {

    const { date, time } = useParams();

    const [loading, setLoading] = useState(false);

    const [create] = useMutation(CREATE);

    const navigate = useNavigate();


    const onSubmit = async (data) => {
        setLoading(true);
        const newData = {
            name: data.name,
            comment: data.comment,
            contentUrl: data.contentUrl,
            gym: data.gym,
            trainers: data.trainers,
            startDate: dayjs(data.startDate).format(),
        };
        await create({
            variables: {data: newData},
            onCompleted: (data) => {
                navigate(training_info(data.createTraining.data.id));
            }
        });
    };

    const data = {
        startDate: dayjs(date).hour(time),
    };

    return (

        <CrmLayout>

            <CrmCenteredTitle
                title={'Новая тренировка'}
            />

            <TrainingForm onSubmit={onSubmit} loading={loading} data={data} actionName={'Добавить'}/>

        </CrmLayout>
    );
}
