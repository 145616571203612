import React, {useState} from 'react';

import {gql, useQuery} from "@apollo/client";
import {Select} from "antd";

import ReportGymsGroup from "./ReportGymsGroup";
import {get_report} from "../../routes";
import {useNavigate} from "react-router-dom";

const GET_GYMS = gql`
    query clients (
        $gymFilters: GymFiltersInput,
        $gymPagination: PaginationArg = {},
        $gymSort: [String] = []) {

        gyms (filters: $gymFilters, pagination: $gymPagination, sort: $gymSort) {
            data {
                id
                attributes {
                    name
                    address
                    enabled
                    city {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

const {Option} = Select;

export default function ReportGyms(props) {

    const {range, id} = props;
    const type = 'gyms';

    const navigate = useNavigate();

    const {data, loading} = useQuery(GET_GYMS, {
        variables: {
            gymFilters: {
                or: [{
                    enabled: {eq: true}
                }, {
                    enabled: {null: true}
                }]
            },
            gymPagination: {
                limit: 200
            }
        }
    });

    const [selectedGym, setSelectedGym] = useState(id);

    let gymOptions = null;
    let gymGroups = null;

    if (data !== undefined) {
        gymOptions = data.gyms.data.map(function (value) {
            return (
                <Option value={value.id} key={value.id}>
                    <span className={'bold'}>{value.attributes.name}</span> {value.attributes.address}
                </Option>
            )
        });
        gymGroups = data.gyms.data.map(function (value) {
            return (
                <div key={value.id} className={'crm-report-group'}>
                    <div className={'bold'}>{value.attributes.name} {value.attributes.address}</div>
                    <ReportGymsGroup
                        id={value.id}
                        range={range}
                    />
                </div>
            )
        });
    }

    function onGymChange(value) {
        setSelectedGym(value);
        navigate(get_report(type, value, range[0].format('YYYY-MM-DD'), range[1].format('YYYY-MM-DD')), {replace: true});
    }

    const displayGyms = selectedGym === '0' ?
        <>
            {gymGroups}
            </>
        :
        <>
            <ReportGymsGroup
                id={selectedGym}
                range={range}
            />
        </>

    ;

    return (
        <>
            <div className={'crm-report-filter'}>
                <Select value={selectedGym} bordered={false} className={'block'} onChange={onGymChange} loading={loading} dropdownMatchSelectWidth={false}>
                    <Option value={'0'}>Все залы</Option>
                    {gymOptions}
                </Select>
            </div>
            {displayGyms}
        </>
    );
}



